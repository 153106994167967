import styled from 'styled-components';
import { Form as FormikForm, Field, useFormikContext } from 'formik';
import { Button, Form } from 'react-bootstrap';
import { tidyUrl } from 'utils/Tidy';
import { Link } from 'react-router-dom';
import ObjectID from 'bson-objectid';

const Component = styled(FormikForm)`
	display: grid;
	gap: 1.5rem;
`;

const FormFields = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1rem;

	@media (max-width: 767px) {
		grid-template-columns: 100%;
	}
`;

const MultiSiteFields = styled.div`
	display: grid;
	gap: 0.5rem;
`;

const MultiSiteRow = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr) 2rem;
	gap: 1rem;
	align-items: center;

	> * {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
`;

const CustomButton = styled.button`
	all: unset;
	color: #fffa;
	border-radius: 0.5rem;
	display: grid;
	place-items: center;
	height: 2rem;
	width: 2rem;
	transition: 0, 2s;

	&:hover,
	&:focus-visible {
		color: white;
	}
`;

const AddButton = styled(CustomButton)`
	background: var(--bs-primary);
`;

const RemoveButton = styled(CustomButton)`
	background: var(--bs-danger);
`;

const Buttons = styled.div`
	display: grid;
	grid-template-columns: auto auto;
	justify-content: start;
	gap: 0.5rem;

	@media (max-width: 576px) {
		grid-template-columns: 100%;

		.btn {
			margin: 0 !important;
		}
	}
`;

const NewForm = () => {
	// Hooks
	const { values, setFieldValue } = useFormikContext();

	// Handlers
	const handleAddProperty = () => {
		const _id = ObjectID().toHexString();
		if (!values.property_name || !values.website_url || !values.preview_url) return;
		setFieldValue('properties', [...values.properties, { _id, property_name: values.property_name, website_url: values.website_url, preview_url: values.preview_url }]);
		setFieldValue('property_name', '');
		setFieldValue('website_url', '');
		setFieldValue('preview_url', '');
	};

	const handleRemoveProperty = id => {
		const filtered = values.properties.filter(property => property._id !== id);
		setFieldValue('properties', filtered);
	};

	// Disable submit
	const disableSubmit = values.company_name && values.company_contact && values.product_tier && values.purpose && values.number_of_sites && values.target_dates.start ? false : true;

	return (
		<Component>
			<h4 className='text-2xl font-semibold'>Project Details</h4>
			<FormFields>
				<div>
					<Form.Label>
						Company Name <span className='text-danger fw-bold'>*</span>
					</Form.Label>
					<Field as={Form.Control} type='text' name='company_name' required />
				</div>
				<div>
					<Form.Label>
						Company Contact <span className='text-danger fw-bold'>*</span>
					</Form.Label>
					<Field as={Form.Control} type='email' name='company_contact' required />
				</div>
				<div>
					<Form.Label>
						Number of Sites <span className='text-danger fw-bold'>*</span>
					</Form.Label>
					<Field as={Form.Select} name='number_of_sites' required>
						<option>Single Site</option>
						<option>Multi-Site Pack</option>
					</Field>
				</div>
				<div>
					<Form.Label>
						Product Tier <span className='text-danger fw-bold'>*</span>
					</Form.Label>
					<Field as={Form.Select} name='product_tier' required>
						<option>S1</option>
						<option>S2</option>
						<option>SE</option>
						<option>Custom</option>
					</Field>
				</div>
				<div>
					<Form.Label>
						Website Purpose <span className='text-danger fw-bold'>*</span>
					</Form.Label>
					<Field as={Form.Select} name='purpose' required>
						<option>Company Site</option>
						<option>Single Property</option>
						<option disabled={values.product_tier !== 'S1'}>Community Association</option>
					</Field>
				</div>
				<div>
					<Form.Label>
						Project Start Date <span className='text-danger fw-bold'>*</span>
					</Form.Label>
					<Field as={Form.Control} type='date' name='target_dates.start' required />
					<Form.Text>Milestone & go-live dates will be calculated from this.</Form.Text>
				</div>
				{values.number_of_sites === 'Single Site' && (
					<>
						<div>
							<Form.Label>Live Website URL</Form.Label>
							<Field as={Form.Control} type='url' name='website_url' />
						</div>
						<div>
							<Form.Label>Duda Site ID</Form.Label>
							<Field as={Form.Control} type='text' name='site_id' minLength={8} maxLength={8} />
						</div>
					</>
				)}
			</FormFields>
			{values.number_of_sites === 'Multi-Site Pack' && (
				<MultiSiteFields>
					<MultiSiteRow>
						<div className='fw-bold'>Property Name</div>
						<div className='fw-bold'>Live Website URL</div>
						<div className='fw-bold'>Preview Site URL</div>
						<div></div>
					</MultiSiteRow>
					{values.properties.map(property => (
						<MultiSiteRow key={property._id}>
							<div>{property.property_name}</div>
							<div>{tidyUrl(property.website_url)}</div>
							<div>{tidyUrl(property.preview_url)}</div>
							<RemoveButton type='button' onClick={() => handleRemoveProperty(property._id)}>
								<i className='fas fa-times'></i>
							</RemoveButton>
						</MultiSiteRow>
					))}
					<MultiSiteRow>
						<Field as={Form.Control} type='text' name='property_name' />
						<Field as={Form.Control} type='url' name='website_url' />
						<Field as={Form.Control} type='url' name='preview_url' />
						<AddButton type='button' onClick={() => handleAddProperty()}>
							<i className='fas fa-plus'></i>
						</AddButton>
					</MultiSiteRow>
				</MultiSiteFields>
			)}
			<Buttons>
				<Button variant='primary' type='submit' disabled={disableSubmit}>
					Create Plan <i className='fas fa-folder-plus'></i>
				</Button>
				<Button as={Link} variant='secondary' to='/plans'>
					Cancel <i className='fas fa-times-circle'></i>
				</Button>
			</Buttons>
		</Component>
	);
};

export default NewForm;
