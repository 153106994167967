import styled from 'styled-components';
import { Button, ProgressBar } from 'react-bootstrap';
import PageHeader from './PageHeader';
import logo from 'img/app/appfolio_logo.svg';
import ProfileImage from './ProfileImage';
import { Panel } from './Panel';
import LoadingButton from './LoadingButton';

const Form = styled.form`
	max-width: 1280px;
	margin: 0 auto;
	padding: 1.5rem;
	display: grid;
	gap: 2rem;

	@media (max-width: 576px) {
		padding: 1rem;
		gap: 1rem;
	}
`;

const Container = styled.div`
	max-width: 720px;
	margin: 0 auto;
	display: grid;
	gap: 2rem;
	width: 100%;

	@media (max-width: 576px) {
		padding: 1rem;
		gap: 1rem;
	}
`;

const HeaderComponent = styled.div`
	display: grid;
	grid-template-columns: 75px 1fr;
	align-items: center;
	gap: 1.5rem;
`;

const ProgressComponent = styled(ProgressBar)`
	height: 2rem;
	font-size: 1rem;
	border-radius: 0.5rem;
`;

const ButtonsComponent = styled.div`
	display: grid;
	grid-template-columns: auto auto;
	gap: 0.75rem;
	justify-self: center;
`;

const Owner = styled.div`
	text-align: center;
	padding: 1.5rem;

	@media (max-width: 576px) {
		padding: 0;
	}
`;

const OwnerDialogComponent = styled.div`
	display: grid;
	grid-template-columns: minmax(96px, auto) 1fr;
	gap: 1.5rem;
	align-items: center;
	max-width: 720px;
	margin: 0 auto;
	width: 100%;

	@media (max-width: 576px) {
		grid-template-columns: 100%;
		gap: 1rem;
	}
`;

const Name = styled.div`
	font-size: 1.25rem;
	font-weight: bold;
	margin: 0.75rem 0 0.25rem;
`;

const Location = styled.div`
	color: var(--bs-secondary);
`;

const DialogPanel = styled.div`
	background: white;
	padding: 2rem;
	box-shadow: rgba(0, 0, 0, 0.08) 0px 0.125rem 0.25rem;
	border-radius: 0 1.5rem 1.5rem 1.5rem;

	h4,
	p {
		margin-bottom: 1rem;
	}
`;

const ReviewComponent = styled.div`
	display: grid;
	gap: 1rem;
`;

const renderResponse = response => {
	if (!response) return '-';
	if (Array.isArray(response)) {
		return (
			<ul className='list-disc list-inside'>
				{response.map((item, i) => (
					<li key={i}>{item}</li>
				))}
			</ul>
		);
	} else {
		return response;
	}
};

const Header = ({ heading, subheading, ...props }) => {
	return (
		<HeaderComponent {...props}>
			<img src={logo} alt='AppFolio Logo' width='100%' />
			<PageHeader heading={heading} subheading={subheading} className='m-0' />
		</HeaderComponent>
	);
};

const Progress = ({ screens, currentScreen, ...props }) => {
	const progress = currentScreen === 1 ? 0 : Math.round((currentScreen / screens.length) * 100);
	return <ProgressComponent now={progress} label={`${progress}% complete`} {...props} />;
};

const Screens = ({ screens, currentScreen, ...props }) => {
	return <>{screens.map((screen, i) => currentScreen === i + 1 && <screen.component key={screen.name} {...props} />)}</>;
};

const Buttons = ({ screens, currentScreen, setCurrentScreen, allowToProceed, errors, isLoading, ...props }) => {
	// Handlers
	const handleNextStep = () => {
		setCurrentScreen(prev => prev + 1);
		window.scrollTo(0, 0);
	};

	// Previous template step
	const handlePrevStep = () => {
		setCurrentScreen(prev => prev - 1);
		window.scrollTo(0, 0);
	};

	// Errors
	const hasErrors = errors && Object.keys(errors).length > 0;

	return (
		<ButtonsComponent {...props}>
			{currentScreen > 1 && currentScreen < screens.length && (
				<Button variant='secondary' type='button' onClick={handlePrevStep}>
					<i className='fas fa-angle-left'></i> Previous
				</Button>
			)}
			{currentScreen < screens.length - 1 && (
				<Button variant='primary' type='button' onClick={handleNextStep} disabled={!allowToProceed || hasErrors}>
					{currentScreen === 1 ? 'Get started' : 'Next'} <i className='fas fa-angle-right'></i>
				</Button>
			)}
			{currentScreen === screens.length - 1 &&
				(!isLoading ? (
					<Button variant='primary' type='submit' disabled={!allowToProceed || hasErrors}>
						Submit <i className='fas fa-paper-plane'></i>
					</Button>
				) : (
					<LoadingButton />
				))}
		</ButtonsComponent>
	);
};

const OwnerDialog = ({ owner, children, ...props }) => {
	// Props
	const { imageUrl, name, jobTitle, userLocation } = { ...owner };

	return (
		<OwnerDialogComponent {...props}>
			<Owner>
				<ProfileImage src={imageUrl} alt={name} />
				<Name>{name}</Name>
				<div>{jobTitle}</div>
				<Location>{userLocation}</Location>
			</Owner>
			<DialogPanel>{children}</DialogPanel>
		</OwnerDialogComponent>
	);
};

const Review = ({ survey, ...props }) => {
	return (
		<ReviewComponent {...props}>
			{survey.map(item => (
				<Panel key={item.question}>
					<div key={item.question}>
						<div className='text-secondary'>{item.question}</div>
						<div>{renderResponse(item.response)}</div>
					</div>
				</Panel>
			))}
		</ReviewComponent>
	);
};

const NestedReview = ({ survey, ...props }) => {
	return (
		<ReviewComponent {...props}>
			{survey.map(item => (
				<Panel key={item.section}>
					<h5 className='mb-2 text-lg font-semibold'>{item.section}</h5>
					{item.data.map(data => (
						<div key={data.question}>
							<div className='text-secondary'>{data.question}</div>
							<div>{renderResponse(data.response)}</div>
						</div>
					))}
				</Panel>
			))}
		</ReviewComponent>
	);
};

export const Survey = { Header, Form, Container, Progress, Screens, Buttons, OwnerDialog, Review, NestedReview };
