import useSWR from 'swr';
import { fetcher } from './Fetch';

export const useTemplates = () => {
	const { data: allTemplates } = useSWR('/api/v1/templates', fetcher);

	// Return all templates
	const getAllTemplates = () => {
		return allTemplates;
	};

	// Return allowed templates based on product tier + site purpose
	const getAllowedTemplates = (product_tier, purpose) => {
		if (!allTemplates) return [];
		let allowedTemplates = allTemplates;

		// If not custom site
		if (product_tier && product_tier !== 'Custom') {
			allowedTemplates = allowedTemplates.filter(template => template.purpose === purpose);
		}

		// If not S1 site
		if (product_tier && product_tier === 'S1') {
			allowedTemplates = allowedTemplates.filter(template => template.product_tier === product_tier);
		} else {
			allowedTemplates = allowedTemplates.filter(template => template.product_tier !== 'S1');
		}

		return allowedTemplates;
	};

	const getAdditionalTemplates = (product_tier, purpose) => {
		if (!allTemplates) return [];
		let additionalTemplates = allTemplates;

		// If not S1 property site
		if (product_tier !== 'S1' && purpose === 'Single Property') {
			additionalTemplates = additionalTemplates.filter(template => template.product_tier !== 'S1' && template.purpose === 'Company Site');
		}

		return additionalTemplates;
	};

	return { getAllTemplates, getAllowedTemplates, getAdditionalTemplates };
};
