import { useState, useEffect } from 'react';
import PageHeader from 'components/shared/PageHeader';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import DateSelector from './DateSelector';
import { Helmet } from 'react-helmet';
import dayjs from 'dayjs';
import { tidyDatePicker, tidyUserEmail } from 'utils/Tidy';
import useSWR from 'swr';
import PlanTable from 'components/shared/PlanTable';
import { Col, Form, Row } from 'react-bootstrap';

const Dashboard = () => {
	// State
	const [queryObject, setQueryObject] = useState({ start: tidyDatePicker(dayjs().subtract(30, 'days')), end: tidyDatePicker(new Date()) });
	const [queryString, setQueryString] = useState('');

	// Handle filter change
	const handleChange = e => {
		const { name, value } = e.target;
		setQueryObject(prev => ({ ...prev, [name]: value }));
	};

	// Update query string
	useEffect(() => {
		setQueryString(new URLSearchParams(queryObject).toString());
	}, [queryObject]);

	// Hooks
	const { data: plans } = useSWR(`/api/v1/plans/all?${queryString}`);
	const { data: owners } = useSWR(`/api/v1/plans/all/users`);

	return (
		<>
			<Helmet>
				<title>Admin Dashboard | Project Plan Tool</title>
			</Helmet>
			<PageHeader heading={'Admin Dashbard'} subheading={'Overview of all existing project plans for each team member.'} />
			{plans && owners ? (
				<>
					<Row>
						<Col lg={4}>
							<Form.Group className='mb-3'>
								<Form.Label>Project Owner</Form.Label>
								<Form.Select name='owner' onChange={e => handleChange(e)} value={queryObject.owner || ''}>
									<option value=''>All Project Owners</option>
									{owners.map(owner => (
										<option key={owner._id} value={owner._id}>
											{tidyUserEmail(owner._id)}
										</option>
									))}
								</Form.Select>
							</Form.Group>
						</Col>
						<Col lg={8}>
							<DateSelector state={queryObject} setState={setQueryObject} />
						</Col>
					</Row>
					<PlanTable plans={plans} showOwner={true} />
				</>
			) : (
				<LoadingOverlay />
			)}
		</>
	);
};

export default Dashboard;
