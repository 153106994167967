import { useState, useRef } from 'react';
import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { useClickAway } from 'react-use';
import { fetcher } from 'utils/Fetch';
import { useParams } from 'react-router-dom';
import { mutate } from 'swr';
import { useMessage } from 'utils/MessageContext';

const EditButton = styled.button`
	all: unset;
	font-size: 0.75rem;
	background: var(--lightest);
	width: 1.5rem;
	height: 1.5rem;
	display: inline-grid;
	place-items: center;
	color: var(--bs-secondary);
	border-radius: 999px;
	transition: 0.2s;
	opacity: 0;
	visibility: hidden;
	margin-left: 0.5rem;

	&:hover,
	&:focus-visible {
		color: var(--bs-primary);
	}
`;

const Component = styled.div`
	display: grid;
`;

const NotEditing = styled.div`
	margin-top: 0.25rem;

	&:hover,
	&:focus-visible {
		${EditButton} {
			opacity: 1;
			visibility: visible;
		}
	}
`;

const CustomInput = styled(TextareaAutosize)`
	all: unset;
	display: block;
	justify-self: stretch;
	padding: 0.25rem 0.5rem;
	border-radius: 0.5rem;
	background: var(--lightest);
	margin-top: 0.25rem;

	&:focus {
		border-color: #80bde3;
		outline: 0;
		box-shadow: 0 0.125rem 0.25rem rgba(41, 48, 61, 0.075), 0 0 0 0.25rem rgba(0, 123, 199, 0.25);
	}
`;

const EditableField = ({ form, name, value }) => {
	// Props
	const { register, getValues } = { ...form };

	// Hooks
	const { id } = useParams();
	const ref = useRef(null);
	const { setMessage } = useMessage();
	useClickAway(ref, () => isEditing && handleSave());

	// State
	const [isEditing, setIsEditing] = useState(false);

	// Handlers
	const handleSave = async () => {
		try {
			const values = getValues();
			await fetcher(`/api/v1/site-genie/${id}`, { method: 'PUT', body: JSON.stringify(values) });
			await mutate(`/api/v1/site-genie/${id}`);
		} catch (error) {
			setMessage({ variant: 'danger', text: 'Error saving Site Genie data.' });
		}
		setIsEditing(false);
	};

	return (
		<Component ref={ref}>
			{!isEditing ? (
				<NotEditing>
					<span>{value || '-'}</span>
					<EditButton onClick={() => setIsEditing(true)}>
						<i className='fas fa-pen'></i>
					</EditButton>
				</NotEditing>
			) : (
				<CustomInput {...register(name)} autoFocus />
			)}
		</Component>
	);
};

export default EditableField;
