import { Form, Row, Col } from 'react-bootstrap';
import { Field, useFormikContext } from 'formik';
import { PinWrapper } from '../Pinned';
import BriefFieldsEnum from 'config//BriefFieldsEnum.json';
import TextareaAutosize from 'react-textarea-autosize';

const Notes = ({ section }) => {
	// Hooks
	const { values } = useFormikContext();

	// Enum
	const { integrations_enum } = BriefFieldsEnum;

	return (
		<>
			<Row>
				<Col lg={6}>
					<PinWrapper section={section} name='current_website_status'>
						<Form.Label>Does the client have a current website?</Form.Label>
						<Field as={Form.Select} name='current_website_status'>
							<option></option>
							<option>Yes</option>
							<option>No</option>
						</Field>
					</PinWrapper>
				</Col>
				<Col lg={6}>
					<PinWrapper section={section} name='integrations'>
						<Form.Label className='fw-bold'>Integrations</Form.Label>
						<Form.Group className='mb-3'>
							{integrations_enum.map(option => (
								<Field key={option} as={Form.Check} type='checkbox' name='integrations' value={option} label={option} id={`integrations-${option}`} />
							))}
						</Form.Group>
					</PinWrapper>
				</Col>
			</Row>
			{values.current_website_status === 'Yes' && (
				<Row>
					<Col lg={6}>
						<PinWrapper section={section} name='like_about_their_current_website'>
							<Form.Label>What does the client like about their current website?</Form.Label>
							<Field as={TextareaAutosize} minRows={3} className='form-control' name='like_about_their_current_website' />
						</PinWrapper>
					</Col>
					<Col lg={6}>
						<PinWrapper section={section} name='dislike_about_their_current_website'>
							<Form.Label>What does the client NOT like about their current website?</Form.Label>
							<Field as={TextareaAutosize} minRows={3} className='form-control' name='dislike_about_their_current_website' />
						</PinWrapper>
					</Col>
				</Row>
			)}
			{values.current_website_status === 'No' && (
				<Row>
					<Col lg={6}>
						<PinWrapper section={section} name='why_want_a_website_now'>
							<Form.Label>What made the client want a website now?</Form.Label>
							<Field as={TextareaAutosize} minRows={3} className='form-control' name='why_want_a_website_now' />
						</PinWrapper>
					</Col>
					<Col lg={6}>
						<PinWrapper section={section} name='goals_for_the_website'>
							<Form.Label>What are the client's goals for the website?</Form.Label>
							<Field as={TextareaAutosize} minRows={3} className='form-control' name='goals_for_the_website' />
						</PinWrapper>
					</Col>
				</Row>
			)}
		</>
	);
};

export default Notes;
