import LoadingOverlay from 'components/shared/LoadingOverlay';
import { Views } from 'components/shared/Views';
import React from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { tidyDateTime, tidyNestedFormResponse } from 'utils/Tidy';

const Expanded = () => {
	// Hooks
	const { id } = useParams();
	const { data: jwt } = useSWR('/auth/jwt');
	const { data, isLoading } = useSWR(jwt ? `/api/v1/site-genie/expanded_survey/${id}` : null);
	const { company_name, submitted, ...allFields } = { ...data };

	// Format responses
	const fieldsToRender = tidyNestedFormResponse(allFields);

	return isLoading ? (
		<LoadingOverlay />
	) : (
		<Views.Wrapper>
			<Views.Header heading={`Expanded Survey - ${company_name}`} subheading={`Submitted ${tidyDateTime(submitted)}`} />
			<Views.Panels>
				{fieldsToRender.map(({ section, data }) => (
					<React.Fragment key={section}>
						<Views.Category>{section}</Views.Category>
						{data.map(({ question, response }) => (
							<Views.Panel key={question}>
								<Views.Question>{question}</Views.Question>
								<div>
									{Array.isArray(response) ? (
										<Views.List>
											{response.map(r => (
												<li key={r}>{r}</li>
											))}
										</Views.List>
									) : (
										<Views.Response>{response || '-'}</Views.Response>
									)}
								</div>
							</Views.Panel>
						))}
					</React.Fragment>
				))}
			</Views.Panels>
		</Views.Wrapper>
	);
};

export default Expanded;
