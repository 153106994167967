import { Form } from 'react-bootstrap';

const ContactDetails = ({ handleChange, formResponse }) => {
	return (
		<>
			<h4 className='font-semibold text-2xl'>Contact Details</h4>
			<Form.Group className='mb-3'>
				<Form.Label>
					Property Name/Title <span className='text-danger font-bold'>*</span>
				</Form.Label>
				<Form.Control placeholder='Olympic Heights Townhomes' onChange={e => handleChange(e)} name='property_name' value={formResponse.property_name} required />
			</Form.Group>
			<Form.Group className='mb-3'>
				<Form.Label>
					Address <span className='text-danger font-bold'>*</span>
				</Form.Label>
				<Form.Control as='textarea' className='allow-resize' rows={3} placeholder={`Building 5\n50 Castilian Dr\nGoleta CA, 93117`} onChange={e => handleChange(e)} name='address' value={formResponse.address} />
			</Form.Group>
			<Form.Group className='mb-3'>
				<Form.Label>
					Phone <span className='text-danger font-bold'>*</span>
				</Form.Label>
				<Form.Control placeholder='(123) 456-7890' onChange={e => handleChange(e)} name='phone' type='phone' value={formResponse.phone} />
			</Form.Group>
			<Form.Group className='mb-3'>
				<Form.Label>
					Email <span className='text-danger font-bold'>*</span>
				</Form.Label>
				<Form.Control placeholder='websitehosting@appfolio.com' onChange={e => handleChange(e)} name='email' type='email' value={formResponse.email} />
			</Form.Group>
		</>
	);
};

export default ContactDetails;
