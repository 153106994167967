export const defaultPropertyValues = {
	'What is the name of your property?': '',
	'Where is your property located and what is great about its location?': '',
	'What amenities or standout features does your property offer?': '',
	'Why do tenants enjoy living at your property?': '',
	'What type of floor plans does your property offer?': '',
	'What is the pet policy for your property?': '',
	'Is there anything else unique about your property that you would like to add?': ''
};

export const defaultCompanyValues = {
	'What is the name of your company?': '',
	'Where are you located and which areas do you serve?': '',
	'What standout features or services do you offer?': '',
	'Why do tenants enjoy renting from you?': '',
	'What type of properties or floor plans does your company specialize in?': '',
	'What is the pet policy for your company?': '',
	'Is there anything else unique about your company that you would like to add?': ''
};
