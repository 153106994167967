import { useState, useEffect } from 'react';
import { Button, Form, Row, Col, Modal } from 'react-bootstrap';
import { ButtonGroup } from '../Components';
import { Field, useFormikContext } from 'formik';
import BriefFieldsEnum from 'config/BriefFieldsEnum.json';

const DomainInfo = ({ handlePrevStep, handleNextStep }) => {
	// State
	const [showModal, setShowModal] = useState(false);

	// Hooks
	const { values: formState } = useFormikContext();
	const { domain_ownership, domain_name, domain_registrar, is_email_set_up_on_this_domain } = formState;

	// Enum
	const { is_email_set_up_on_this_domain_enum, domain_ownership_enum } = BriefFieldsEnum;

	// Proceed
	let allowToProceed = false;
	if (domain_ownership.includes('owns') && domain_name && domain_registrar && is_email_set_up_on_this_domain) {
		allowToProceed = true;
	}
	if (domain_ownership === 'AppFolio purchased') {
		allowToProceed = true;
	}

	// Show modal
	useEffect(() => {
		if (domain_ownership === 'AppFolio purchased') {
			setShowModal(true);
		}
	}, [domain_ownership]);

	// Logo options map
	const domainOptionsMap = {
		'Client owns directly': 'Yes, I own my domain name',
		'IT contact owns': 'Yes, someone else owns my domain name',
		'AppFolio purchased': 'No, I do not own a domain name'
	};

	// Render
	return (
		<>
			<Modal show={showModal} onHide={() => setShowModal(false)} backdrop='static'>
				<Modal.Header>
					<Modal.Title>
						<i className='fas fa-exclamation-circle text-warning me-1'></i> Don't have a domain name?
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>No problem! During our kick-off call we can decide on a domain name for your website and purchase one on your behalf. This cost is included in the one-time website build fee.</p>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='primary' onClick={() => setShowModal(false)}>
						OK, great! <i className='fas fa-check'></i>
					</Button>
				</Modal.Footer>
			</Modal>
			<h4 className='font-semibold text-2xl'>Branding</h4>
			<Row>
				<Col lg={6}>
					<Form.Group className='mb-3'>
						<Form.Label>
							Do you have a domain name you would like to use? <span className='text-danger fw-bold'>*</span>
						</Form.Label>
						<Field as={Form.Select} name='domain_ownership'>
							<option></option>
							{domain_ownership_enum.map(option => (
								<option key={option} value={option}>
									{domainOptionsMap[option]}
								</option>
							))}
						</Field>
						<Form.Text>If not, we can purchase one for you.</Form.Text>
					</Form.Group>
				</Col>
				{domain_ownership.includes('owns') && (
					<Col lg={6}>
						<Form.Group className='mb-3'>
							<Form.Label>
								Domain Name <span className='text-danger fw-bold'>*</span>
							</Form.Label>
							<Field as={Form.Control} name='domain_name' required={domain_ownership.includes('owns') ? true : false} />
							<Form.Text>The domain name itself. ie. yourdomain.com</Form.Text>
						</Form.Group>
					</Col>
				)}
			</Row>
			{domain_ownership.includes('owns') && (
				<Row>
					<Col lg={6}>
						<Form.Group className='mb-3'>
							<Form.Label>
								Domain Registrar <span className='text-danger fw-bold'>*</span>
							</Form.Label>
							<Field as={Form.Control} name='domain_registrar' required={domain_ownership.includes('owns') ? true : false} />
							<Form.Text>Which company do you own the domain through? ie. GoDaddy, Google Domains etc.</Form.Text>
						</Form.Group>
					</Col>
					<Col lg={6}>
						<Form.Group className='mb-3'>
							<Form.Label>
								Is email set up on this domain? <span className='text-danger fw-bold'>*</span>
							</Form.Label>
							<Field as={Form.Select} name='is_email_set_up_on_this_domain' required={domain_ownership.includes('owns') ? true : false}>
								<option></option>
								{is_email_set_up_on_this_domain_enum.map(option => (
									<option key={option}>{option}</option>
								))}
							</Field>
							<Form.Text>Do you have email addresses ending with @yourdomain.com?</Form.Text>
						</Form.Group>
					</Col>
				</Row>
			)}
			<ButtonGroup>
				<Button variant='secondary' type='button' onClick={() => handlePrevStep()}>
					<i className='fas fa-angle-left'></i> Previous
				</Button>
				<Button variant='primary' type='button' onClick={() => handleNextStep()} disabled={allowToProceed ? false : true}>
					Next <i className='fas fa-angle-right'></i>
				</Button>
			</ButtonGroup>
		</>
	);
};

export default DomainInfo;
