import { tidyDate, tidyUrl, tidyTime } from 'utils/Tidy';
import { Table } from 'components/shared/Table';
import LoadingOverlay from 'components/shared/LoadingOverlay';

const DataTable = ({ data, type }) => {
	const columns = 'repeat(3, minmax(15rem, 1.5fr)) repeat(2, minmax(10rem, 1fr))';

	return (
		<Table.Container>
			<Table.Header $columns={columns}>
				<Table.Cell>Company Name</Table.Cell>
				<Table.Cell>Existing Website URL</Table.Cell>
				<Table.Cell>Website Template</Table.Cell>
				<Table.Cell>Submitted</Table.Cell>
				<Table.Cell>
					Updated <i className='fas fa-angle-down text-secondary ms-1'></i>
				</Table.Cell>
			</Table.Header>
			<Table.Body>
				{data ? (
					data.length > 0 ? (
						data.map(item => (
							<Table.Row key={item._id} to={`/${type}/${item._id}`} $columns={columns}>
								<Table.Cell>{item.company_name}</Table.Cell>
								<Table.Cell>{tidyUrl(item.website_url)}</Table.Cell>
								<Table.Cell>{item.website_template}</Table.Cell>
								<Table.Cell>
									{item.submitted && (
										<>
											<div>
												<i className='fas fa-check-circle text-success me-2'></i>
												{tidyDate(item.submitted)}
											</div>
											<small className='text-secondary ms-4'>{tidyTime(item.submitted)}</small>
										</>
									)}
								</Table.Cell>
								<Table.Cell>
									<div>{tidyDate(item.updatedAt)}</div>
									<small className='text-secondary'>{tidyTime(item.updatedAt)}</small>
								</Table.Cell>
							</Table.Row>
						))
					) : (
						<Table.NoData>No data to display.</Table.NoData>
					)
				) : (
					<LoadingOverlay />
				)}
			</Table.Body>
		</Table.Container>
	);
};

export default DataTable;
