import MyProjectPlans from 'components/Plan/Plans/MyProjectPlans';
import MyBriefsSurveys from 'components/Briefs/MyBriefsSurveys';
import CreativeBrief from 'components/Brief/CreativeBrief/CreativeBrief';
import Edit from 'components/Edit/Edit';
import EditUser from 'components/Edit/EditUser';
import SiteGenieDetails from 'components/SiteGenie/Details/Details';
import New from 'components/Plan/New/New';
import Plan from 'components/Plan/Plan';
import Properties from 'components/Properties/Properties';
import Footer from './Footer';
import MessageBar from 'components/shared/MessageBar';
import NotFound from 'components/pages/NotFound';
import { Routes, Navigate, Route, Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { useLogin } from 'utils/UserContext';
import Dashboard from 'components/Admin/Dashboard';
import Info from 'components/Association/Details/Info';

const Component = styled.main`
	grid-area: main;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	overflow: hidden auto;
`;

const Wrapper = styled.div`
	max-width: 100%;
	width: 1280px;
	margin: 0 auto;
	padding: 2rem;

	@media (max-width: 767px) {
		padding: 1rem;
	}
`;

export default function Main() {
	// Hooks
	const { user } = useLogin();
	const { isAdmin } = { ...user };

	return (
		<Component id='main'>
			<MessageBar />
			<Wrapper>
				<Routes>
					<Route index element={<Navigate to='plans' />} />
					<Route path='admin' element={isAdmin ? <Outlet /> : <Navigate to='/' />}>
						<Route path='dashboard' element={<Dashboard />} />
					</Route>
					<Route path='association'>
						<Route path=':id' element={<Info />} />
					</Route>
					<Route path='brief'>
						<Route path=':id' element={<CreativeBrief />} />
					</Route>
					<Route path='briefs' element={<MyBriefsSurveys />} />
					<Route path='edit'>
						<Route path='user' element={<EditUser />} />
						<Route path=':id' element={<Edit />} />
					</Route>
					<Route path='plan'>
						<Route path='new' element={<New />} />
						<Route path=':id' element={<Plan />} />
					</Route>
					<Route path='plans' element={<MyProjectPlans />} />
					<Route path='properties'>
						<Route path=':id' element={<Properties />} />
					</Route>
					<Route path='site-genie'>
						<Route path=':id' element={<SiteGenieDetails />} />
					</Route>
					<Route path='*' element={<NotFound />} />
				</Routes>
			</Wrapper>
			<Footer />
		</Component>
	);
}
