import { Form, Row, Col } from 'react-bootstrap';
import { Field } from 'formik';
import TextareaAutosize from 'react-textarea-autosize';
import { PinWrapper } from '../Pinned';

const DetailsToDisplay = ({ section }) => {
	return (
		<>
			<Row>
				<Col>
					<PinWrapper section={section} name='contact_details'>
						<Form.Label>Contact Details</Form.Label>
						<Field as={TextareaAutosize} minRows={3} className='form-control' name='contact_details' />
					</PinWrapper>
				</Col>
			</Row>
			<Row>
				<Col lg={4}>
					<PinWrapper section={section} name='social_media_accounts'>
						<Form.Label>Social Media Accounts</Form.Label>
						<Field as={TextareaAutosize} minRows={3} className='form-control' name='social_media_accounts' />
					</PinWrapper>
				</Col>
				<Col lg={4}>
					<PinWrapper section={section} name='contact_form_notification_emails'>
						<Form.Label>Contact Form Notification Emails</Form.Label>
						<Field as={TextareaAutosize} minRows={3} className='form-control' name='contact_form_notification_emails' />
					</PinWrapper>
				</Col>
				<Col lg={4}>
					<PinWrapper section={section} name='associations'>
						<Form.Label>Associations</Form.Label>
						<Field as={TextareaAutosize} minRows={3} className='form-control' name='associations' />
					</PinWrapper>
				</Col>
			</Row>
		</>
	);
};

export default DetailsToDisplay;
