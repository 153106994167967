import { Row, Form, Col, Button } from 'react-bootstrap';
import { ButtonGroup } from '../Components';

const PreliminaryInfo = ({ handleChange, formResponse, nextStep, prevStep }) => {
	return (
		<>
			<h4 className='mb-3 text-2xl font-semibold'>Preliminary Information</h4>
			<Row>
				<Col lg={6}>
					<Form.Group className='mb-3'>
						<Form.Label>
							Primary Contact Name <span className='text-danger fw-bold'>*</span>
						</Form.Label>
						<Form.Control type='text' name='primary_contact_name' defaultValue={formResponse?.primary_contact_name} onChange={e => handleChange(e)} required />
					</Form.Group>
				</Col>
				<Col lg={6}>
					<Form.Group className='mb-3'>
						<Form.Label>
							Primary Contact Email <span className='text-danger fw-bold'>*</span>
						</Form.Label>
						<Form.Control type='email' name='primary_contact_email' defaultValue={formResponse?.primary_contact_email} onChange={e => handleChange(e)} required />
					</Form.Group>
				</Col>
			</Row>
			<Row>
				<Col lg={6}>
					<Form.Group className='mb-3'>
						<Form.Label>Does your association have an existing website?</Form.Label>
						<Form.Check type='radio' name='does_your_association_have_an_existing_website' label='Yes' value='Yes' defaultChecked={formResponse?.does_your_association_have_an_existing_website === 'Yes'} id='does_your_association_have_an_existing_website_yes' onChange={e => handleChange(e)} />
						<Form.Check type='radio' name='does_your_association_have_an_existing_website' label='No' value='No' defaultChecked={formResponse?.does_your_association_have_an_existing_website === 'No'} id='does_your_association_have_an_existing_website_no' onChange={e => handleChange(e)} />
					</Form.Group>
				</Col>
				<Col lg={6}>
					{formResponse?.does_your_association_have_an_existing_website === 'Yes' && (
						<Form.Group className='mb-3'>
							<Form.Label>What is your current website's URL?</Form.Label>
							<Form.Control type='url' name='current_website_url' defaultValue={formResponse?.current_website_url} onChange={e => handleChange(e)} />
							<Form.Text>Copy and paste the website URL from your browser's address bar.</Form.Text>
						</Form.Group>
					)}
				</Col>
			</Row>
			{formResponse?.does_your_association_have_an_existing_website === 'Yes' && (
				<Row>
					<Col lg={6}>
						<Form.Group className='mb-3'>
							<Form.Label>Does your association own the above domain?</Form.Label>
							<Form.Check type='radio' name='does_your_association_own_the_above_domain' label='Yes' value='Yes' defaultChecked={formResponse?.does_your_association_own_the_above_domain === 'Yes'} id='does_your_association_own_the_above_domain_yes' onChange={e => handleChange(e)} />
							<Form.Check type='radio' name='does_your_association_own_the_above_domain' label='No' value='No' defaultChecked={formResponse?.does_your_association_own_the_above_domain === 'No'} id='does_your_association_own_the_above_domain_no' onChange={e => handleChange(e)} />
						</Form.Group>
					</Col>
					<Col lg={6}>
						{formResponse?.does_your_association_own_the_above_domain === 'Yes' && (
							<Form.Group className='mb-3'>
								<Form.Label>Which registrar does your association own this domain through?</Form.Label>
								<Form.Control name='domain_registrar' defaultValue={formResponse?.domain_registrar} onChange={e => handleChange(e)} />
								<Form.Text>eg. GoDaddy, Google Domains, NameCheap, etc.</Form.Text>
							</Form.Group>
						)}
					</Col>
				</Row>
			)}
			<ButtonGroup>
				<Button variant='secondary' onClick={() => prevStep()} className='m-1'>
					<i className='fas fa-angle-left'></i> Previous
				</Button>
				<Button variant='primary' onClick={() => nextStep()} className='m-1' disabled={formResponse?.primary_contact_name && formResponse?.primary_contact_email ? false : true}>
					Next <i className='fas fa-angle-right'></i>
				</Button>
			</ButtonGroup>
			<hr className='my-2 text-gray-200' />
		</>
	);
};

export default PreliminaryInfo;
