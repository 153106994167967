import { useEffect, useRef } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { ButtonGroup } from '../Components';
import StepNumber from '../StepNumber';
import styled from 'styled-components';

const Wrapper = styled.div`
	position: relative;
	top: 20%;

	@media (max-width: 991px) {
		position: static;
	}
`;

const TextContent = ({ prevStep, nextStep, handleChange, formResponse }) => {
	// Scroll to element
	const scrollRef = useRef();
	const scrollToElement = () => scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

	useEffect(() => {
		// Scroll to element if on desktop
		if (window.matchMedia('(min-width: 992px)').matches) {
			scrollToElement();
		}
	}, []);

	return (
		<Wrapper ref={scrollRef}>
			<Row className='align-items-center' noGutters>
				<Col xs='auto'>
					<StepNumber number={3} />
				</Col>
				<Col>
					<h5 className='text-xl font-semibold'>Text Content</h5>
				</Col>
			</Row>
			<p>This is where we'll include some welcoming text about your Association. If you have marketing materials or an existing website, we can likely use the same content that has already been written.</p>
			<Form.Group className='mb-3'>
				<Form.Label>Main website text</Form.Label>
				<Form.Control as='textarea' rows={8} name='main_website_text' defaultValue={formResponse?.main_website_text} onChange={e => handleChange(e)} />
				<Form.Text>The text content you would like to include on the website home page.</Form.Text>
			</Form.Group>
			<ButtonGroup>
				<Button variant='secondary' onClick={() => prevStep()} className='m-1'>
					<i className='fas fa-angle-left'></i> Previous
				</Button>
				<Button variant='primary' onClick={() => nextStep()} className='m-1'>
					Next <i className='fas fa-angle-right'></i>
				</Button>
			</ButtonGroup>
		</Wrapper>
	);
};

export default TextContent;
