import { Button, Form, Row, Col } from 'react-bootstrap';
import { ButtonGroup } from '../Components';
import { Field, useFormikContext } from 'formik';
import BriefFieldsEnum from 'config/BriefFieldsEnum.json';
import TextareaAutosize from 'react-textarea-autosize';

const Branding = ({ handleNextStep, handlePrevStep }) => {
	// Hooks
	const { values: formState } = useFormikContext();

	// Enum
	const { logo_status_enum } = BriefFieldsEnum;

	// Proceed
	const allowToProceed = formState.logo_status && formState.color_scheme && formState.photos;

	// Logo options map
	const logoOptionsMap = {
		'Customer has provided': 'Yes',
		'AppFolio will create': 'No',
		'Follow up with customer to provide': 'I am currently having a logo designed'
	};

	return (
		<>
			<h4 className='font-semibold text-2xl'>Branding</h4>
			<Row>
				<Col lg={6}>
					<Form.Group className='mb-3'>
						<Form.Label>
							Do you have an existing logo? <span className='text-danger fw-bold'>*</span>
						</Form.Label>
						<Field as={Form.Select} name='logo_status' required>
							<option></option>
							{logo_status_enum.map(option => (
								<option key={option} value={option}>
									{logoOptionsMap[option]}
								</option>
							))}
						</Field>
						<Form.Text>If not, we can design a basic text logo for you.</Form.Text>
					</Form.Group>
				</Col>
				{formState.logo_status === 'AppFolio will create' && (
					<Col lg={6}>
						<Form.Group className='mb-3'>
							<Form.Label>Logo Thoughts</Form.Label>
							<Field as={TextareaAutosize} className='form-control' name='logo_notes' minRows={3} />
							<Form.Text>Any details about what you would like the basic text logo to look like.</Form.Text>
						</Form.Group>
					</Col>
				)}
			</Row>
			<Row>
				<Col lg={6}>
					<Form.Group className='mb-3'>
						<Form.Label>
							Color Scheme <span className='text-danger fw-bold'>*</span>
						</Form.Label>
						<Field type='text' className='form-control' name='color_scheme' required />
						<Form.Text>Which colors would you like to use on the website?</Form.Text>
					</Form.Group>
				</Col>
				<Col lg={6}>
					<Form.Group className='mb-3'>
						<Form.Label>
							Photos <span className='text-danger fw-bold'>*</span>
						</Form.Label>
						<Field as={TextareaAutosize} className='form-control' name='photos' minRows={3} required />
						<Form.Text>Describe the types of photos you would like to use on the home page.</Form.Text>
					</Form.Group>
				</Col>
			</Row>
			<ButtonGroup>
				<Button variant='secondary' type='button' onClick={() => handlePrevStep()}>
					<i className='fas fa-angle-left'></i> Previous
				</Button>
				<Button variant='primary' type='button' onClick={() => handleNextStep()} disabled={allowToProceed ? false : true}>
					Next <i className='fas fa-angle-right'></i>
				</Button>
			</ButtonGroup>
		</>
	);
};

export default Branding;
