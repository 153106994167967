import Goal from './Goal';
import styled from 'styled-components';
import { calculateDueDate, calculateStatus } from 'utils/Stages';
import { tidyDatePicker } from 'utils/Tidy';

const Component = styled.div`
	margin: 2rem auto;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	justify-content: center;
`;

const Timeline = ({ on_hold, target_dates, actual_dates, stage }) => {
	// Props
	const { start, collect, build, review, live } = { ...target_dates };
	const { start: actual_start, collect: actual_collect, build: actual_build, review: actual_review, live: actual_live } = { ...actual_dates };
	const dueDate = calculateDueDate(stage, target_dates);
	const status = calculateStatus(on_hold, stage, dueDate);

	// Maps
	const goalMap = [
		{
			stage: 'welcome',
			date: start,
			completed: actual_start
		},
		{
			stage: 'collect',
			date: collect,
			completed: actual_collect
		},
		{
			stage: 'build',
			date: build,
			completed: actual_build
		},
		{
			stage: 'review',
			date: review,
			completed: actual_review
		},
		{
			stage: 'live',
			date: live,
			completed: actual_live
		}
	];

	return (
		<Component>
			{goalMap.map(goal => (
				<Goal key={goal.stage} stage={goal.stage} currentStage={stage} date={tidyDatePicker(goal.date)} completed={goal.completed} status={status} />
			))}
		</Component>
	);
};

export default Timeline;
