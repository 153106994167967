import { useContext, createContext, useState } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
	const [user, setUser] = useState(null);
	return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
};

export const useLogin = () => {
	return useContext(UserContext);
};
