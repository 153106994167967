import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import useSWR from 'swr';
import { Survey } from 'components/shared/Surveys';

// Steps
import Welcome from './Screens/1-Welcome';
import Basics from './Screens/2-NameLocation';
import Features from './Screens/3-Features';
import Details from './Screens/4-Details';
import Final from './Screens/5-Final';
import Review from './Screens/6-Review';
import Complete from './Screens/7-Complete';
import { fetcher } from 'utils/Fetch';
import { useMessage } from 'utils/MessageContext';
import { useForm } from 'react-hook-form';
import { defaultPropertyValues, defaultCompanyValues } from './defaultValues';

// Screens
const allScreens = [
	{
		component: Welcome,
		name: 'Welcome'
	},
	{
		component: Basics,
		name: 'Basics'
	},
	{
		component: Features,
		name: 'Features'
	},
	{
		component: Details,
		name: 'Details'
	},
	{
		component: Final,
		name: 'Final'
	},
	{
		component: Review,
		name: 'Review'
	},
	{
		component: Complete,
		name: 'Complete'
	}
];

const InitialSurvey = () => {
	// Component state
	const [currentScreen, setCurrentScreen] = useState(1);
	const [allowToProceed, setAllowToProceed] = useState(false);
	const [submittedDate, setSubmittedDate] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	// Hooks
	const { setMessage } = useMessage();
	const navigate = useNavigate();
	const { id } = useParams();
	const [searchParams] = useSearchParams();

	const { data: jwt } = useSWR('/auth/jwt');
	const { data: plan } = useSWR(jwt ? `/api/v1/plans/${id}` : null);
	const { data: siteGenieDoc } = useSWR(jwt ? `/api/v1/site-genie/${id}` : null);

	// Search params
	const skipWelcome = searchParams.get('skipWelcome');
	if (currentScreen === 1 && skipWelcome === 'true') setCurrentScreen(2);

	// Plan
	const { appfolio_contact, purpose } = { ...plan };
	const { data: owner } = useSWR(jwt && appfolio_contact ? `/api/v1/users/email/${appfolio_contact}` : null);

	// Form state
	let defaultValues = {};
	if (purpose === 'Single Property') defaultValues = defaultPropertyValues;
	if (purpose === 'Company Site') defaultValues = defaultCompanyValues;
	const questions = Object.keys(defaultValues);

	const {
		formState: { errors, isValid },
		getValues,
		handleSubmit,
		register
	} = useForm();

	const formValues = getValues();

	// Handlers
	const handleFormSubmit = async values => {
		setIsLoading(true);
		try {
			const { initial_survey } = await fetcher(`/api/v1/site-genie/initial_survey/${id}`, { method: 'POST', body: JSON.stringify({ ...values, submitted: new Date() }) });
			setSubmittedDate(initial_survey?.submitted);
			setCurrentScreen(allScreens.length);
		} catch (error) {
			setMessage({ variant: 'danger', text: 'There was an error submitting your survey. Please try again.' });
		}
		setTimeout(() => setIsLoading(false), 500);
	};

	// Effects
	useEffect(() => {
		if (siteGenieDoc?.initial_survey?.submitted) {
			navigate('/expired', { state: { error: { message: 'This survey has already been submitted! A website onboarding specialist will be in touch with next steps.' } } });
		}
	}, [navigate, siteGenieDoc?.initial_survey?.submitted]);

	useEffect(() => {
		isValid || currentScreen === 1 ? setAllowToProceed(true) : setAllowToProceed(false);
	}, [currentScreen, isValid]);

	return (
		<>
			<Helmet>
				<title>Initial Survey | AppFolio Sites</title>
			</Helmet>
			{questions.length > 0 && (
				<Survey.Form onSubmit={handleSubmit(handleFormSubmit)} currentScreen={currentScreen} setCurrentScreen={setCurrentScreen}>
					<Survey.Header heading='Initial Survey' subheading='AppFolio Sites' />
					<Survey.Progress screens={allScreens} currentScreen={currentScreen} />
					<Survey.Screens screens={allScreens} currentScreen={currentScreen} owner={owner} register={register} submittedDate={submittedDate} questions={questions} formValues={formValues} siteGenieDoc={siteGenieDoc} purpose={purpose} />
					<Survey.Buttons screens={allScreens} currentScreen={currentScreen} setCurrentScreen={setCurrentScreen} allowToProceed={allowToProceed} errors={errors} isLoading={isLoading} />
				</Survey.Form>
			)}
		</>
	);
};

export default InitialSurvey;
