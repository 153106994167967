import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import ObjectID from 'bson-objectid';
import { Plus } from 'lucide-react';
import { animations } from '@formkit/drag-and-drop';
import { useDragAndDrop } from '@formkit/drag-and-drop/react';
import MaterialsListRow from 'components/shared/MaterialsList/MaterialsListRow';
import TextareaAutosize from 'react-textarea-autosize';

const MaterialsList = ({ assets, setAssets }) => {
	// State
	const [newMaterial, setNewMaterial] = useState({ name: '', description: '' });

	// Hooks
	const [parentRef, values, setValues] = useDragAndDrop(assets, { dragHandle: '.lucide-grip-horizontal', plugins: [animations()], handleEnd: () => setAssets(values) });

	// Handlers
	const handleToggle = id => {
		const newMaterials = [...assets];
		const asset = newMaterials.find(asset => asset._id === id);
		asset.complete = !asset.complete;
		setAssets(newMaterials);
	};

	const handleAddMaterial = () => {
		if (!newMaterial.name) return;
		const addAsset = {
			_id: ObjectID().toHexString(),
			name: newMaterial.name,
			description: newMaterial.description,
			complete: false
		};
		const newMaterials = [addAsset, ...assets];
		setAssets(newMaterials);
		setValues(newMaterials);
		setNewMaterial({ name: '', description: '' });
	};

	const handleEdit = (id, newInfo) => {
		const newMaterials = [...assets];
		const asset = newMaterials.find(asset => asset._id === id);
		asset.name = newInfo.name;
		asset.description = newInfo.description;
		setAssets(newMaterials);
	};

	const handleDelete = id => {
		const newMaterials = [...assets.filter(asset => asset._id !== id)];
		setValues(newMaterials);
		setAssets(newMaterials);
	};

	return (
		<div className='space-y-2'>
			<div className='grid sm:grid-cols-[1fr_2fr_auto] gap-2 items-end'>
				<div>
					<Form.Label>Name</Form.Label>
					<Form.Control value={newMaterial.name} onChange={e => setNewMaterial(prev => ({ ...prev, name: e.target.value }))} type='text' />
				</div>
				<div>
					<Form.Label>
						Description{' '}
						<a className='text-sm text-sky-600' href='https://markdownlivepreview.com/' target='_blank' rel='noopener noreferrer'>
							(markdown supported)
						</a>
					</Form.Label>
					<Form.Control as={TextareaAutosize} rows={1} value={newMaterial.description} onChange={e => setNewMaterial(prev => ({ ...prev, description: e.target.value }))} />
				</div>
				<Button
					onClick={() => handleAddMaterial()}
					onKeyDown={e => {
						if (e.code === 'Enter') handleAddMaterial();
					}}
					title='Add item to checklist'
					className='flex items-center justify-center gap-2 sm:w-48 default-style'>
					Add material <Plus size={16} />
				</Button>
			</div>
			<div ref={parentRef} className='divide-y'>
				{values.map(asset => (
					<MaterialsListRow key={asset._id} asset={asset} handleToggle={handleToggle} handleEdit={handleEdit} handleDelete={handleDelete} />
				))}
			</div>
		</div>
	);
};

export default MaterialsList;
