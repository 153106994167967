import styled from 'styled-components';

export const Panel = styled.div`
	background: white;
	padding: 1.5rem;
	border-radius: 0.5rem;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
	position: relative;
	display: grid;
	gap: 0.5rem;

	@media (max-width: 576px) {
		padding: 1rem;
	}
`;
