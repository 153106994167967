import { Survey } from 'components/shared/Surveys';
import { FormControl, FormLabel } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';

const Features = ({ register, questions }) => {
	return (
		<Survey.Container>
			<h4 className='font-semibold text-2xl'>Name & location</h4>
			<div>
				<FormLabel>
					{questions[4]} <strong className='text-danger'>*</strong>
				</FormLabel>
				<FormControl as={TextareaAutosize} minRows={5} {...register(questions[4], { required: true })} />
			</div>
			<div>
				<FormLabel>
					{questions[5]} <strong className='text-danger'>*</strong>
				</FormLabel>
				<FormControl as={TextareaAutosize} minRows={5} {...register(questions[5], { required: true })} />
			</div>
		</Survey.Container>
	);
};

export default Features;
