import { Button } from 'react-bootstrap';
import { ButtonGroup } from '../Components';
import styled from 'styled-components';
import { handleMissingImage } from 'utils/Errors';

const ContentWrapper = styled.div`
	display: flex;
	gap: 6rem;
	align-items: start;

	@media (max-width: 767px) {
		flex-direction: column;
		gap: 4rem;
	}
`;

const DesignerPanel = styled.div`
	flex: 0 0 15rem;
	text-align: center;
	display: grid;
	justify-content: end;

	@media (max-width: 767px) {
		justify-content: center;
	}
`;

const WelcomePanel = styled.div`
	background: white;
	padding: 2rem;
	border-radius: 0.5rem;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
	position: relative;

	&::after {
		content: '';
		height: 0;
		width: 0;
		border-top: 2rem solid transparent;
		border-bottom: 2rem solid transparent;
		border-right: 3rem solid white;
		z-index: 1;
		position: absolute;
		top: 2.5rem;
		left: -3rem;
	}

	@media (max-width: 767px) {
		&::after {
			content: '';
			height: 0;
			width: 0;
			border-left: 2rem solid transparent;
			border-right: 2rem solid transparent;
			border-bottom: 3rem solid white;
			z-index: 1;
			position: absolute;
			top: -5rem;
			left: calc(50% - 2rem);
		}
	}

	p:last-child {
		margin: 0;
	}
`;

const Profile = styled.img`
	border-radius: 50%;
	height: 8rem;
	width: 8rem;
	margin: 0 0 1rem;
`;

const Title = styled.p`
	margin: 0;
`;

const Location = styled.p`
	margin: 0;
	color: var(--bs-secondary);
`;

const Welcome = ({ owner, handleNextStep }) => {
	// State
	const { imageUrl, name, jobTitle, userLocation } = { ...owner };

	// Render
	return (
		<>
			<ContentWrapper>
				<DesignerPanel>
					<div>
						<Profile src={imageUrl} onError={({ currentTarget }) => handleMissingImage(currentTarget)} alt={name} />
						<h5 className='font-semibold'>{name}</h5>
						<Title>{jobTitle}</Title>
						<Location>{userLocation}</Location>
					</div>
				</DesignerPanel>
				<WelcomePanel>
					<h4 className='text-2xl font-semibold'>Welcome!</h4>
					<p>We are excited you chose AppFolio to build your new website. To help start the process and get the most from our upcoming kick-off call, please complete this survey to the best of your ability.</p>
					<p>This survey is designed to guide our conversation during our first call and help you brainstorm what you want for your new website. If you are unsure of any of the items, no problem! Please add any questions or further notes when you are asked for final thoughts and we will review them together on our call.</p>
					<p>Thank you!</p>
				</WelcomePanel>
			</ContentWrapper>
			<ButtonGroup>
				<Button variant='primary' type='button' onClick={() => handleNextStep()}>
					Get started <i className='fas fa-angle-right'></i>
				</Button>
			</ButtonGroup>
		</>
	);
};

export default Welcome;
