import { Accordion } from 'react-bootstrap';
import { Collapse, Section, Toggle } from './Components';
import styled from 'styled-components';
import { useMessage } from 'utils/MessageContext';
import { Panel } from 'components/shared/Panel';
import { fetcher } from 'utils/Fetch';
import { useParams } from 'react-router-dom';
import { tidyDateTime } from 'utils/Tidy';

const Component = styled.div`
	display: grid;
	gap: 1.5rem;
`;

const Alert = styled(Panel)`
	color: var(--bs-warning);
	border-left: 0.5rem solid var(--bs-warning);
`;

const Heading = styled.div`
	display: grid;
	grid-template-columns: max-content min-content min-content;
	gap: 0.75rem;
	align-items: start;
`;

const RoundButton = styled.button`
	all: unset;
	width: 1.75rem;
	height: 1.75rem;
	font-size: 0.9rem;
	background: var(--lightest);
	display: grid;
	place-items: center;
	border-radius: 50%;
	color: var(--bs-secondary);
	transition: 0.2s;
`;

const CopyButton = styled(RoundButton)`
	&:hover,
	&:focus-visible {
		color: var(--bs-primary);
	}
`;

const DeleteButton = styled(RoundButton)`
	&:hover,
	&:focus-visible {
		color: var(--bs-danger);
	}
`;

const ContentPanel = styled(Panel)`
	gap: 0.75rem;
	white-space: break-spaces;

	h5 {
		margin: 0;
	}
`;

const CustomAccordion = styled(Accordion)`
	background: var(--lightest);
	padding: 0.5rem 1rem;
	border-radius: 0.5rem;
`;

const Content = ({ content, mutate }) => {
	// Hooks
	const { setMessage } = useMessage();
	const { id } = useParams();

	// Handlers
	const handleCopyContent = (type, content) => {
		navigator.clipboard.writeText(content);
		setMessage({
			variant: 'info',
			text: (
				<>
					<i className='fas fa-copy me-2'></i>
					{type} successfully copied to clipboard.
				</>
			)
		});
	};

	const handleDeleteContent = async content_id => {
		try {
			await fetcher(`/api/v1/site-genie/${id}/content/${content_id}`, { method: 'DELETE' });
			await mutate();
		} catch (error) {
			setMessage({ variant: 'danger', text: 'Error deleting Site Genie content' });
		}
	};

	return (
		<Section>
			<h4 className='text-lg font-semibold'>Content</h4>
			<Component>
				<Alert>
					<div>
						<i className='fas fa-exclamation-circle me-2'></i> Before using this content on the website, make sure you read through all the text thoroughly and:
					</div>
					<ul className='list-disc list-inside'>
						<li>Check to make sure this text represents the client's company accurately.</li>
						<li>Make sure this content abides by Fair Housing Laws and any other applicable legislation.</li>
						<li>For example, DO NOT reference relationship, individual, couple, or family size status.</li>
					</ul>
				</Alert>
				{content
					?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
					.map(content => (
						<ContentPanel key={content._id}>
							<small className='text-secondary'>Generated {tidyDateTime(content.createdAt)}</small>
							<Heading>
								<h5 className='text-lg font-bold'>{content.content_type}</h5>
								<CopyButton onClick={() => handleCopyContent(content.content_type, content.response)} title='Copy this content'>
									<i className='fas fa-copy'></i>
								</CopyButton>
								<DeleteButton onClick={() => handleDeleteContent(content._id)} title='Delete this content'>
									<i className='fas fa-trash-alt'></i>
								</DeleteButton>
							</Heading>
							<div>{content.response}</div>
							<CustomAccordion>
								<Toggle eventKey={1}>
									<div>
										<i className='fas fa-info-circle text-muted me-1'></i> View Prompt Parameters <i className='fas fa-angle-down text-muted ms-1'></i>
									</div>
								</Toggle>
								<Collapse eventKey={1}>
									<div>
										<div>
											<strong>Tone:</strong> {content.tone}
										</div>
										<div>
											<strong>Word Count:</strong> {content.word_count}
										</div>
										<div>
											<strong>Survey:</strong> {content.survey}
										</div>
									</div>
								</Collapse>
							</CustomAccordion>
						</ContentPanel>
					))}
			</Component>
		</Section>
	);
};

export default Content;
