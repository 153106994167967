import { Button, Form, Col, Row, Accordion } from 'react-bootstrap';
import { ButtonGroup } from '../Components';
import styled from 'styled-components';

import { Field, useFormikContext } from 'formik';
import { AccordionToggle } from 'components/shared/Accordion';

const Panel = styled.div`
	background: white;
	padding: 1rem;
	border-radius: 0.5rem;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
	margin: 0 0 1.5rem;
	line-height: 1.75;

	p:last-of-type {
		margin: 0;
	}
`;

const CheckWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;

	i {
		margin-bottom: 0.5rem;
	}
`;

const CustomAccordion = styled(Accordion)`
	background: var(--border);
	border: 2px solid var(--bs-light);
	padding: 1rem;
	border-radius: 0.5rem;
	margin: 0 0 1.5rem;
`;

const Toggle = styled(AccordionToggle)`
	width: 100%;
	cursor: pointer;
	color: var(--bs-primary);
	text-align: center;
`;

const Content = styled.div`
	margin: 0.75rem 0 0;
`;

const List = styled.ol`
	margin: 0.75rem 0 0;

	li {
		margin: 0.5rem 0;

		&:last-of-type {
			margin: 0;
		}
	}
`;

const Accessibility = ({ handleNextStep, handlePrevStep }) => {
	// Hooks
	const { values: formState } = useFormikContext();

	// Proceed
	const allowToProceed = formState.accessibility_statement;

	return (
		<>
			<h4 className='text-2xl font-semibold'>Website Accessibility</h4>
			<Row>
				<Col lg={6}>
					<p>You can choose to add the following Website Accessibility Statement to your website. Please note that it is your responsibility to uphold its obligations and respond to all inquiries.</p>
					<p>Please read through the statement carefully and select whether or not you would like to include it on your website.</p>
					<CustomAccordion>
						<Toggle eventKey='0'>
							Why should I include an Accessibility Statement on my website? <i className='fas fa-angle-down ms-1'></i>
						</Toggle>
						<Accordion.Collapse eventKey='0'>
							<Content>
								Accessibility statements are important for several reasons:
								<List>
									<li>They show prospective tenants that you care about accessibility and about them</li>
									<li>They provide prospective tenants with information about the accessibility of your content</li>
									<li>They demonstrate your company’s commitment to accessibility and social responsibility</li>
									<li>They help avoid lawsuits by providing a manner for prospective tenants to raise accessibility issues and suggest improvements</li>
								</List>
							</Content>
						</Accordion.Collapse>
					</CustomAccordion>
				</Col>
				<Col lg={6}>
					<Panel>
						<h5>
							<i className='fas fa-universal-access text-primary me-1'></i> Accessibility Statement
						</h5>
						<p>
							We are committed to the accessibility of our website for all persons, including people with disabilities. In furtherance of our commitment, we proactively work to align our website with the{' '}
							<a href='https://www.w3.org/WAI/standards-guidelines/wcag/' target='_blank' rel='noopener noreferrer' className='text-sky-600'>
								World Wide Web Consortium’s Web Content Accessibility Guidelines (WCAG)
							</a>{' '}
							to support the use of assistive technologies and provide accessible content.
						</p>
						<p>
							If you encounter any accessibility issues while visiting our website, need accessible services and information, or have suggestions for improvement, we encourage you to contact us at <strong>[YOUR EMAIL ADDRESS]</strong> or <strong>[YOUR PHONE NUMBER]</strong>.
						</p>
					</Panel>
					<Form.Group className='mb-3'>
						<Form.Label className='fw-bold'>
							<i className='fas fa-universal-access text-primary me-1'></i> Accessibility Statement <span className='text-danger fw-bold'>*</span>
						</Form.Label>
						<CheckWrapper>
							<Field as={Form.Check} type='radio' name='accessibility_statement' label='I agree and would like to include this on my website' value='Include' id='accessibility_agree' />
							{formState.accessibility_statement === 'Include' && <i className='fas fa-check text-success'></i>}
						</CheckWrapper>
						<CheckWrapper>
							<Field as={Form.Check} type='radio' name='accessibility_statement' label='I disagree and/or do not want to include this on my website' value='Exclude' id='accessibility_disagree' />
							{formState.accessibility_statement === 'Exclude' && <i className='fas fa-times text-danger'></i>}
						</CheckWrapper>
					</Form.Group>
				</Col>
			</Row>
			<ButtonGroup>
				<Button variant='secondary' type='button' onClick={() => handlePrevStep()}>
					<i className='fas fa-angle-left'></i> Previous
				</Button>
				<Button variant='primary' type='button' onClick={() => handleNextStep()} disabled={allowToProceed ? false : true}>
					Next <i className='fas fa-angle-right'></i>
				</Button>
			</ButtonGroup>
		</>
	);
};

export default Accessibility;
