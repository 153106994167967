import styled from 'styled-components';

const Component = styled.div`
	color: white;
	display: inline-grid;
	grid-template-columns: 1rem 1fr;
	padding: 0.25rem 0.75rem;
	border-radius: 999px;
	align-items: center;
	gap: 0.5rem;
	justify-self: start;
	text-transform: uppercase;
	font-size: 0.9rem;
	line-height: 1.25;

	i {
		font-size: 1rem;
		opacity: 0.75;

		&.spin {
			animation: spin 1s infinite;
		}
	}
`;

const StatusPill = ({ status, ...props }) => {
	// Maps
	const statusColorMap = {
		Pending: 'secondary',
		Processing: 'primary',
		Completed: 'success',
		Failed: 'danger'
	};

	const statusIconMap = {
		Pending: 'hourglass-half',
		Processing: 'spinner spin',
		Completed: 'check',
		Failed: 'times'
	};

	return (
		<Component className={`bg-${statusColorMap[status]}`} {...props}>
			<i className={`fas fa-${statusIconMap[status]}`}></i>
			<div>{status}</div>
		</Component>
	);
};

export default StatusPill;
