import MaterialsListViewRow from 'components/shared/MaterialsList/MaterialsListViewRow';
import { Button } from 'react-bootstrap';

const MaterialsListView = ({ assets_url, assets, hideButton }) => {
	return (
		<div>
			{assets?.length > 0 ? (
				<div className='space-y-4'>
					<div className='space-y-1'>
						{assets.map(asset => (
							<MaterialsListViewRow key={asset._id} asset={asset} />
						))}
					</div>
					{assets_url && !hideButton && (
						<Button href={assets_url} target='_blank' rel='noopener noreferrer'>
							Upload Assets <i className='fas fa-upload'></i>
						</Button>
					)}
				</div>
			) : (
				<p>No assets listed.</p>
			)}
		</div>
	);
};

export default MaterialsListView;
