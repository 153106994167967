import { useState, useEffect } from 'react';
import { tidyDate, tidyCapitalize, tidyDatePicker } from 'utils/Tidy';
import styled from 'styled-components';

import { Field, useFormikContext } from 'formik';
import { Form, Modal, Button } from 'react-bootstrap';

const Breadcrumbs = styled.div`
	display: grid;
	justify-content: center;
	position: relative;
	height: 3rem;

	&::after {
		content: '';
		position: absolute;
		width: calc(100% - 1rem);
		height: 0.5rem;
		background: #ddd;
		top: 50%;
		left: 50%;
		transform: translateY(-50%);
	}
`;

const Icon = styled.div`
	height: 2.5rem;
	width: 2.5rem;
	border-radius: 50%;
	background: ${({ $color }) => `var(--bs-${$color})`};
	color: white;
	display: grid;
	place-items: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
`;

const EditIcon = styled.i`
	font-size: 0.75rem;
	opacity: 0;
	visibility: hidden;
	transition: 0.2s;
`;

const Details = styled.button`
	all: unset;

	&:hover,
	&:focus-visible {
		${EditIcon} {
			opacity: 1;
			visibility: visible;
		}
	}

	@media (max-width: 576px) {
		font-size: 0.75rem;
	}
`;

const Title = styled.div`
	font-size: 1.25rem;

	@media (max-width: 576px) {
		font-size: 1rem;
	}
`;

const Date = styled.div`
	color: var(--bs-secondary);
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;
`;

const DateField = styled(Field)`
	box-shadow: none !important;
	background: var(--lightest);
`;

const Component = styled.div`
	display: grid;
	justify-content: stretch;
	text-align: center;
	gap: 1rem;
	position: relative;

	&.active {
		${Icon} {
			height: 3.75rem;
			width: 3.75rem;
			font-size: 1.5rem;
		}

		${Title} {
			font-weight: bold;
		}
	}

	&.inactive {
		${Icon} {
			background: #ddd;
		}

		${Details} {
			color: #bbb;
		}
	}

	&.completed:not(.active) {
		${Breadcrumbs}::after {
			background: ${({ $color }) => `var(--bs-${$color})`};
		}
	}

	&.completed.active {
		${Breadcrumbs}::after {
			background: ${({ $color }) => `linear-gradient(to right, var(--bs-${$color}), #ddd)`};
		}
	}

	&:last-of-type {
		${Breadcrumbs}::after {
			display: none;
		}
	}
`;

const Body = styled(Modal.Body)`
	display: grid;
	gap: 1.25rem;
`;

const Goal = ({ stage, currentStage, date, completed, status }) => {
	// State
	const [isActive, setIsActive] = useState(false);
	const [isComplete, setIsComplete] = useState(false);
	const [showDateModal, setShowDateModal] = useState(false);

	// Hooks
	const { setFieldValue, values } = useFormikContext();

	// Effects
	useEffect(() => {
		if (tidyCapitalize(stage) === currentStage) {
			setIsActive(true);
		}
		if (completed) {
			setIsComplete(true);
		}
		if (currentStage === 'Live') {
			setIsActive(false);
			setIsComplete(true);
		}
		if (status === 'On Hold') {
			setIsActive(false);
			setIsComplete(false);
		}
	}, [stage, currentStage, completed, status]);

	// Maps
	const statusMap = {
		'On Time': 'success',
		Delayed: 'warning',
		Overdue: 'danger',
		Complete: 'primary',
		'On Hold': 'danger'
	};

	// Maps
	const goalMap = {
		welcome: 'start',
		collect: 'collect',
		build: 'build',
		review: 'review',
		live: 'live'
	};

	// Handlers
	const handleDateChange = e => {
		const { name, value } = e.target;
		setFieldValue(name, tidyDatePicker(value));
	};

	return (
		<Component className={`${isActive ? 'active' : !isComplete ? 'inactive' : ''} ${isComplete ? 'completed' : ''}`} $color={statusMap[status]}>
			<Modal show={showDateModal} onHide={() => setShowDateModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Select a date</Modal.Title>
				</Modal.Header>
				<Body>
					<div>
						<div className='mb-1'>
							Please select a new date for the <strong>{tidyCapitalize(stage)}</strong> stage.
						</div>
						<DateField as={Form.Control} type='date' name={`target_dates.${goalMap[stage]}`} value={date} onChange={e => handleDateChange(e)} />
					</div>
					{stage === 'review' && (
						<>
							<div>
								<div className='mb-1'>
									Optionally, select a new date for the <strong>Revisions 1</strong> due date.
								</div>
								<DateField as={Form.Control} type='date' name={`target_dates.revisions_1`} value={values.target_dates.revisions_1} min={values.target_dates.review} max={values.target_dates.live} onChange={e => handleDateChange(e)} />
							</div>
							<div>
								<div className='mb-1'>
									Optionally, select a new date for the <strong>Revisions 2</strong> due date.
								</div>
								<DateField as={Form.Control} type='date' name={`target_dates.revisions_2`} value={values.target_dates.revisions_2} min={values.target_dates.review} max={values.target_dates.live} onChange={e => handleDateChange(e)} />
							</div>
						</>
					)}
					<Button variant='primary' onClick={() => setShowDateModal(false)} style={{ justifySelf: 'center' }}>
						<i className='fas fa-check'></i> Done
					</Button>
				</Body>
			</Modal>
			<Breadcrumbs $color={statusMap[status]}>
				<Icon $color={statusMap[status]}>{isActive ? <i className='fas fa-angle-double-right'></i> : isComplete && <i className='fas fa-check'></i>}</Icon>
			</Breadcrumbs>
			<Details type='button' onClick={() => setShowDateModal(true)}>
				<Title>{tidyCapitalize(stage)}</Title>
				<Date>
					{tidyDate(date)} <EditIcon className='fas fa-pen' />
				</Date>
			</Details>
		</Component>
	);
};

export default Goal;
