import { useState } from 'react';
import styled from 'styled-components';
import { calculateDueDate, calculateStatus } from 'utils/Stages';
import { tidyDatePicker } from 'utils/Tidy';
import { mutate } from 'swr';
import { fetcher } from 'utils/Fetch';
import { useParams } from 'react-router-dom';
import { useMessage } from 'utils/MessageContext';
import EditableGoal from './EditableGoal';

const Component = styled.div`
	margin: 3rem auto;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	justify-content: center;
`;

const EditableTimeline = ({ plan }) => {
	// Props
	const { on_hold, target_dates, actual_dates, stage } = { ...plan };
	const { start: actual_start, collect: actual_collect, build: actual_build, review: actual_review, live: actual_live } = { ...actual_dates };
	const dueDate = calculateDueDate(stage, target_dates);
	const status = calculateStatus(on_hold, stage, dueDate);

	// State
	const [dates, setDates] = useState(target_dates || {});

	// Hooks
	const { id } = useParams();
	const { setMessage } = useMessage();

	// Maps
	const goalMap = [
		{
			stage: 'welcome',
			date: dates.start,
			completed: actual_start
		},
		{
			stage: 'collect',
			date: dates.collect,
			completed: actual_collect
		},
		{
			stage: 'build',
			date: dates.build,
			completed: actual_build
		},
		{
			stage: 'review',
			date: dates.review,
			completed: actual_review
		},
		{
			stage: 'live',
			date: dates.live,
			completed: actual_live
		}
	];

	// Handlers
	const handleDateChange = async e => {
		const { name, value } = e.target;
		setDates(prev => ({ ...prev, [name]: value }));
	};

	const handleDateSubmit = async () => {
		try {
			await fetcher(`/api/v1/plans/${id}`, { method: 'PUT', body: JSON.stringify({ target_dates: dates }) });
			mutate(`/api/v1/plans/${id}`);
		} catch (error) {
			setMessage({
				variant: 'danger',
				text: 'Could not update date.'
			});
		}
	};

	return (
		<Component>
			{goalMap.map(goal => (
				<EditableGoal key={goal.stage} dates={dates} stage={goal.stage} currentStage={stage} date={tidyDatePicker(goal.date)} completed={goal.completed} status={status} handleDateChange={handleDateChange} handleDateSubmit={handleDateSubmit} />
			))}
		</Component>
	);
};

export default EditableTimeline;
