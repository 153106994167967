import { tidyDateTime } from 'utils/Tidy';
import { Survey } from 'components/shared/Surveys';
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const Complete = ({ owner, submittedDate, purpose, siteGenieDoc }) => {
	// Props
	const { expanded_survey } = { ...siteGenieDoc };

	// Hooks
	const { id } = useParams();

	// Render
	return (
		<Survey.OwnerDialog owner={owner}>
			<h4 className='font-semibold text-2xl'>Thank you!</h4>
			<p>
				Your Initial Survey was submitted successfully on <strong>{tidyDateTime(submittedDate)}.</strong>
			</p>
			{purpose === 'Company Site' && !expanded_survey?.submitted ? (
				<div>
					<p>As part of your website package, we can also have content created for the services pages (tenant/management services, real estate, commercial, etc.) of your website at no additional cost.</p>
					<p>If you would like to take advantage of this service, please complete the Expanded Survey by clicking the button below.</p>
					<Button href={`/site-genie/${id}/survey/expanded?skipWelcome=true`}>
						Begin Expanded Survey <i className='fas fa-angle-right'></i>
					</Button>
				</div>
			) : (
				<p className='mb-0'>I will be in contact with you shortly to schedule a project kickoff call. You can now close this window.</p>
			)}
		</Survey.OwnerDialog>
	);
};

export default Complete;
