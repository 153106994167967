import { useAccordionButton } from 'react-bootstrap';
import styled from 'styled-components';

const AccordionToggleComponent = styled.button`
	all: unset;
`;

export const AccordionToggle = ({ eventKey, ...props }) => {
	// Hooks
	const handleClick = useAccordionButton(eventKey, () => null);

	return <AccordionToggleComponent type='button' {...props} onClick={handleClick} />;
};
