import { useRef, useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { Field } from 'formik';
import TextareaAutosize from 'react-textarea-autosize';
import { PinWrapper } from '../Pinned';
import styled from 'styled-components';
import BriefFieldsEnum from 'config//BriefFieldsEnum.json';

const RevealButton = styled.button`
	all: unset;
	position: absolute;
	bottom: 0;
	right: 0.25rem;
	opacity: 0.25;
	padding: 0.45rem;
	transition: 0.2s;

	&:hover,
	&:focus-visible {
		opacity: 1;
	}
`;

const DomainInfo = ({ section }) => {
	// State
	const [passType, setPassType] = useState('password');

	// Hooks
	const passRef = useRef(null);

	// Handlers
	const toggleReveal = () => {
		passType === 'password' ? setPassType('text') : setPassType('password');
	};

	// Enum
	const { domain_ownership_enum, is_email_set_up_on_this_domain_enum } = BriefFieldsEnum;

	return (
		<>
			<Row>
				<Col lg={6}>
					<PinWrapper section={section} name='domain_name'>
						<Form.Label>Domain Name</Form.Label>
						<Field as={Form.Control} name='domain_name' />
					</PinWrapper>
				</Col>
				<Col lg={6}>
					<PinWrapper section={section} name='domain_registrar'>
						<Form.Label>Domain Registrar</Form.Label>
						<Field as={Form.Control} name='domain_registrar' />
					</PinWrapper>
				</Col>
			</Row>
			<Row>
				<Col lg={6}>
					<PinWrapper section={section} name='domain_ownership'>
						<Form.Label>Domain Ownership</Form.Label>
						<Field as={Form.Select} name='domain_ownership'>
							<option></option>
							{domain_ownership_enum.map(option => (
								<option key={option}>{option}</option>
							))}
						</Field>
					</PinWrapper>
				</Col>
				<Col lg={6}>
					<PinWrapper section={section} name='is_email_set_up_on_this_domain'>
						<Form.Label>Is email set up on this domain?</Form.Label>
						<Field as={Form.Select} name='is_email_set_up_on_this_domain'>
							<option></option>
							{is_email_set_up_on_this_domain_enum.map(option => (
								<option key={option}>{option}</option>
							))}
						</Field>
					</PinWrapper>
				</Col>
			</Row>
			<Row>
				<Col lg={6}>
					<PinWrapper section={section} name='registrar_username'>
						<Form.Label>Registrar Username</Form.Label>
						<Field as={Form.Control} name='registrar_username' autoComplete='off' />
					</PinWrapper>
				</Col>
				<Col lg={6}>
					<PinWrapper section={section} name='registrar_password'>
						<Form.Label>Registrar Password</Form.Label>
						<Form.Control as={Field} type={passType} name='registrar_password' innerRef={passRef} autoComplete='off' />
						<RevealButton type='button' onClick={toggleReveal}>
							{passType === 'password' ? <i className='fas fa-eye'></i> : <i className='fas fa-eye-slash'></i>}
						</RevealButton>
					</PinWrapper>
				</Col>
			</Row>
			<Row>
				<Col>
					<PinWrapper section={section} name='go_live_notes'>
						<Form.Label>Go-Live Notes</Form.Label>
						<Field as={TextareaAutosize} minRows={3} className='form-control' name='go_live_notes' />
					</PinWrapper>
				</Col>
			</Row>
		</>
	);
};

export default DomainInfo;
