import { useState } from 'react';
import PageHeader from 'components/shared/PageHeader';
import styled from 'styled-components';
import { Section, Field, Name, Value } from 'components/shared/Survey';
import { useParams } from 'react-router-dom';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import { Button, Form } from 'react-bootstrap';
import { tidyUnderscores, tidyDateTime } from 'utils/Tidy';
import { Helmet } from 'react-helmet';
import Confirmation from 'components/shared/Confirmation';
import { useMessage } from 'utils/MessageContext';
import { ButtonBar, BarButton, StickyButtons } from 'components/shared/ButtonBar';
import { fetcher } from 'utils/Fetch';
import useSWR, { mutate } from 'swr';
import EditableTimeline from 'components/shared/EditableTimeline/EditableTimeline';

const Wrapper = styled.div`
	max-width: 1280px;
	margin: 0 auto;

	.row {
		margin-bottom: 1rem;
	}

	h4 {
		margin: 1rem 0;
	}

	.form-label {
		font-weight: bold;
	}

	.custom-control {
		margin-bottom: 0.5rem;
	}
`;

const NoResults = styled(Value)`
	text-align: center;
`;

const Info = () => {
	// Component state
	const [additionalNotes, setAdditionalNotes] = useState('');
	const [showConfirmation, setShowConfirmation] = useState(false);

	// Hooks
	const { id } = useParams();
	const { setMessage } = useMessage();
	const { data: plan } = useSWR(`/api/v1/plans/${id}`);
	const { data: survey } = useSWR(`/api/v1/associations/survey/${id}`);

	// Handle form submit
	const handleSubmit = async e => {
		// Prevent page refresh
		e.preventDefault();

		// POST to API
		try {
			const payload = { ...survey, additional_notes: additionalNotes };
			await fetcher(`/api/v1/associations/survey/${id}`, { method: 'PUT', body: JSON.stringify(payload) });
			mutate(`/api/v1/associations/survey/${id}`);
			setMessage({
				variant: 'success',
				text: 'Additional notes were saved successfully.'
			});
		} catch (error) {
			setMessage({
				variant: 'danger',
				text: 'There was an error saving additional notes.'
			});
		}
	};

	// Handle delete response
	const handleDelete = async () => {
		setShowConfirmation(false);
		try {
			await fetcher(`/api/v1/associations/survey/${id}`, { method: 'DELETE' });
			mutate(`/api/v1/associations/survey/${id}`);
			setMessage({
				variant: 'success',
				text: 'Kickoff survey response and notes were deleted successfully.'
			});
		} catch (error) {
			setMessage({
				variant: 'danger',
				text: 'There was an error deleting this survey response.'
			});
		}
	};

	return survey && plan ? (
		<>
			<Helmet>
				<title>Association Info | Project Plan Tool</title>
			</Helmet>
			<Confirmation show={showConfirmation} setShow={setShowConfirmation} message={`delete this survey response and any additional notes.`} confirmFunction={handleDelete} buttonType={'danger'} />
			<PageHeader heading='Association Info' subheading='View the response from the Community Association Survey.' />
			<ButtonBar>
				<BarButton to={`/plan/${id}`}>
					<i className='fas fa-angle-left'></i> Back to Project Plan
				</BarButton>
				<BarButton to={`/association/${id}/survey`} target='_blank' rel='noopener noreferrer'>
					{survey?.submitted && <i className='text-danger fas fa-exclamation-circle'></i>} Association Survey Link <i className='fas fa-external-link-alt'></i>
				</BarButton>
			</ButtonBar>
			<Wrapper>
				<EditableTimeline plan={plan} />
				{survey?.submitted ? (
					<Section>
						<Section>
							<Form onSubmit={e => handleSubmit(e)}>
								<h4 className='text-2xl font-semibold'>Notes</h4>
								<Form.Group className='mb-3'>
									<Form.Label>Additional Notes</Form.Label>
									<Form.Control as='textarea' className='allow-resize' rows={5} value={additionalNotes} onChange={e => setAdditionalNotes(e.target.value)}></Form.Control>
									<Form.Text>Keep additional notes or comments regarding this association website build here.</Form.Text>
								</Form.Group>
							</Form>
						</Section>
						<hr className='my-2 text-gray-200' />
						<Section>
							<h4 className='text-2xl font-semibold'>Community Association Survey Response</h4>
							<p className='ms-2 text-secondary'>
								<i className='fas fa-calendar-check me-1'></i> Submitted: {tidyDateTime(survey?.submitted)}
							</p>
							{Object.entries(survey).map(([key, value]) => (
								<Field key={key}>
									<Name>{tidyUnderscores(key)}</Name>
									<Value>
										{value
											.split('\n')
											.filter(text => text)
											.map((text, i) => (
												<p key={i}>{text}</p>
											))}
									</Value>
								</Field>
							))}
						</Section>
					</Section>
				) : (
					<Field>
						<NoResults>The kickoff survey has not been submitted.</NoResults>
					</Field>
				)}
			</Wrapper>
			{survey?.submitted && (
				<StickyButtons>
					<Button type='submit' onClick={e => handleSubmit(e)} disabled={additionalNotes ? false : true}>
						Save notes <i className='fas fa-save'></i>
					</Button>
					<Button variant='danger' onClick={() => setShowConfirmation(true)}>
						Delete response <i className='fas fa-trash-alt'></i>
					</Button>
				</StickyButtons>
			)}
		</>
	) : (
		<LoadingOverlay />
	);
};

export default Info;
