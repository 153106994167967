import { Panel } from 'components/shared/Panel';
import { Survey } from 'components/shared/Surveys';
import styled from 'styled-components';
import { tidyFormResponse } from 'utils/Tidy';

const InfoPanel = styled(Panel)`
	border-left: 0.5rem solid var(--bs-primary);
`;

const Finalize = ({ formValues }) => {
	const survey = tidyFormResponse(formValues);

	return (
		<Survey.Container>
			<h4 className='text-2xl font-semibold'>Marketing Survey Review</h4>
			<InfoPanel>
				<h5 className='text-lg font-semibold text-sky-600'>REVIEW:</h5>
				<div>
					Please review your responses below and hit <strong>Submit</strong> if everything looks correct.
				</div>
			</InfoPanel>
			<Survey.Review survey={survey} />
		</Survey.Container>
	);
};

export default Finalize;
