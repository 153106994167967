import styled from 'styled-components';

const Component = styled.div`
	margin-bottom: 2rem;

	@media (max-width: 579px) {
		margin-bottom: 1rem;
	}
`;

const PageHeader = ({ heading, subheading, ...props }) => {
	return (
		<Component {...props}>
			<h1 className='mb-2 text-4xl font-bold'>{heading}</h1>
			<div className='text-gray-500'>{subheading}</div>
		</Component>
	);
};

export default PageHeader;
