import { useState } from 'react';
import { Form, FormCheck } from 'react-bootstrap';
import { Check, ChevronDown, GripHorizontal, Pen, Trash2 } from 'lucide-react';
import Markdown from 'react-markdown';
import { cn } from 'utils/Render';
import TextareaAutosize from 'react-textarea-autosize';

const MaterialsListRow = ({ asset, handleToggle, handleDelete, handleEdit }) => {
	// State
	const [isEditing, setIsEditing] = useState(false);
	const [isExpanded, setIsExpanded] = useState(false);
	const [newInfo, setNewInfo] = useState({ name: asset.name, description: asset.description });

	// Handlers
	const handleEditAsset = () => {
		handleEdit(asset._id, newInfo);
		setIsEditing(false);
	};

	return (
		<div className='flex items-start justify-between gap-3 py-2' style={{ backgroundColor: 'var(--bs-lightest)' }}>
			{!isEditing ? (
				<div className='flex items-start w-full gap-3 mt-2'>
					<FormCheck.Input type='checkbox' defaultChecked={asset.complete} onChange={() => handleToggle(asset._id)} />
					<div>
						{asset.description ? (
							<button className='flex items-center' type='button' onClick={() => setIsExpanded(!isExpanded)}>
								<div className={cn(asset.complete ? 'line-through' : '', 'break-all')}>{asset.name}</div>
								<ChevronDown size={16} className='ml-2 text-slate-500' />
							</button>
						) : (
							<div className={cn(asset.complete ? 'line-through' : '', 'break-all')}>{asset.name}</div>
						)}
						{isExpanded && (
							<Markdown
								className={cn(asset.complete ? 'line-through' : '', 'mb-2 text-sm break-all')}
								components={{
									a: props => (
										<a {...props} className='text-sky-600' target='_blank' rel='noopener noreferrer'>
											{props.children}
										</a>
									)
								}}>
								{asset.description}
							</Markdown>
						)}
					</div>
				</div>
			) : (
				<div className='w-full py-2 space-y-3'>
					<div className='space-y-1'>
						<div className='text-sm text-slate-500'>Name</div>
						<Form.Control type='text' value={newInfo.name} onChange={e => setNewInfo(prev => ({ ...prev, name: e.target.value }))} />
					</div>
					<div className='space-y-1'>
						<div className='text-sm text-slate-500'>Description</div>
						<Form.Control as={TextareaAutosize} value={newInfo.description} onChange={e => setNewInfo(prev => ({ ...prev, description: e.target.value }))} />
					</div>
				</div>
			)}
			<div className='flex items-center'>
				{!isEditing ? (
					<button type='button' onClick={() => setIsEditing(true)} className='grid duration-100 rounded-full text-sky-600 size-9 hover:bg-sky-100 place-items-center'>
						<Pen size={14} />
					</button>
				) : (
					<button type='button' onClick={() => handleEditAsset()} className='grid text-green-600 duration-100 rounded-full size-9 hover:bg-green-100 place-items-center'>
						<Check size={16} />
					</button>
				)}
				<button type='button' onClick={() => handleDelete(asset._id)} className='grid text-red-600 duration-100 rounded-full size-9 hover:bg-red-100 place-items-center'>
					<Trash2 size={14} />
				</button>
				<button type='button' className='grid text-gray-600 duration-100 rounded-full size-9 hover:bg-gray-200 place-items-center'>
					<GripHorizontal size={14} />
				</button>
			</div>
		</div>
	);
};

export default MaterialsListRow;
