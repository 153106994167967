import { useEffect } from 'react';
import { useMessage } from 'utils/MessageContext';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { SWRConfig } from 'swr';
import { fetcher } from 'utils/Fetch';
import ReactGA from 'react-ga4';
import { config } from 'config/config';

// Components
import AssociationSurvey from 'components/Association/Survey/Survey';
import BriefContent from 'components/Brief/Content';

import BriefSurvey from 'components/Brief/Survey/Survey';
import BriefView from 'components/Brief/View';
import Error from 'components/pages/Error';
import Expired from 'components/pages/Expired';
import SiteGenieInitialSurvey from 'components/SiteGenie/Survey/Initial/Survey';
import SiteGenieExpandedSurvey from 'components/SiteGenie/Survey/Expanded/Survey';
import SiteGenieInitialView from 'components/SiteGenie/View/Initial';
import SiteGenieExpandedView from 'components/SiteGenie/View/Expanded';
import Login from 'components/pages/Login';
import PropertiesSurvey from 'components/Properties/Survey/Survey';
import ViewProperties from 'components/Properties/View';
import ViewPlan from 'components/Plan/View';
import ProtectedRoute from 'components/layout/ProtectedRoute';
import NotFound from 'components/pages/NotFound';

// On route changes
const usePageChange = () => {
	const location = useLocation();
	const { setMessage } = useMessage();

	// Initialize GA
	useEffect(() => {
		ReactGA.initialize(config.GOOGLE_ANALYTICS_ID);
	}, []);

	// Clear message bar
	useEffect(() => {
		setMessage(null);
	}, [location, setMessage]);
};

export default function App() {
	// Hooks
	const { message, setMessage } = useMessage();
	const { pathname } = useLocation();
	const navigate = useNavigate();
	usePageChange();

	// Effects
	useEffect(() => {
		if (message) {
			const { persist } = { ...message };
			const timeout = !persist ? setTimeout(() => setMessage(null), 5000) : null;
			return () => clearTimeout(timeout);
		}
	}, [message, setMessage]);

	// Redirect v2 routes
	useEffect(() => {
		if (pathname.includes('/v2/')) {
			const newPath = pathname.replaceAll('/v2/', '/');
			navigate(newPath);
		}
	}, [navigate, pathname]);

	return (
		<SWRConfig value={{ revalidateOnFocus: false, shouldRetryOnError: false, fetcher: url => fetcher(url) }}>
			<Routes>
				<Route path='association/:id/survey' element={<AssociationSurvey />} />
				<Route path='brief/:id/content' element={<BriefContent />} />
				<Route path='brief/:id/survey' element={<BriefSurvey />} />
				<Route path='brief/:id/view' element={<BriefView />} />
				<Route path='error' element={<Error />} />
				<Route path='expired' element={<Expired />} />
				<Route path='login' element={<Login />} />
				<Route path='properties/:id/survey' element={<PropertiesSurvey />} />
				<Route path='properties/:id/view' element={<ViewProperties />} />
				<Route path='site-genie/:id/survey/initial' element={<SiteGenieInitialSurvey />} />
				<Route path='site-genie/:id/survey/expanded' element={<SiteGenieExpandedSurvey />} />
				<Route path='site-genie/:id/view/initial' element={<SiteGenieInitialView />} />
				<Route path='site-genie/:id/view/expanded' element={<SiteGenieExpandedView />} />
				<Route path='view/:id' element={<ViewPlan />} />
				<Route path='*' element={<ProtectedRoute />} />
				<Route path='*' element={<NotFound />} />
			</Routes>
		</SWRConfig>
	);
}
