import { Section } from './Components';
import styled from 'styled-components';
import { FormSelect, FormCheck, FormControl, Button } from 'react-bootstrap';
import { Panel } from 'components/shared/Panel';
import { useForm } from 'react-hook-form';
import { fetcher } from 'utils/Fetch';
import { mutate } from 'swr';
import { useMessage } from 'utils/MessageContext';
import { useParams } from 'react-router-dom';

const Prompts = styled.div`
	margin-top: 0.5rem;
	display: grid;
	gap: 1.5rem;
`;

const InlinePrompts = styled(Prompts)`
	grid-template-columns: repeat(2, 1fr);
	align-items: start;

	@media (max-width: 767px) {
		grid-template-columns: 100%;
	}
`;

const InputLabel = styled.label`
	margin: 0;
	font-weight: bold;
`;

const PromptRow = styled.div`
	display: grid;
	gap: 0.25rem;
`;

const PromptControl = styled(FormControl)`
	box-shadow: none;
	border: 0;
	background-color: var(--lightest) !important;
`;

const PromptSelect = styled(FormSelect)`
	box-shadow: none;
	border: 0;
	resize: none;
	height: auto;
	background-color: var(--lightest);
	border-radius: 0.5rem;
	padding: 0.5rem 0.75rem;
	position: relative;

	&:disabled {
		color: var(--bs-gray-600);
	}
`;

const CheckRow = styled.div`
	display: grid;
	grid-template-columns: 15rem min-content;
	align-items: center;

	${PromptControl} {
		width: 5rem;
	}
`;

const Checks = styled.div`
	margin-top: 0.5rem;
	display: grid;
	gap: 0.5rem;
`;

const WordCount = styled.div`
	display: grid;
	grid-template-columns: repeat(2, auto);
	gap: 0.5rem;
	align-items: center;
`;

const Form = styled.form`
	display: grid;
	gap: 1.5rem;
`;

const Buttons = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 15rem);
	gap: 0.75rem;

	@media (max-width: 576px) {
		grid-template-columns: 100%;
		gap: 0;
	}
`;

const Prompt = ({ siteGenieData, site_type }) => {
	// Props
	const { initial_survey, expanded_survey } = { ...siteGenieData };
	const excludeTypes = ['Website Content', 'submitted'];
	const contentTypes = expanded_survey && Object.keys(expanded_survey).filter(type => !excludeTypes.includes(type));

	// Hooks
	const { id } = useParams();
	const { register, watch, reset, handleSubmit } = useForm({ defaultValues: { content_to_generate: [] } });
	const showCustomTone = watch('tone') === 'Custom';
	const { setMessage } = useMessage();

	// State
	const disableGenerateContent = watch('content_to_generate').length === 0;
	const disablePushToWebsite = true;

	// Handlers
	const submitHandler = async values => {
		try {
			const { word_count: unfiltered_word_count, ...rest } = values;
			const word_count = Object.entries(unfiltered_word_count)
				.map(([key, value]) => ({ name: key, word_count: Number(value) }))
				.filter(content => rest.content_to_generate.includes(content.name));

			const payload = { id, site_type, initial_survey, expanded_survey, word_count, ...rest };
			await fetcher('/api/v1/site-genie/prompt', { method: 'POST', body: JSON.stringify(payload) });
			await mutate(`/api/v1/site-genie/${id}`);
			reset();
			setMessage({ variant: 'success', text: `Site Genie content is being generated. You will receive a Slack notification upon completion.` });
		} catch (error) {
			setMessage({ variant: 'danger', text: `Error submitting to Site Genie: ${error.message}` });
		}
	};

	return (
		<>
			<Section>
				<h4 className='font-semibold text-2xl'>Prompt</h4>
				<Form onSubmit={handleSubmit(submitHandler)}>
					<Panel>
						<div>
							<i className='fas fa-info-circle text-muted me-2'></i>Select the correct options below to match the content you require:
						</div>
						<InlinePrompts>
							<PromptRow>
								<InputLabel>
									<i className='fas fa-question-circle text-muted me-2' title='Edit this value in the Project Plan'></i>Website Purpose
								</InputLabel>
								<PromptSelect value={site_type} disabled>
									<option>Single Property</option>
									<option>Company Site</option>
									<option>Community Association</option>
								</PromptSelect>
							</PromptRow>
							<PromptRow>
								<InputLabel>Writing Tone</InputLabel>
								<PromptSelect {...register('tone')}>
									<option>Professional</option>
									<option>Extra Professional</option>
									<option>Friendly</option>
									<option>Casual</option>
									<option>Modern</option>
									<option>Shakespearean</option>
									<option>Custom</option>
								</PromptSelect>
							</PromptRow>
							{showCustomTone && (
								<>
									<PromptRow>
										<InputLabel>Custom Tone</InputLabel>
										<PromptControl {...register('custom_tone')} />
									</PromptRow>
									<div></div>
								</>
							)}
							{initial_survey && (
								<div>
									<InputLabel>Initial Survey</InputLabel>

									<Checks>
										<CheckRow>
											<FormCheck {...register('content_to_generate')} type='checkbox' id='marketing_description' value='Marketing Description' label='Marketing Description' />
											<WordCount>
												<PromptControl type='number' {...register('word_count[Marketing Description]')} defaultValue={125} min={5} max={250} />
												<div>words</div>
											</WordCount>
										</CheckRow>
										<CheckRow>
											<FormCheck {...register('content_to_generate')} type='checkbox' id='marketing_headline' value='Marketing Headline' label='Marketing Headline' />
											<WordCount>
												<PromptControl type='number' {...register('word_count[Marketing Headline]')} defaultValue={10} min={5} max={50} />
												<div>words</div>
											</WordCount>
										</CheckRow>
									</Checks>
								</div>
							)}
							{expanded_survey && (
								<div>
									<InputLabel>Expanded Survey</InputLabel>
									<Checks>
										{contentTypes?.map(type => (
											<CheckRow key={type}>
												<FormCheck {...register('content_to_generate')} type='checkbox' id={type} value={type} label={type} />
												<WordCount>
													<PromptControl type='number' {...register(`word_count.${type}`)} defaultValue={125} min={5} max={250} />
													<div>words</div>
												</WordCount>
											</CheckRow>
										))}
									</Checks>
								</div>
							)}
						</InlinePrompts>
					</Panel>
					<Buttons>
						<Button type='submit' variant='primary' disabled={disableGenerateContent}>
							Generate Content <i className='fas fa-magic'></i>
						</Button>
						<Button type='button' variant='outline-secondary' disabled={disablePushToWebsite}>
							Push to Website <i className='fas fa-paper-plane'></i>
						</Button>
					</Buttons>
				</Form>
			</Section>
		</>
	);
};

export default Prompt;
