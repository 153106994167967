import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

const Body = styled(Modal.Body)`
	display: grid;
	gap: 1rem;
`;

const DropboxPrompt = ({ show, setShow }) => {
	return (
		<Modal show={show} onHide={() => setShow(false)} backdrop='static'>
			<Modal.Header closeButton>
				<Modal.Title>Just a quick heads up...</Modal.Title>
			</Modal.Header>
			<Body>
				<div>
					Looks like you're using a <strong>Dropbox share link.</strong>
				</div>
				<div>
					That's totally fine, just make sure your customer has a Dropbox account and you have shared this folder with them from{' '}
					<a href='https://www.dropbox.com/' target='_blank' rel='noopener noreferrer' className='text-sky-600'>
						within your Dropbox account.
					</a>
				</div>
				<div>
					If they don't have a Dropbox account, use a file request instead. You can{' '}
					<a href='https://www.dropbox.com/requests' target='_blank' rel='noopener noreferrer' className='text-sky-600'>
						generate a new file request here.
					</a>
				</div>
			</Body>
			<Modal.Footer>
				<Button variant='primary' onClick={() => setShow(false)}>
					OK, I understand.
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default DropboxPrompt;
