import styled from 'styled-components';

const Component = styled.div`
	display: grid;
	align-items: start;
	gap: 1rem;
`;

const WhatWeAreDoing = ({ stage }) => {
	switch (stage) {
		case 'Welcome':
			return (
				<Component>
					<div>I will contact you to begin the website project. Together we will schedule and complete the kick-off call which provides me with information and guidance for your website build.</div>
					<div>We will also outline items that you will need to provide before I am able to begin working on your new website.</div>
				</Component>
			);

		case 'Collect':
			return (
				<Component>
					<div>As discussed, I need items from you before I can start building your website. The required assets are listed in the checklist below.</div> <div>If you are unsure what else you need to provide, please contact me directly.</div>
				</Component>
			);

		case 'Build':
			return (
				<Component>
					<div>I am now building your website based on the template you have chosen and design elements discussed during the kick-off call.</div>
					<div>Once the build is completed, I will email you a preview site link no later than the due date listed below.</div>
				</Component>
			);

		case 'Review':
			return (
				<Component>
					<div>I now need your input to complete the website project. Please review your preview site located at the Preview Site URL link above, and provide me with a consolidated list of changes.</div>
					<div>Revisions will be implemented in two rounds. You can submit the edits by emailing them to me directly, or by using our site comment feature. Please contact me if you would like access to site comments.</div>
				</Component>
			);

		case 'Live':
			return <Component>Nothing, your site is live!</Component>;

		default:
			return null;
	}
};

export default WhatWeAreDoing;
