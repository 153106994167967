import { useLogin } from 'utils/UserContext';
import Helmet from 'react-helmet';
import { fetcher } from 'utils/Fetch';
import { useMessage } from 'utils/MessageContext';
import PageHeader from 'components/shared/PageHeader';
import useSWR from 'swr';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import ProfileImage from 'components/shared/ProfileImage';
import { ExternalLink, Info } from 'lucide-react';
import EditInput from './EditInput';
import { useForm } from 'react-hook-form';

const EditUser = () => {
	// Hooks
	const {
		user: { _id }
	} = useLogin();
	const { setMessage } = useMessage();
	const { data: user, isValidating, mutate } = useSWR(`/api/v1/users/${_id}`);
	const { name, email, imageUrl } = user || {};
	const { register, getValues, handleSubmit: submitForm } = useForm({ values: user });

	// Handlers
	const handleSubmit = async data => {
		try {
			await fetcher(`/api/v1/users/${_id}`, { method: 'PUT', body: JSON.stringify(data) });
			await mutate();
			setMessage({ variant: 'success', text: 'New user info saved successfully.' });
		} catch (error) {
			setMessage({ variant: 'danger', text: error.message || 'Could not save user info.' });
		}
	};

	return (
		<>
			<Helmet>
				<title>Edit User Info | Project Plan Tool</title>
			</Helmet>
			<PageHeader heading='Edit User Info' subheading="Edit the information displayed on my client's project plans." />
			{!user || isValidating ? (
				<LoadingOverlay />
			) : (
				<form onSubmit={submitForm(handleSubmit)} className='max-w-md bg-white divide-y divide-gray-100 rounded-lg shadow divide-solid'>
					<div className='grid justify-center gap-2 p-4 justify-items-center'>
						<ProfileImage src={imageUrl} alt={name} width='100' height='100' className='mx-auto' />
						<div className='text-sky-600'>{email}</div>
						<a href='https://myaccount.google.com/' target='_blank' rel='noopener noreferrer' className='max-w-sm text-sm text-center text-gray-500'>
							Edit your profile picture in your Google account settings
							<ExternalLink className='inline w-4 h-4 ms-2' />
						</a>
					</div>
					<div className='grid gap-2 p-4'>
						<h5 className='text-lg fw-bold'>User Info</h5>
						<EditInput register={register} getValues={getValues} label='Name' name='name' />
						<EditInput register={register} getValues={getValues} label='Title' name='jobTitle' />
						<EditInput register={register} getValues={getValues} label='Location' name='userLocation' />
					</div>
					<div className='grid gap-2 p-4'>
						<h5 className='text-lg fw-bold'>Calendly</h5>
						<EditInput register={register} getValues={getValues} label='Booking URL' name='calendly.booking_url' />
						<EditInput register={register} getValues={getValues} label='Access Token' name='calendly.access_token' />
					</div>
					<div className='grid gap-2 p-4'>
						<div className='flex items-center gap-3 text-gray-500'>
							<Info className='w-8 h-8' />
							<a className='text-sm ' href='https://calendly.com/integrations/api_webhooks' target='_blank' rel='noopener noreferrer'>
								Generate a new Calendly access token here by selecting <strong>Generate New Token</strong>
							</a>
						</div>
					</div>
				</form>
			)}
		</>
	);
};

export default EditUser;
