import styled from 'styled-components';

const Component = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #fffe;
	backdrop-filter: blur(0.25rem);
	display: grid;
	place-items: center;
	z-index: 99999;
	pointer-events: none;
`;

const Spinner = styled.div`
	width: ${({ $small }) => ($small ? '2rem' : '5rem')};
	height: ${({ $small }) => ($small ? '2rem' : '5rem')};
`;

const LoadingOverlay = ({ small }) => {
	return (
		<Component>
			<Spinner className='spinner-border text-primary' $small={small}>
				<span className='sr-only'>Loading...</span>
			</Spinner>
		</Component>
	);
};

export default LoadingOverlay;
