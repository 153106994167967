import { Field } from 'formik';
import { Form, Row, Col } from 'react-bootstrap';
import { PinWrapper } from '../Pinned';
import styled from 'styled-components';

import BriefFieldsEnum from 'config//BriefFieldsEnum.json';
import TextareaAutosize from 'react-textarea-autosize';
import { useTemplates } from 'utils/Templates';
import { Link } from 'react-router-dom';

const CustomInput = styled.div`
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.35rem 0.5rem;
	border-radius: 0.5rem;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	color: var(--bs-warning);
	border: 1px solid;
	background: #fff6eb;
`;

const Design = ({ plan, section }) => {
	// Props
	const { _id, product_tier, purpose } = { ...plan };

	// Templates
	const { getAllowedTemplates, getAdditionalTemplates } = useTemplates();
	const requiresAdditionalTemplates = product_tier === 'S2' && purpose === 'Single Property';
	const allowedTemplates = getAllowedTemplates(product_tier, purpose);
	const additionalTemplates = requiresAdditionalTemplates ? getAdditionalTemplates(product_tier, purpose) : [];
	const templateOptions = [...allowedTemplates, ...additionalTemplates];

	// Enum
	const { logo_status_enum } = BriefFieldsEnum;

	return (
		<>
			<Row>
				<Col lg={6}>
					<PinWrapper section={section} name='website_template'>
						<Form.Label>Website Template</Form.Label>
						{product_tier && purpose ? (
							<Field as={Form.Select} name='website_template'>
								<option></option>
								{templateOptions?.map(template => (
									<option key={template.id}>{template.name}</option>
								))}
							</Field>
						) : (
							<CustomInput>
								<i className='fas fa-exclamation-circle'></i>{' '}
								<span>
									Requires Product Tier & Purpose set directly in the{' '}
									<Link to={`/edit/${_id}`} target='_blank'>
										Project Plan.
									</Link>
								</span>
							</CustomInput>
						)}
					</PinWrapper>
				</Col>
				<Col lg={6}>
					<PinWrapper section={section} name='why_did_the_customer_choose_this_design'>
						<Form.Label>Why did the customer choose this template design?</Form.Label>
						<Field as={TextareaAutosize} minRows={3} className='form-control' name='why_did_the_customer_choose_this_design' />
					</PinWrapper>
				</Col>
			</Row>
			<Row>
				<Col lg={6}>
					<PinWrapper section={section} name='logo_status'>
						<Form.Label className='m-0'>Logo Status</Form.Label>
						<Form.Text>Current status of the logo design</Form.Text>
						<Field as={Form.Select} name='logo_status'>
							<option></option>
							{logo_status_enum.map(status => (
								<option key={status}>{status}</option>
							))}
						</Field>
					</PinWrapper>
				</Col>
				<Col lg={6}>
					<PinWrapper section={section} name='logo_notes'>
						<Form.Label className='m-0'>Logo Notes</Form.Label>
						<Form.Text>Font style, color scheme, content, etc.</Form.Text>
						<Field as={TextareaAutosize} minRows={3} className='form-control' name='logo_notes' />
					</PinWrapper>
				</Col>
			</Row>
			<Row>
				<Col lg={6}>
					<PinWrapper section={section} name='color_scheme'>
						<Form.Label className='m-0'>Color Scheme</Form.Label>
						<Form.Text>Does the customer have a particular color scheme they would like to use?</Form.Text>
						<Field as={Form.Control} type='text' name='color_scheme' />
					</PinWrapper>
				</Col>
				<Col lg={6}>
					<PinWrapper section={section} name='photos'>
						<Form.Label className='m-0'>Photos</Form.Label>
						<Form.Text>What kind of photos does the customer want to use on the home page?</Form.Text>
						<Field as={TextareaAutosize} minRows={3} className='form-control' name='photos' />
					</PinWrapper>
				</Col>
			</Row>
			<Form.Group className='mb-3'>
				<Form.Label>Design Notes</Form.Label>
				<Field as={TextareaAutosize} minRows={5} className='form-control' name='design_notes' />
			</Form.Group>
		</>
	);
};

export default Design;
