import { useEffect, useRef } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { ButtonGroup } from '../Components';
import StepNumber from '../StepNumber';
import styled from 'styled-components';

const Wrapper = styled.div`
	position: relative;
	top: 58%;

	@media (max-width: 991px) {
		position: static;
	}
`;

const FAQs = ({ prevStep, nextStep, handleChange, formResponse }) => {
	// Scroll to element
	const scrollRef = useRef();
	const scrollToElement = () => scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

	useEffect(() => {
		// Scroll to element if on desktop
		if (window.matchMedia('(min-width: 992px)').matches) {
			scrollToElement();
		}
	}, []);

	return (
		<Wrapper ref={scrollRef}>
			<Row className='align-items-center' noGutters>
				<Col xs='auto'>
					<StepNumber number={6} />
				</Col>
				<Col>
					<h5 className='text-xl font-semibold'>FAQs</h5>
				</Col>
			</Row>
			<p>This is a great place to include answers to frequently asked questions about your community.</p>
			<p>The audience here can be current residents (how do I contact the board? What day is trash pickup? How do I reserve the clubhouse?), as well as prospective homeowners and realtors (where do I find governing documents? what is it like to live here?).</p>
			<Form.Group className='mb-3'>
				<Form.Label>FAQ text</Form.Label>
				<Form.Control as='textarea' rows={8} name='faq_text' defaultValue={formResponse?.faq_text} onChange={e => handleChange(e)} />
				<Form.Text>The text content you would like to include in the FAQs section.</Form.Text>
			</Form.Group>
			<ButtonGroup>
				<Button variant='secondary' onClick={() => prevStep()} className='m-1'>
					<i className='fas fa-angle-left'></i> Previous
				</Button>
				<Button variant='primary' onClick={() => nextStep()} className='m-1'>
					Next <i className='fas fa-angle-right'></i>
				</Button>
			</ButtonGroup>
		</Wrapper>
	);
};

export default FAQs;
