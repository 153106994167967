import { Survey } from 'components/shared/Surveys';
import { FormCheck, FormText } from 'react-bootstrap';

const Content = ({ register }) => {
	// Form state
	const checkboxes = ['Tenant Services', 'Management Services', 'Real Estate', 'HOA', 'Student Housing', 'Commercial', 'Investment'];

	return (
		<Survey.Container>
			<h4 className='text-2xl font-semibold'>Website Content</h4>
			<div>
				<div>
					Which of the following types of content do you want to have on your website? <strong className='text-danger'>*</strong>
				</div>
				<FormText className='mb-3'>You will need to answer additional questions for each content type.</FormText>
				{checkboxes.map(checkbox => (
					<FormCheck key={checkbox} id={checkbox}>
						<FormCheck.Input type='checkbox' value={checkbox} {...register('Website Content.Which of the following types of content do you want to have on your website?', { required: true })} />
						<FormCheck.Label>{checkbox}</FormCheck.Label>
					</FormCheck>
				))}
			</div>
		</Survey.Container>
	);
};

export default Content;
