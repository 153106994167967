import { Survey } from 'components/shared/Surveys';
import { FormControl, FormLabel } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';

const Basics = ({ register, questions }) => {
	return (
		<Survey.Container>
			<h4 className='font-semibold text-2xl'>Name & location</h4>
			<div>
				<FormLabel>
					{questions[0]} <strong className='text-danger'>*</strong>
				</FormLabel>
				<FormControl {...register(questions[0], { required: true })} />
			</div>
			<div>
				<FormLabel>
					{questions[1]} <strong className='text-danger'>*</strong>
				</FormLabel>
				<FormControl as={TextareaAutosize} minRows={5} {...register(questions[1], { required: true })} />
			</div>
		</Survey.Container>
	);
};

export default Basics;
