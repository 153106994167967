import { Survey } from 'components/shared/Surveys';

const Welcome = ({ owner }) => {
	return (
		<Survey.OwnerDialog owner={owner}>
			<h4 className='font-semibold text-2xl'>Let’s create content for your website!</h4>
			<p>As part of your website purchase, AppFolio Sites can generate content for the services pages of your website at no additional cost.</p>
			<p>Please complete the following questions in as much detail as possible.</p>
			<p className='mb-0'>This survey should take 5-10 minutes. Let’s get started!</p>
		</Survey.OwnerDialog>
	);
};

export default Welcome;
