import { Survey } from 'components/shared/Surveys';
import { FormLabel, FormControl, FormText } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';

const StudentHousing = ({ register }) => {
	return (
		<Survey.Container>
			<h4 className='font-semibold text-2xl'>Student Housing</h4>
			<div>
				<FormLabel>
					Which school(s) are you near and are you associated with them? <strong className='text-danger'>*</strong>
				</FormLabel>
				<FormControl as={TextareaAutosize} minRows={5} {...register('Student Housing.Which school(s) are you near and are you associated with them?', { required: true })} />
			</div>
			<div>
				<FormLabel className='mb-0'>
					What should students know about your location? <strong className='text-danger'>*</strong>
				</FormLabel>
				<FormText className='mb-3'>Public transportation, local dining, activities & other points of interest</FormText>
				<FormControl as={TextareaAutosize} minRows={5} {...register('Student Housing.What should students know about your location?', { required: true })} />
			</div>
			<div>
				<FormLabel>
					What sets you apart from other student housing providers in your area? <strong className='text-danger'>*</strong>
				</FormLabel>
				<FormControl as={TextareaAutosize} minRows={5} {...register('Student Housing.What sets you apart from other student housing providers in your area?', { required: true })} />
			</div>
		</Survey.Container>
	);
};

export default StudentHousing;
