import { useState, useEffect } from 'react';
import PageHeader from 'components/shared/PageHeader';
import styled from 'styled-components';
import { ProgressBar } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import logo from 'img/app/appfolio_logo.svg';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import { Formik, Form } from 'formik';
import useSWR from 'swr';

// Steps
import Welcome from './Steps/1-Welcome';
import DesignOptions from './Steps/2-DesignOptions';
import WebsiteInfo from './Steps/4-WebsiteInfo';
import Branding from './Steps/5-Branding';
import DomainInfo from './Steps/6-DomainInfo';
import Accessibility from './Steps/7-Accessibility';
import AdditionalInfo from './Steps/8-AdditionalInfo';
import Complete from './Steps/9-Complete';
import { fetcher } from 'utils/Fetch';

const Component = styled.div`
	max-width: 1280px;
	margin: 0 auto;

	.row,
	h4,
	p {
		margin-bottom: 1rem;
	}

	.form-label {
		font-weight: bold;
	}

	.custom-control {
		margin-bottom: 0.5rem;
	}
`;

const Logo = styled.img`
	width: 75px;
	margin-right: 2rem;

	@media (max-width: 767px) {
		width: 50px;
		margin-right: 1rem;
	}
`;

const Progress = styled(ProgressBar)`
	height: 2rem;
	margin-bottom: 2rem;
	font-size: 1rem;
`;

const WritingSurvey = () => {
	// Component state
	const [formState, setFormState] = useState(null);
	const [currentStep, setCurrentStep] = useState(0);
	const [isLoading, setIsLoading] = useState(true);

	// Hooks
	const { id } = useParams();
	const navigate = useNavigate();
	const { data: jwt, error: jwtError } = useSWR('/auth/jwt');
	const { data: brief, error: briefError } = useSWR(jwt ? `/api/v1/briefs/content/${id}` : null);
	const { data: plan, error: planError } = useSWR(jwt ? `/api/v1/plans/${id}` : null);

	// Destructure
	const { owner } = { ...brief };
	const { data: ownerObject } = useSWR(jwt ? `/api/v1/users/email/${owner}` : null);

	// Effects
	useEffect(() => {
		if (brief) {
			setFormState(brief);
			if (brief?.submitted?.koc_survey) {
				navigate('/expired', { state: { error: { message: 'This survey has already been submitted! A website onboarding specialist will be in touch with next steps.' } } });
			}
		}
	}, [brief, navigate]);

	useEffect(() => {
		if (jwt && brief && plan) {
			setIsLoading(false);
		}
	}, [jwt, brief, plan]);

	useEffect(() => {
		if (jwtError || briefError || planError) {
			navigate('/error', { state: { from_public_page: true } });
		}
	}, [jwtError, briefError, planError, navigate]);

	// Handlers
	const handleNextStep = () => {
		setCurrentStep(currentStep + 1);
		window.scrollTo(0, 0);
	};

	// Previous template step
	const handlePrevStep = () => {
		setCurrentStep(currentStep - 1);
		window.scrollTo(0, 0);
	};

	// Form submit
	const handleSubmit = async values => {
		setIsLoading(true);
		const body = { ...values, submitted: { ...values.submitted, koc_survey: new Date() } };
		// POST to API
		try {
			const data = await fetcher(`/api/v1/briefs/survey/${id}`, { method: 'PUT', body: JSON.stringify(body) });
			setFormState(data);
			setCurrentStep(stepComponents.length - 1);
			setIsLoading(false);
		} catch (error) {
			navigate('/error', { state: { from_public_page: true } });
		}
	};

	// Step components
	const stepComponents = [
		{
			component: Welcome,
			order: 0
		},
		{
			component: DesignOptions,
			order: 1
		},
		{
			component: WebsiteInfo,
			order: 2
		},
		{
			component: Branding,
			order: 3
		},
		{
			component: DomainInfo,
			order: 4
		},
		{
			component: Accessibility,
			order: 5
		},
		{
			component: AdditionalInfo,
			order: 6
		},
		{
			component: Complete,
			order: 7
		}
	];

	// Current progress
	const currentProgress = Math.round((currentStep / (stepComponents.length - 1)) * 100);

	// Render
	return !isLoading ? (
		<div className='p-3 p-md-5'>
			<Formik enableReinitialize={true} initialValues={formState} onSubmit={handleSubmit}>
				{() => (
					<Component>
						<Helmet>
							<title>Website Kickoff Survey | AppFolio Sites</title>
						</Helmet>
						<div className='d-flex align-items-start'>
							<Logo src={logo} width='75' alt='AppFolio Logo' className='mb-3 mb-lg-0' />
							<PageHeader heading='Website Kickoff Survey' subheading='AppFolio Sites' />
						</div>
						<Progress now={currentProgress} label={`${currentProgress}% complete`} />
						<Form>
							{stepComponents.map(step => {
								return currentStep === step.order && <step.component key={step.order} plan={plan} handlePrevStep={handlePrevStep} handleNextStep={handleNextStep} owner={ownerObject} />;
							})}
						</Form>
					</Component>
				)}
			</Formik>
		</div>
	) : (
		<LoadingOverlay />
	);
};

export default WritingSurvey;
