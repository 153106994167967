import Helmet from 'react-helmet';
import { useLogin } from 'utils/UserContext';
import { useMessage } from 'utils/MessageContext';
import { Formik } from 'formik';
import NewForm from './NewForm';
import dayjs from 'dayjs';
import { calculateStageDates } from 'utils/Stages';
import { fetcher } from 'utils/Fetch';
import { useNavigate } from 'react-router-dom';
import PageHeader from 'components/shared/PageHeader';
import ObjectID from 'bson-objectid';

export default function New() {
	// Hooks
	const { user } = useLogin();
	const { setMessage } = useMessage();
	const navigate = useNavigate();

	// Initial Values
	const initialValues = {
		appfolio_contact: user.email,
		assets: [
			{
				id: ObjectID().toHexString(),
				name: 'Logo',
				description: 'Preferred formats are .png, .ai, .eps, or .pdf',
				completed: false
			},
			{
				id: ObjectID().toHexString(),
				name: 'Property Photos',
				description: 'Please send high-resolution images of your property (2000x1000 pixels or larger).',
				completed: false
			},
			{
				id: ObjectID().toHexString(),
				name: 'Floor Plans',
				description: 'Preferred formats are .png or .pdf',
				completed: false
			},
			{
				id: ObjectID().toHexString(),
				name: 'Domain',
				description: 'Please search for available domains [here](https://domains.appfoliowebsites.com/search) and send the domain you would like to use. (Tip: When searching, include the ".com" following the domain, but omit the "www.")',
				completed: false
			},
			{
				id: ObjectID().toHexString(),
				name: 'Domain Login',
				description: 'Please provide me with the login credentials to your current domain hosting provider.',
				completed: false
			},
			{
				id: ObjectID().toHexString(),
				name: 'Domain Transfer Code',
				description: 'Please request a transfer authorization code and send this to me directly. (Note: Domain transfers can take 7-10 days so please request this as soon as possible.)',
				completed: false
			},
			{
				id: ObjectID().toHexString(),
				name: 'GoDaddy Delegate Access',
				description: 'Please follow [this help article](https://www.godaddy.com/help/invite-a-delegate-to-access-my-godaddy-account-12376) to provide me with delegate access to your domain.',
				completed: false
			},
			{
				id: ObjectID().toHexString(),
				name: 'Stock Photos',
				description: 'Please explore Adobe stock options [here](https://stock.adobe.com/) and send me the urls of the 5-7 images you would like to use. (Note: AppFolio does not offer stock ,videos, but feel free to purchase these seperately for use on the site)',
				completed: false
			},
			{
				id: ObjectID().toHexString(),
				name: 'IDX iFrame',
				description: 'Please reach out to your MLS provider and request an "IDX iFrame" to display real estate listings on your site.',
				completed: false
			},
			{
				id: ObjectID().toHexString(),
				name: 'About Us Paragraph',
				description: 'Please write a 3-5 sentence description about your company, detailing your mission and goals.',
				completed: false
			},
			{
				id: ObjectID().toHexString(),
				name: 'Team Bios',
				description: 'Please provide images, names, titles, contact information and bios for each team member.',
				completed: false
			},
			{
				id: ObjectID().toHexString(),
				name: 'Property Group',
				description: 'Please create a property group within your AppFolio database following [this help article](https://help.appfolio.com/s/article/Create-and-Edit-Property-Groups).',
				completed: false
			}
		],
		assets_url: '',
		company_contact: '',
		company_name: '',
		linked_plan_id: '',
		number_of_sites: 'Single Site',
		on_hold: false,
		site_id: '',
		product_tier: 'S1',
		properties: [],
		property_name: '',
		property_details: [],
		purpose: 'Company Site',
		stage: 'Welcome',
		target_dates: {
			start: dayjs().format('YYYY-MM-DD')
		},
		website_url: ''
	};

	// Handlers
	const handleSubmit = async values => {
		// Destructure values
		const { target_dates, ...rest } = values;

		// Calculate dates
		const new_target_dates = calculateStageDates(values.target_dates.start);

		// POST to API
		try {
			const planOwner = await fetcher(`/api/v1/users/${user._id}`);
			if (!planOwner) throw new Error('User not found.');

			// Form body
			const body = {
				...rest,
				calendly: {
					is_enabled: true,
					booking_url: planOwner?.calendly?.booking_url || ''
				},
				target_dates: new_target_dates,
				actual_dates: {
					start: new_target_dates.start,
					collect: null,
					build: null,
					review: null,
					live: null
				}
			};
			const plan = await fetcher('/api/v1/plans/new', { method: 'POST', body: JSON.stringify(body) });
			if (!plan) throw new Error('Error creating project plan.');
			navigate(`/plan/${plan.id}`);
		} catch ({ message }) {
			setMessage({ variant: 'danger', text: message });
		}
	};

	return (
		<>
			<Helmet>
				<title>New Project Plan | Project Plan Tool</title>
			</Helmet>
			<PageHeader heading='New Project Plan' subheading='Create a new project plan.' />
			<Formik initialValues={initialValues} onSubmit={values => handleSubmit(values)}>
				<NewForm />
			</Formik>
		</>
	);
}
