import { tidyDateTime } from 'utils/Tidy';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

const Component = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 2rem;

	@media (max-width: 767px) {
		grid-template-columns: 1fr;
		gap: 1rem;
	}
`;

const Title = styled.h4`
	margin: 0 !important;
`;

const SmallTitle = styled.h5`
	margin: 0 !important;
`;

const Panel = styled.div`
	background: white;
	padding: 1.5rem;
	border-radius: 0.5rem;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
	display: grid;
	gap: 1rem;
	align-items: start;
`;

const LargePanel = styled(Panel)`
	grid-column: 1 / 3;

	@media (max-width: 767px) {
		grid-template-columns: 1fr;
		grid-column: 1 / 2;
	}
`;

const CustomButton = styled(Button)`
	&.btn:not(.default-style) {
		height: 2.5rem;
		display: flex !important;
		align-items: center;
		justify-content: center;
		gap: 0.5rem;
	}
`;

const Complete = ({ formResponse, uploadUrl }) => {
	// Props
	const { property_name } = formResponse;

	// Handlers
	const handleRefreshPage = () => {
		window.location.reload(true);
	};

	return (
		<Component>
			<LargePanel>
				<Title>Thank you!</Title>
				<div>
					Your Property Details Survey response for <strong>{property_name}</strong> was submitted successfully on <strong>{tidyDateTime(new Date())}.</strong>
				</div>
			</LargePanel>
			{uploadUrl && (
				<Panel>
					<SmallTitle>Upload Assets</SmallTitle>
					<div>
						If you would like to send us images or other media about {property_name}, you can <strong>upload them below</strong>. Be sure to title the folder with the name of this property, so we know where everything belongs.
					</div>
					<CustomButton href={uploadUrl} target='_blank' rel='noopener noreferrer'>
						Upload Assets <i className='fas fa-upload'></i>
					</CustomButton>
				</Panel>
			)}

			<Panel>
				<SmallTitle>Additional Properties</SmallTitle>
				<div>
					If that is the only property you wish to add, you can now close this window. Otherwise, <strong>add another property below.</strong>
				</div>
				<CustomButton variant='secondary' onClick={() => handleRefreshPage()}>
					Add another property <i className='fas fa-plus'></i>
				</CustomButton>
			</Panel>
		</Component>
	);
};

export default Complete;
