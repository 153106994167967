import { useState } from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';

const Important = styled.div`
	background: white;
	padding: 1rem;
	font-size: 0.9rem;
	box-shadow: inset 3px 0 0 0 var(--bs-warning), 0 0.125rem 0.25rem rgb(0 0 0 / 8%);

	h5 {
		color: var(--bs-warning);
	}

	p {
		margin: 0;
	}
`;

const FinalDetails = ({ handleChange, formResponse }) => {
	const [showTerms, setShowTerms] = useState(true);
	const [showPropertyGroup, setShowPropertyGroup] = useState(true);
	const [showSocialMedia, setShowSocialMedia] = useState(true);

	return (
		<>
			<h4 className='text-2xl font-semibold'>Final Details</h4>
			<Form.Group className='mb-3'>
				<Form.Label>Do you have specific terms for this property that you want displayed on the website?</Form.Label>
				<Form.Check type='radio' id='show-terms' label='Yes, display the following terms on my website.' checked={showTerms} onChange={() => setShowTerms(true)} />
				<Form.Check type='radio' id='hide-terms' label='None for now, maybe later.' checked={!showTerms} onChange={() => setShowTerms(false)} />
				{showTerms && (
					<div className='mt-3'>
						<Form.Group className='mb-3'>
							<Form.Label>Security Deposit</Form.Label>
							<Form.Control type='text' onChange={e => handleChange(e)} name='security_deposit' value={formResponse.security_deposit} />
						</Form.Group>
						<Form.Group className='mb-3'>
							<Form.Label>Application Fee</Form.Label>
							<Form.Control type='text' onChange={e => handleChange(e)} name='application_fee' value={formResponse.application_fee} />
						</Form.Group>
						<Form.Group className='mb-3'>
							<Form.Label>Pet Policy</Form.Label>
							<Form.Control type='text' onChange={e => handleChange(e)} name='pet_policy' value={formResponse.pet_policy} />
						</Form.Group>
						<Form.Group className='mb-3'>
							<Form.Label>Other Fees</Form.Label>
							<Form.Control type='text' onChange={e => handleChange(e)} name='other_fees' value={formResponse.other_fees} />
						</Form.Group>
					</div>
				)}
			</Form.Group>
			<Form.Group className='mt-4'>
				<Form.Label>Do you have a property group set up in AppFolio Property Manager for this property?</Form.Label>
				<Form.Check type='radio' id='show-property-group' label='Yes, the property group name is below.' checked={showPropertyGroup} onChange={() => setShowPropertyGroup(true)} />
				<Form.Check type='radio' id='hide-property-group' label='Not yet, help me set this up.' checked={!showPropertyGroup} onChange={() => setShowPropertyGroup(false)} />
				<div className='mt-3'>
					{showPropertyGroup ? (
						<Form.Control type='text' onChange={e => handleChange(e)} name='property_group' value={formResponse.property_group} />
					) : (
						<Important>
							<h5 className='mb-2 text-lg font-semibold'>How to create a property group</h5>
							<p>
								You can create a new group in AppFolio Property Manager by going to <strong>Settings &gt; New Property Group</strong>. Please let us know what you have titled the property group and we’ll put it in your website for your vacancies feed.
							</p>
						</Important>
					)}
				</div>
			</Form.Group>
			<Form.Group className='mt-4'>
				<Form.Label>Do you have any social media accounts you would like to add to the website?</Form.Label>
				<Form.Check type='radio' id='show-social-media' label='Yes, links to my social media accounts are listed below.' checked={showSocialMedia} onChange={() => setShowSocialMedia(true)} />
				<Form.Check type='radio' id='hide-social-media' label='None for now, maybe later.' checked={!showSocialMedia} onChange={() => setShowSocialMedia(false)} />
				<div className='mt-3'>{showSocialMedia && <Form.Control as='textarea' rows={5} className='allow-resize' onChange={e => handleChange(e)} name='social_media' value={formResponse.social_media} />}</div>
			</Form.Group>
		</>
	);
};

export default FinalDetails;
