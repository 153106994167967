import { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import PageHeader from 'components/shared/PageHeader';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import { Button, Accordion, useAccordionButton } from 'react-bootstrap';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useMessage } from 'utils/MessageContext';
import Confirmation from 'components/shared/Confirmation';
import styled from 'styled-components';
import useSWR, { mutate } from 'swr';
import { BarButton, ButtonBar, StickyButtons } from 'components/shared/ButtonBar';
import { tidyDateTime } from 'utils/Tidy';
import { fetcher } from 'utils/Fetch';
import { Formik, Form } from 'formik';
import { Pinned } from './Pinned';

// Config
import BriefInitialValues from 'config/BriefInitialValues.json';
import BriefSections from 'config/BriefSections.json';

// Sections
import ProjectDetails from './Sections/1-ProjectDetails';
import DetailsToDisplay from './Sections/2-DetailsToDisplay';
import DomainInfo from './Sections/3-DomainInfo';
import Notes from './Sections/4-Notes';
import Accessibility from './Sections/7-Accessibility';
import Design from './Sections/8-Design';
import Features from './Sections/9-Features';
import FollowUp from './Sections/10-FollowUp';
import Assets from './Sections/11-Assets';
import Timeline from './Sections/12-Timeline';

// Thumbnails
import ProjectDetailsThumb from 'img/thumbs/ProjectDetails.png';
import DetailsToDisplayThumb from 'img/thumbs/DetailsToDisplay.png';
import AccessibilityThumb from 'img/thumbs/Accessibility.png';
import DesignThumb from 'img/thumbs/Design.png';
import DomainInfoThumb from 'img/thumbs/DomainInfo.png';
import FeaturesThumb from 'img/thumbs/Features.png';
import FollowUpThumb from 'img/thumbs/FollowUp.png';
import AssetsThumb from 'img/thumbs/Assets.png';
import NotesThumb from 'img/thumbs/Notes.png';

const Wrapper = styled.div`
	max-width: 1280px;
	margin: 0 auto;
	display: grid;
	gap: 1.5rem;

	.row {
		margin-bottom: 1rem;
	}

	h4 {
		margin: 1rem 0;
	}

	.custom-control {
		margin-bottom: 0.5rem;
	}
`;

const CustomAccordion = styled(Accordion)`
	display: grid;
	gap: 1rem;
`;

const Section = styled.section`
	padding: 1.5rem;
	transition: 0.2s;
	border-radius: 0.5rem;

	&.active,
	&:hover,
	&:focus-within {
		box-shadow: 0 0 0 2px var(--bs-light);
		background: #fff6;
	}
`;

const ToggleComponent = styled.button`
	all: unset;
	width: 100%;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 0.5rem;

	@media (max-width: 576px) {
		flex-direction: column;
		gap: 1rem;
	}
`;

const Collapse = styled.div`
	margin: 1rem 0 0;
`;

const Fields = styled.div`
	margin-top: 0.5rem;
`;

const Thumb = styled.img`
	width: 250px;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
`;

const Panel = styled.div`
	background: white;
	padding: 1.5rem;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
	border-radius: 0.5rem;
`;

const CustomButtonBar = styled(ButtonBar)`
	margin: 0;
`;

const ButtonWrapper = styled.div`
	display: flex;
	gap: 1rem;

	@media (max-width: 576px) {
		flex-direction: column;
	}
`;

const Toggle = ({ eventKey, label, activeSection, setActiveSection, ...props }) => {
	// Hooks
	const handleClick = useAccordionButton(eventKey, () => (label !== activeSection ? setActiveSection(label) : setActiveSection(null)));

	return <ToggleComponent onClick={handleClick} type='button' {...props} />;
};

const CreativeBrief = () => {
	// Hooks
	const { id } = useParams();
	const navigate = useNavigate();
	const { setMessage } = useMessage();
	const { data: plan } = useSWR(`/api/v1/plans/${id}`);
	const { data: brief } = useSWR(`/api/v1/briefs/${id}`);

	// Destructure data
	const { company_contact, appfolio_contact } = { ...plan };
	const { submitted } = { ...brief };
	const { koc_survey } = { ...submitted };
	const { data: owner } = useSWR(appfolio_contact ? `/api/v1/users/email/${appfolio_contact}` : null);

	// State
	const [isLoading, setIsLoading] = useState(true);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [activeSection, setActiveSection] = useState('Project Details');
	const [formState, setFormState] = useState(BriefInitialValues);
	const [briefExists, setBriefExists] = useState(false);

	// Effects
	useEffect(() => {
		if (plan && brief) {
			setIsLoading(false);
		}
	}, [plan, brief]);

	useEffect(() => {
		if (brief && Object.keys(brief).length > 0) {
			setBriefExists(true);
			setFormState(brief);
		}
	}, [brief]);

	useEffect(() => {
		if (!isLoading && !briefExists) {
			setMessage({ variant: 'warning', text: 'Save this Creative Brief to generate a Kickoff Survey Link' });
		}
	}, [isLoading, briefExists, setMessage]);

	// Sections
	const InitialSections = [
		{
			label: 'Project Details',
			component: ProjectDetails,
			thumb: ProjectDetailsThumb
		},
		{
			label: 'Details to Display',
			component: DetailsToDisplay,
			thumb: DetailsToDisplayThumb
		},
		{
			label: 'Domain Info',
			component: DomainInfo,
			thumb: DomainInfoThumb
		},
		{
			label: 'Notes',
			component: Notes,
			thumb: NotesThumb
		},
		{
			label: 'Accessibility',
			component: Accessibility,
			thumb: AccessibilityThumb
		},
		{
			label: 'Design',
			component: Design,
			thumb: DesignThumb
		},
		{
			label: 'Features',
			component: Features,
			thumb: FeaturesThumb
		},
		{
			label: 'Follow Up',
			component: FollowUp,
			thumb: FollowUpThumb
		},
		{
			label: 'Assets',
			component: Assets,
			thumb: AssetsThumb
		},
		{
			label: 'Timeline',
			component: Timeline,
			thumb: AssetsThumb
		}
	];
	const RenderSections = InitialSections.map(value => ({ ...value, fields: BriefSections.find(section => value.label === section.label).fields }));

	// Handlers
	const handleSubmit = async values => {
		setIsLoading(true);
		const body = { ...values, owner: appfolio_contact };
		try {
			await fetcher(`/api/v1/briefs/${id}`, { method: 'PUT', body: JSON.stringify(body) });
			await mutate(`/api/v1/briefs/${id}`, null, { revalidate: true });
			setMessage({
				variant: 'success',
				text: 'Successfully saved this creative brief.'
			});
		} catch (error) {
			setMessage({
				variant: 'danger',
				text: 'There was an error saving this creative brief.'
			});
		}
		setIsLoading(false);
	};

	const handleDelete = async () => {
		setShowConfirmation(false);
		try {
			await fetcher(`/api/v1/briefs/${id}`, { method: 'DELETE' });
			await mutate(`/api/v1/briefs/${id}`, null, { revalidate: true });
			navigate(`/plan/${id}`);
		} catch (error) {
			setMessage({
				variant: 'danger',
				text: 'There was an error deleting this creative brief.'
			});
		}
	};

	return !isLoading ? (
		<Formik enableReinitialize={true} initialValues={formState} onSubmit={handleSubmit}>
			{({ values }) => (
				<Wrapper>
					<Helmet>
						<title>Creative Brief | Project Plan Tool</title>
					</Helmet>
					<Confirmation show={showConfirmation} setShow={setShowConfirmation} message={`delete this creative brief. ${koc_survey && 'The customer will need to resubmit their kickoff survey if you do this.'}`} confirmFunction={handleDelete} buttonType={'danger'} />
					<PageHeader heading={'Creative Brief'} subheading={'Generate a creative brief from the kick-off call survey responses.'} />
					<ButtonWrapper>
						<CustomButtonBar>
							<BarButton to={`/plan/${id}`} href={`/plan/${id}`}>
								<i className='fas fa-angle-left'></i> Back to Project Plan
							</BarButton>
						</CustomButtonBar>
						{briefExists && (
							<CustomButtonBar>
								<BarButton to={`/brief/${id}/survey`} target='_blank' rel='noopener noreferrer'>
									{koc_survey && <i className='text-danger fas fa-exclamation-circle'></i>} Kickoff Survey <i className='fas fa-external-link-alt'></i>
								</BarButton>
								<BarButton to={`/brief/${id}/view`} target='_blank' rel='noopener noreferrer'>
									Creative Brief View <i className='fas fa-external-link-alt'></i>
								</BarButton>
							</CustomButtonBar>
						)}
					</ButtonWrapper>
					{koc_survey && (
						<Panel>
							<i className='fas fa-check-circle text-success me-2'></i> Kickoff survey submitted by{' '}
							<a href={`mailto:${company_contact}`} className='text-sky-600'>
								{company_contact}
							</a>{' '}
							on <strong>{tidyDateTime(koc_survey)}</strong>
						</Panel>
					)}
					<Pinned setActiveSection={setActiveSection} />
					<Form>
						<CustomAccordion activeKey={activeSection}>
							{RenderSections.map(section => (
								<Section key={section.label} className={section.label === activeSection ? 'active' : ''}>
									<Toggle eventKey={section.label} label={section.label} activeSection={activeSection} setActiveSection={setActiveSection}>
										<div>
											<h3 className='flex items-center gap-2 mb-2 text-2xl font-bold'>
												{section.label} <i className='fas fa-angle-down text-secondary'></i>
											</h3>
											{section.fields.length > 0 && (
												<>
													<Fields>
														<strong>{section.fields.length}</strong> field{section.fields.length !== 1 && 's'} to complete <i className='fas fa-check text-secondary ms-1'></i>
													</Fields>
													<Fields>
														<strong>{section.fields.filter(field => values.pinned.some(pin => pin.name === field)).length}</strong> field{section.fields.filter(field => values.pinned.some(pin => pin.name === field)).length !== 1 && 's'} pinned <i className='fas fa-thumbtack ms-1 text-secondary'></i>
													</Fields>
												</>
											)}
										</div>
										{section.thumb && <Thumb src={section.thumb} alt={section.label} />}
									</Toggle>
									<Accordion.Collapse eventKey={section.label}>
										<Collapse>
											<section.component section={section.label} pinned={values.pinned} id={id} plan={plan} owner={owner} />
										</Collapse>
									</Accordion.Collapse>
								</Section>
							))}
						</CustomAccordion>
						<StickyButtons>
							<Button type='submit' variant='primary'>
								Save <i className='fas fa-save'></i>
							</Button>
							<Button as={Link} variant='secondary' to={`/plan/${id}`}>
								Cancel <i className='fas fa-times-circle'></i>
							</Button>
							{briefExists && (
								<Button type='button' variant='danger' onClick={() => setShowConfirmation(true)}>
									Delete Brief <i className='fas fa-trash-alt'></i>
								</Button>
							)}
						</StickyButtons>
					</Form>
				</Wrapper>
			)}
		</Formik>
	) : (
		<LoadingOverlay />
	);
};

export default CreativeBrief;
