import { Survey } from 'components/shared/Surveys';
import { FormLabel, FormControl } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';

const Commercial = ({ register }) => {
	return (
		<Survey.Container>
			<h4 className='font-semibold text-2xl'>Commercial</h4>
			<div>
				<FormLabel>
					What kind of properties make up your commercial portfolio? <strong className='text-danger'>*</strong>
				</FormLabel>
				<FormControl as={TextareaAutosize} minRows={5} {...register('Commercial.What kind of properties make up your commercial portfolio?', { required: true })} />
			</div>
			<div>
				<FormLabel>
					What additional services do you provide to your commercial clients? <strong className='text-danger'>*</strong>
				</FormLabel>
				<FormControl as={TextareaAutosize} minRows={5} {...register('Commercial.What additional services do you provide to your commercial clients?', { required: true })} />
			</div>
		</Survey.Container>
	);
};

export default Commercial;
