import { Form, Col, Row } from 'react-bootstrap';

const DateSelector = ({ state, setState }) => {
	// Handlers
	const handleChange = e => {
		const { name, value } = e.target;
		setState(prev => ({ ...prev, [name]: value }));
	};

	return (
		<Form className='mb-5'>
			<Row>
				<Col md={6}>
					<Form.Label>Start Date</Form.Label>
					<Form.Control type='date' value={state.start} name='start' onChange={e => handleChange(e)} required />
				</Col>
				<Col md={6}>
					<Form.Label>End Date</Form.Label>
					<Form.Control type='date' value={state.end} name='end' onChange={e => handleChange(e)} required />
				</Col>
			</Row>
		</Form>
	);
};

export default DateSelector;
