import { Link } from 'react-router-dom';
import styled from 'styled-components';

const BarComponent = styled.div`
	margin: 1rem 0;
	display: inline-flex;
	flex-wrap: wrap;
	justify-content: center;
	background: white;
	border-radius: 0.5rem;
	overflow: hidden;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);

	> * {
		border-right: 1px solid var(--lightest)

		&:last-of-type {
			border: 0;
		}
	}

	@media (max-width: 576px) {
		display: flex;
		flex-direction: column;
		margin: 0.5rem;

		> * {
			border-right: 0;
			border-bottom: 1px solid var(--lightest)
		}
	}
`;

const ButtonComponent = styled(Link)`
	all: unset;
	padding: 0.75rem 1.5rem;
	transition: 0.1s;
	text-decoration: none !important;
	display: flex;
	align-items: center;
	gap: 0.75rem;
	line-height: 1;
	white-space: nowrap;
	overflow: hidden;
	color: var(--bs-primary);
	cursor: pointer;
	border-right: 1px solid #eee;

	&:last-of-type {
		border: 0;
	}

	&:hover,
	&:focus-visible {
		background: var(--bs-primary);
		color: white;
	}

	&:disabled {
		background: var(--bs-light);
		color: #0006;
		cursor: not-allowed;
	}

	@media (max-width: 576px) {
		justify-content: center;
	}
`;

const Sticky = styled.div`
	position: sticky;
	bottom: 0;
	left: 0;
	z-index: 1;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
	padding: 1.5rem;

	@media (max-width: 600px) {
		position: static;
		gap: 0;
		padding: 0;
	}
`;

export const ButtonBar = ({ children, ...props }) => {
	return <BarComponent {...props}>{children}</BarComponent>;
};

export const BarButton = ({ children, ...props }) => {
	return <ButtonComponent {...props}>{children}</ButtonComponent>;
};

export const StickyButtons = ({ children, ...props }) => {
	return <Sticky {...props}>{children}</Sticky>;
};
