import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

// Render functions
export const renderValue = text => {
	if (!Array.isArray(text)) {
		return text.split('\n').map((paragraph, i) => <div key={i}>{paragraph}</div>);
	} else {
		return (
			<ul className='list-disc list-inside'>
				{text.map(item => (
					<li key={item}>{item}</li>
				))}
			</ul>
		);
	}
};

export const cn = (...inputs) => twMerge(clsx(inputs));
