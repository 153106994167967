import dayjs from 'dayjs';
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

// Tidy URLs
export function tidyUrl(url) {
	try {
		const { hostname, pathname } = new URL(url);
		return `${hostname}${pathname !== '/' ? pathname : ''}`;
	} catch (error) {
		return url;
	}
}

// Tidy dates
export function tidyDate(date) {
	return date && dayjs(date).format('MM/DD/YYYY');
}

export function tidyDateWords(date) {
	return date && dayjs(date).format('D MMMM YYYY');
}

export function tidyDatePicker(date) {
	return date && dayjs(date).format('YYYY-MM-DD');
}

export function tidyUTCDate(date) {
	return date && dayjs.utc(date).format('MM/DD/YYYY');
}

export function tidyTime(date) {
	return date && dayjs(date).format('h:mm A');
}

export function tidyDateTime(date) {
	return date && dayjs(date).format('MMMM D YYYY @ h:mm A');
}

// Tidy text
export function tidyCapitalize(text) {
	return text
		.toLowerCase()
		.split(/ |_/)
		.map(word => word[0]?.toUpperCase() + word.slice(1))
		.join(' ');
}

export function tidyUnderscores(text) {
	return text.toLowerCase()[0]?.toUpperCase() + text.slice(1).split('_').join(' ');
}

export function addUnderscores(text) {
	return text
		.toLowerCase()
		.replaceAll(/[^a-zA-Z0-9]/g, ' ')
		.replaceAll(/\s\s+/g, ' ')
		.split(' ')
		.join('_');
}

export function addDashes(text) {
	return text
		.toLowerCase()
		.replaceAll(/[^a-zA-Z0-9]/g, ' ')
		.replaceAll(/\s\s+/g, ' ')
		.split(' ')
		.join('-');
}

export function tidyDashes(text) {
	const withSpaces = text.toLowerCase().split('-').join(' ');
	return withSpaces[0].toUpperCase() + withSpaces.slice(1);
}

export function tidyParagraphs(text) {
	return text.replaceAll('\n', '<br />');
}

// Tidy email addresses
export function tidyUserEmail(email) {
	return email
		.split('@')[0]
		.replaceAll('.', ' ')
		.split(' ')
		.map(word => word[0].toUpperCase() + word.slice(1))
		.join(' ');
}

// Tidy database strings
export function tidyDatabase(url) {
	const urlObject = new URL(url);
	if (urlObject.hostname === 'api.appfoliotest.com') {
		const db = urlObject.pathname.replaceAll('/api/v1/listings/', '');
		return `${db}.appfolio.com`;
	} else {
		return urlObject.hostname;
	}
}

export function tidyPropertyGroup(url) {
	return new URL(url).searchParams.get('property_lists[]');
}

// Tidy file sizes
export function tidyFileSize(bytes) {
	const kilo = 1024;
	const sizes = ['Bytes', 'KB', 'MB'];
	const i = Math.floor(Math.log(bytes) / Math.log(kilo));
	return `${parseFloat((bytes / Math.pow(kilo, i)).toFixed(2))} ${sizes[i]}`;
}

// Tidy form responses
export function tidyFormResponse(formValues) {
	const output = Object.entries(formValues).map(([question, response]) => ({ question, response }));
	return output;
}

export function tidyNestedFormResponse(formValues) {
	const output = Object.entries(formValues)
		.map(([section, data]) => ({
			section,
			data: Object.entries(data).map(([question, response]) => ({ question, response }))
		}))
		.filter(({ data }) => data.some(({ response }) => response.length > 0));
	return output;
}
