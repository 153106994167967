import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
	overflow: auto;
	border-radius: 0.5rem;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
	display: grid;
`;

const Header = styled.div`
	display: grid;
	grid-template-columns: ${({ $columns }) => $columns};
	background: var(--darkest);
	color: white;
	font-weight: bold;
`;

const Body = styled.div`
	background: white;
`;

const Row = styled(Link)`
	display: grid;
	grid-template-columns: ${({ $columns }) => $columns};
	align-items: center;
	border-bottom: 1px solid var(--lightest);
	color: inherit;
	text-decoration: none !important;
	transition: 0.1s;

	&:last-of-type {
		border: 0;
	}

	&:hover,
	&:focus-visible {
		box-shadow: rgba(0, 0, 0, 0.18) 0px 0px 3rem;
	}
`;

const Cell = styled.div`
	padding: 0.75rem 1rem;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	& > * {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
`;

const NoData = styled.div`
	text-align: center;
	padding: 1rem;
`;

export const Table = { Container, Header, Body, Row, Cell, NoData };
