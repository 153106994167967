import { Survey } from 'components/shared/Surveys';
import { FormLabel, FormControl } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';

const TenantServices = ({ register }) => {
	return (
		<Survey.Container>
			<h4 className='font-semibold text-2xl'>Tenant Services</h4>
			<div>
				<FormLabel>
					Which tenant services do you offer and what are some of the benefits they provide? <strong className='text-danger'>*</strong>
				</FormLabel>
				<FormControl as={TextareaAutosize} minRows={5} {...register('Tenant Services.Which tenant services do you offer and what are some of the benefits they provide?', { required: true })} />
			</div>
			<div>
				<FormLabel>
					Why do tenants enjoy renting from your company? <strong className='text-danger'>*</strong>
				</FormLabel>
				<FormControl as={TextareaAutosize} minRows={5} {...register('Tenant Services.Why do tenants enjoy renting from your company?', { required: true })} />
			</div>
		</Survey.Container>
	);
};

export default TenantServices;
