import { ButtonBar, BarButton } from 'components/shared/ButtonBar';
import PageHeader from 'components/shared/PageHeader';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import useSWR from 'swr';
import { useEffect } from 'react';
import { fetcher } from 'utils/Fetch';
import Surveys from 'components/SiteGenie/Details/Surveys';
import Prompt from './Prompt';
import Content from './Content';
import JobStatus from './JobStatus';
import { Panel } from 'components/shared/Panel';

const Component = styled.div`
	display: grid;
	gap: 2rem;
`;

const ButtonWrapper = styled.div`
	display: flex;
	gap: 1rem;

	@media (max-width: 576px) {
		flex-direction: column;
		gap: 0;
	}
`;

const Details = () => {
	// Hooks
	const { id } = useParams();

	// Site Genie Doc
	const { data: siteGenieData, mutate } = useSWR(`/api/v1/site-genie/${id}`);
	const { initial_survey, expanded_survey, job_id, content } = { ...siteGenieData };

	// Job
	const { data: jobData } = useSWR(job_id ? `/api/v1/site-genie/jobs/${job_id}` : null, { refreshInterval: 5000 });

	// Plan
	const { data: planData } = useSWR(`/api/v1/plans/${id}`);
	const { purpose: site_type } = { ...planData };

	// Effects
	useEffect(() => {
		const mutateDoc = async () => {
			if (jobData?.status === 'Completed') {
				await fetcher(`/api/v1/site-genie/jobs/${job_id}`, { method: 'DELETE' });
				await mutate();
			}
		};
		mutateDoc();
	}, [jobData?.status, job_id, mutate]);

	return (
		<>
			<Helmet>
				<title>Site Genie Details | Project Plan Tool</title>
			</Helmet>
			<PageHeader heading='Site Genie Details' subheading='View Site Genie survey submissions and prompt AI-generated content responses.' />
			<Component>
				<ButtonWrapper>
					<ButtonBar>
						<BarButton to={`/plan/${id}`}>
							<i className='fas fa-angle-left'></i> Back to Project Plan
						</BarButton>
					</ButtonBar>
					<ButtonBar>
						<BarButton to={`/site-genie/${id}/survey/initial`} target='_blank' rel='noopener noreferrer'>
							Initial Survey <i className={`fas ${!initial_survey?.submitted ? 'fa-external-link-alt' : 'fa-exclamation-circle text-danger'}`}></i>
						</BarButton>
						{initial_survey && (
							<BarButton to={`/site-genie/${id}/survey/expanded`} target='_blank' rel='noopener noreferrer'>
								Expanded Survey <i className={`fas ${!expanded_survey?.submitted ? 'fa-external-link-alt' : 'fa-exclamation-circle text-danger'}`}></i>
							</BarButton>
						)}
					</ButtonBar>
				</ButtonWrapper>
				{jobData && <JobStatus job={jobData} />}
				{!siteGenieData ? (
					<Panel>
						<div>
							<i className='fas fa-exclamation-circle text-warning me-2'></i>No Site Genie data to display. Start by sending the customer an <strong>Initial Survey</strong> using the link above.
						</div>
					</Panel>
				) : (
					<>
						<Surveys siteGenieData={siteGenieData} mutate={mutate} />
						{initial_survey && <Prompt siteGenieData={siteGenieData} site_type={site_type} />}
						{content?.length > 0 && <Content content={content} mutate={mutate} />}
					</>
				)}
			</Component>
		</>
	);
};

export default Details;
