import { Field } from 'formik';
import { Form, Row, Col } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';
import EditableTimeline from 'components/shared/EditableTimeline/EditableTimeline';

const Timeline = ({ plan }) => {
	return (
		<>
			<EditableTimeline plan={plan} />
			<Row>
				<Col>
					<Form.Group className='mb-3'>
						<Form.Label>Timeline Notes</Form.Label>
						<Field as={TextareaAutosize} className='form-control' minRows={5} name='timeline_notes' />
					</Form.Group>
				</Col>
			</Row>
		</>
	);
};

export default Timeline;
