import { useState, useEffect } from 'react';
import { tidyDate, tidyCapitalize } from 'utils/Tidy';
import styled from 'styled-components';

const Icon = styled.div`
	position: absolute;
	top: 0;
	left: -1.45rem;
	width: 2.5rem;
	height: 2.5rem;
	background: ${({ $color }) => `var(--bs-${$color})`};
	color: white;
	border-radius: 50%;

	> i {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;

const Details = styled.div``;

const Breadcrumbs = styled.div`
	border-left: 5px solid;
	border-image-slice: 1;
	position: relative;
	flex: 0 1 4rem;
	border-color: ${({ $color }) => `var(--bs-${$color})`};
`;

const Component = styled.div`
	display: flex;
	justify-content: center;
	height: 10rem;
	position: relative;

	&:last-of-type {
		height: auto;

		${Breadcrumbs} {
			border: 0;

			${Icon} {
				left: -1.1rem;
			}
		}
	}

	&.active {
		&::after {
			content: '';
			height: 0;
			width: 0;
			border-top: 2rem solid transparent;
			border-bottom: 2rem solid transparent;
			border-right: 3rem solid white;
			z-index: 1;
			position: absolute;
			top: 40%;
			right: 0;

			@media (max-width: 991px) {
				display: none;
			}
		}

		${Breadcrumbs} {
			border-image-source: ${({ $color }) => `linear-gradient(to bottom, var(--bs-${$color}), #ddd)`};

			${Icon} {
				transform: scale(1.5);
			}
		}

		${Details} {
			h5 {
				font-weight: bold;
			}
		}
	}

	&.inactive {
		${Breadcrumbs} {
			border-color: #ddd;

			${Icon} {
				background: #ddd;
			}
		}

		${Details} {
			h5 {
				color: #bbb;
			}
		}
	}

	${Details} {
		flex: 0 0 7rem;
		position: relative;
		z-index: 1;

		h5 {
			font-weight: normal;
			margin: 0;
		}
	}
`;

const Date = styled.div`
	color: var(--bs-secondary);
`;

const Goal = ({ stage, currentStage, date, completed, status }) => {
	// State
	const [isActive, setIsActive] = useState(false);
	const [isComplete, setIsComplete] = useState(false);

	// Effects
	useEffect(() => {
		if (tidyCapitalize(stage) === currentStage) {
			setIsActive(true);
		}
		if (completed) {
			setIsComplete(true);
		}
		if (currentStage === 'Live') {
			setIsActive(false);
			setIsComplete(true);
		}
		if (status === 'On Hold') {
			setIsActive(false);
			setIsComplete(false);
		}
	}, [stage, currentStage, completed, status]);

	// Maps
	const statusMap = {
		'On Time': 'success',
		Delayed: 'warning',
		Overdue: 'danger',
		Complete: 'primary',
		'On Hold': 'danger'
	};

	return (
		<Component className={`${isActive ? 'active' : !isComplete ? 'inactive' : ''} ${isComplete ? 'completed' : ''}`} $color={statusMap[status]}>
			<Breadcrumbs $color={statusMap[status]}>
				<Icon $color={statusMap[status]}>{isActive ? <i className='fas fa-angle-double-right'></i> : isComplete && <i className='fas fa-check'></i>}</Icon>
			</Breadcrumbs>
			<Details>
				<h5 className='text-xl font-semibold'>{tidyCapitalize(stage)}</h5>
				<Date>{tidyDate(date)}</Date>
			</Details>
		</Component>
	);
};

export default Goal;
