import { Form, Button, Row, Col } from 'react-bootstrap';
import { ButtonGroup } from '../Components';

const FinalApprover = ({ prevStep, handleChange, formResponse }) => {
	return (
		<>
			<h4 className='font-semibold text-2xl'>Final Approver</h4>
			<Row>
				<Col lg={6}>
					<Form.Group className='mb-3'>
						<Form.Label>
							Name <span className='text-danger fw-bold'>*</span>
						</Form.Label>
						<Form.Control type='input' name='approver_name' defaultValue={formResponse?.approver_name} onChange={e => handleChange(e)} required />
					</Form.Group>
				</Col>
				<Col lg={6}>
					<Form.Group className='mb-3'>
						<Form.Label>
							Email <span className='text-danger fw-bold'>*</span>
						</Form.Label>
						<Form.Control type='email' name='approver_email' defaultValue={formResponse?.approver_email} onChange={e => handleChange(e)} required />
					</Form.Group>
				</Col>
				<Col className='mt-3'>
					<Form.Group className='d-flex'>
						<Form.Check type='checkbox' label='The above representative is empowered and authorized to make decisions about the content and design of the website, including the final go-ahead to set the website live.' value='Yes, I agree.' id='final_approver_agreement' name='final_approver_agreement' defaultChecked={formResponse?.final_approver_agreement === 'Yes, I agree.'} onChange={e => handleChange(e)} required />
						<span className='text-danger fw-bold ms-1'>*</span>
					</Form.Group>
				</Col>
			</Row>
			<Form.Group className='mb-3'>
				<Form.Label>Additional information</Form.Label>
				<Form.Control as='textarea' rows={5} name='questions_thoughts_or_comments' defaultValue={formResponse?.questions_thoughts_or_comments} onChange={e => handleChange(e)} />
				<Form.Text>Any questions you have, as well as thoughts or comments about the design and content of the website.</Form.Text>
			</Form.Group>
			<ButtonGroup>
				<Button variant='secondary' onClick={() => prevStep()} className='m-1'>
					<i className='fas fa-angle-left'></i> Previous
				</Button>
				<Button type='submit' variant='primary' className='m-1' disabled={formResponse?.approver_name && formResponse?.approver_email && formResponse?.final_approver_agreement === 'Yes, I agree.' ? false : true}>
					Submit <i className='fas fa-paper-plane'></i>
				</Button>
			</ButtonGroup>
			<hr className='text-gray-200 my-2' />
		</>
	);
};

export default FinalApprover;
