import styled from 'styled-components';
import { Section } from './Components';
import StatusPill from 'components/shared/StatusPill';
import { tidyDateTime } from 'utils/Tidy';
import { Panel } from 'components/shared/Panel';

const Component = styled(Panel)`
	gap: 0.25rem;
`;

const JobStatus = ({ job }) => {
	// Props
	const { _id, status, test_job, createdAt } = { ...job };

	return (
		<Section>
			<h4 className='font-semibold text-2xl'>Job Status</h4>
			<Component>
				<div>
					<span className='text-muted'>Job ID:</span> {_id}
				</div>
				<div>
					<span className='text-muted'>Status:</span> <StatusPill status={status} />
				</div>
				<div>
					<span className='text-muted'>Job created:</span> {tidyDateTime(createdAt)}
				</div>
				{test_job && (
					<div>
						<span className='text-muted'>Test Job:</span> <i className='fas fa-check-circle text-primary'></i>
					</div>
				)}
			</Component>
		</Section>
	);
};

export default JobStatus;
