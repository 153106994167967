import { Survey } from 'components/shared/Surveys';
import { FormControl, FormLabel } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';

const Final = ({ register, questions }) => {
	return (
		<Survey.Container>
			<h4 className='font-semibold text-2xl'>Nearly done!</h4>
			<div>
				<FormLabel>{questions[6]}</FormLabel>
				<FormControl as={TextareaAutosize} minRows={5} {...register(questions[6])} />
			</div>
		</Survey.Container>
	);
};

export default Final;
