import styled from 'styled-components';

const Wrapper = styled.div`
	width: 3rem;
	height: 3rem;
	background: var(--bs-primary);
	color: white;
	border-radius: 50%;
	display: grid;
	place-items: center;
	font-size: 1.25rem;
	font-weight: bold;
	margin-right: 1rem;
`;

const StepNumber = ({ number }) => {
	return <Wrapper>{number}</Wrapper>;
};

export default StepNumber;
