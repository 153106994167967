import { useEffect, useRef } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { ButtonGroup } from '../Components';
import StepNumber from '../StepNumber';
import styled from 'styled-components';

const Wrapper = styled.div`
	position: relative;
	top: 29%;

	@media (max-width: 991px) {
		position: static;
	}
`;

const PhotoGallery = ({ prevStep, nextStep, handleChange, formResponse }) => {
	// Scroll to element
	const scrollRef = useRef();
	const scrollToElement = () => scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

	useEffect(() => {
		// Scroll to element if on desktop
		if (window.matchMedia('(min-width: 992px)').matches) {
			scrollToElement();
		}
	}, []);

	return (
		<Wrapper ref={scrollRef}>
			<Row className='align-items-center' noGutters>
				<Col xs='auto'>
					<StepNumber number={4} />
				</Col>
				<Col>
					<h5 className='text-xl font-semibold'>Photo Gallery</h5>
				</Col>
			</Row>
			<p>This is a great place to showcase your Association's amenities.</p>
			<Form.Group className='mb-3'>
				<Form.Label>Do you have high quality photos of your community that you would like to include in a photo gallery?</Form.Label>
				<Form.Check type='radio' name='do_you_have_high_quality_photos_of_your_community' label='Yes' value='Yes' defaultChecked={formResponse?.do_you_have_high_quality_photos_of_your_community === 'Yes'} id='do_you_have_high_quality_photos_of_your_community_yes' onChange={e => handleChange(e)} />
				<Form.Check type='radio' name='do_you_have_high_quality_photos_of_your_community' label='No' value='No' defaultChecked={formResponse?.do_you_have_high_quality_photos_of_your_community === 'No'} id='do_you_have_high_quality_photos_of_your_community_no' onChange={e => handleChange(e)} />
			</Form.Group>
			<ButtonGroup>
				<Button variant='secondary' onClick={() => prevStep()} className='m-1'>
					<i className='fas fa-angle-left'></i> Previous
				</Button>
				<Button variant='primary' onClick={() => nextStep()} className='m-1'>
					Next <i className='fas fa-angle-right'></i>
				</Button>
			</ButtonGroup>
		</Wrapper>
	);
};

export default PhotoGallery;
