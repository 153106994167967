import { AccordionToggle } from 'components/shared/Accordion';
import AccordionCollapse from 'react-bootstrap/AccordionCollapse';
import styled from 'styled-components';

export const Section = styled.section`
	display: grid;
	gap: 0.5rem;
`;

export const Collapse = styled(AccordionCollapse)`
	display: grid;
	margin-top: 0.5rem;
`;

export const Toggle = styled(AccordionToggle)`
	all: unset;
	display: grid;
	grid-template-columns: auto 3.5rem;
	gap: 1rem;
	width: 100%;

	@media (max-width: 576px) {
		grid-template-columns: 100%;
		gap: 0.25rem;
	}
`;
