import { useEffect, useRef } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { ButtonGroup } from '../Components';
import StepNumber from '../StepNumber';
import styled from 'styled-components';

const Wrapper = styled.div`
	position: relative;
	top: 7%;

	@media (max-width: 991px) {
		position: static;
	}
`;

const HeroImage = ({ prevStep, nextStep, handleChange, formResponse }) => {
	// Scroll to element
	const scrollRef = useRef();
	const scrollToElement = () => scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

	useEffect(() => {
		// Scroll to element if on desktop
		if (window.matchMedia('(min-width: 992px)').matches) {
			scrollToElement();
		}
	}, []);

	return (
		<Wrapper ref={scrollRef}>
			<Row className='align-items-center' noGutters>
				<Col xs='auto'>
					<StepNumber number={2} />
				</Col>
				<Col>
					<h5 className='text-xl font-semibold'>Hero Image</h5>
				</Col>
			</Row>
			<p>We are happy to purchase a high-quality, professional stock photo to make your website stand out.</p>
			<p>
				Please take a look at{' '}
				<a href='https://www.istockphoto.com' target='_blank' rel='noopener noreferrer' className='text-sky-600'>
					www.istockphoto.com
				</a>
				, and choose a few options. We recommend searching for your city, or local landmarks.
			</p>
			<p>If you prefer to use a high quality photograph of your community, we can also use that instead.</p>
			<Form.Group className='mb-3'>
				<Form.Label>Your 3 image selections from istockphoto.com</Form.Label>
				<Form.Control as='textarea' rows={5} name='hero_image_istockphoto_urls' defaultValue={formResponse?.hero_image_istockphoto_urls} onChange={e => handleChange(e)} />
				<Form.Text>Copy and paste the links to your image choices here.</Form.Text>
			</Form.Group>
			<ButtonGroup>
				<Button variant='secondary' onClick={() => prevStep()} className='m-1'>
					<i className='fas fa-angle-left'></i> Previous
				</Button>
				<Button variant='primary' onClick={() => nextStep()} className='m-1'>
					Next <i className='fas fa-angle-right'></i>
				</Button>
			</ButtonGroup>
		</Wrapper>
	);
};

export default HeroImage;
