import styled from 'styled-components';
import { Accordion } from 'react-bootstrap';
import { tidyUrl } from 'utils/Tidy';
import { AccordionToggle } from 'components/shared/Accordion';
import { getPreviewUrl } from 'utils/Sites';

const Component = styled.div`
	margin: 0 0 1rem;
`;

const Toggle = styled(AccordionToggle)`
	cursor: pointer;
	display: inline-block;
`;

const Properties = styled.div`
	background: white;
	border-radius: 0.5rem;
	overflow: auto;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
	padding: 1rem 1.5rem;
	margin: 1rem 0;
	display: grid;
	gap: 0.5rem;
`;

const PropertyRow = styled.div`
	display: grid;
	grid-template-columns: minmax(10rem, 1fr) minmax(10rem, 2fr) minmax(10rem, 1fr);
	gap: 1rem;
`;

const Cell = styled.div`
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const PropertyHeading = styled(Cell)`
	font-weight: bold;
`;

const PropertyDetail = styled(Cell)``;

const MultiSite = ({ properties }) => {
	return (
		<Component>
			<p className='m-1 text-secondary'>
				<i className='fas fa-globe me-1 text-secondary'></i> Number of websites in project: <span className='text-dark fw-bold'>{properties.length}</span>
			</p>
			{properties.length > 0 && (
				<Accordion>
					<Toggle className='text-secondary' eventKey='0'>
						<p className='mx-1 my-0'>
							<i className='fas fa-info-circle me-1 text-secondary'></i> View all website details <i className='fas fa-angle-down ms-1'></i>
						</p>
					</Toggle>
					<Accordion.Collapse eventKey='0'>
						<Properties>
							<PropertyRow>
								<PropertyHeading>Property Name</PropertyHeading>
								<PropertyHeading>Preview Site URL</PropertyHeading>
								<PropertyHeading>Live Website URL</PropertyHeading>
							</PropertyRow>
							{properties.map(property => (
								<PropertyRow key={property._id}>
									<PropertyDetail>{property.property_name}</PropertyDetail>
									<PropertyDetail>
										<a href={getPreviewUrl(property.site_id)} target='_blank' rel='noopener noreferrer' className='text-sky-600'>
											{tidyUrl(getPreviewUrl(property.site_id))}
										</a>
									</PropertyDetail>
									<PropertyDetail>
										<a href={property.website_url} target='_blank' rel='noopener noreferrer' className='text-sky-600'>
											{tidyUrl(property.website_url)}
										</a>
									</PropertyDetail>
								</PropertyRow>
							))}
						</Properties>
					</Accordion.Collapse>
				</Accordion>
			)}
		</Component>
	);
};

export default MultiSite;
