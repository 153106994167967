import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { tidyUnderscores, tidyCapitalize } from 'utils/Tidy';
import { Link, Element } from 'react-scroll';

const PinnedComponent = styled.div`
	background: white;
	padding: 1.5rem;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
	border-radius: 0.5rem;
`;

const Title = styled.h2`
	font-size: 1.5rem;
	display: flex;
	align-items: center;
	gap: 1rem;
`;

const PinItem = styled(Link)`
	cursor: pointer;
	text-decoration: none !important;

	i {
		position: relative;
		visibility: hidden;
		opacity: 0;
		left: -1rem;
		transition: 0.2s;
	}

	&:hover {
		i {
			visibility: visible;
			opacity: 1;
			left: 0;
		}
	}
`;

const PinList = styled.div`
	display: flex;
	flex-direction: column;
	align-items: start;
	gap: 0.25rem 0;
`;

const PinnWrapperComponent = styled(Element)`
	position: relative;
`;

const PinButton = styled.button`
	all: unset;
	position: absolute;
	top: 0;
	right: 0;
	transition: 0.2s;
	color: var(--bs-secondary);
	opacity: 0.25;

	&:hover,
	&:active {
		color: var(--bs-primary);
		opacity: 1;
	}

	&.marked {
		color: var(--bs-primary);
		opacity: 1;
	}
`;

const Section = styled.div``;

const SectionName = styled.h6`
	margin: 1.5rem 0 0.5rem;
`;

export const Pinned = ({ setActiveSection }) => {
	// Hooks
	const {
		values: { pinned }
	} = useFormikContext();

	// Sections
	const sections = pinned.reduce((total, current) => {
		const currentItem = {
			name: current.section,
			fields: [current.name]
		};
		const exists = total.find(section => section.name === current.section);
		if (exists) {
			total.find(section => section.name === current.section).fields.push(current.name);
		} else {
			total.push(currentItem);
		}
		return total;
	}, []);

	return (
		sections.length > 0 && (
			<PinnedComponent>
				<Title>Pinned Items</Title>
				{sections.map(section => (
					<Section key={section.name}>
						<SectionName>
							<i className='fas fa-thumbtack text-secondary me-1'></i> {section.name}
						</SectionName>
						<PinList>
							{section.fields.map(field => (
								<PinItem key={field} to={field} smooth={true} isDynamic={true} duration={500} containerId='main' offset={-60} onClick={() => setActiveSection(section.name)}>
									{tidyCapitalize(tidyUnderscores(field))} <i className='fas fa-angle-right ms-1'></i>
								</PinItem>
							))}
						</PinList>
					</Section>
				))}
			</PinnedComponent>
		)
	);
};

export const PinWrapper = ({ section, name, children }) => {
	// Hooks
	const {
		setFieldValue,
		values: { pinned }
	} = useFormikContext();

	// Props
	const isMarked = pinned.find(pin => pin.name === name);

	// Handlers
	const togglePinForLater = () => {
		if (!isMarked) {
			setFieldValue('pinned', [...pinned, { name, section }]);
		} else {
			setFieldValue(
				'pinned',
				pinned.filter(pin => pin.name !== name)
			);
		}
	};

	return (
		<PinnWrapperComponent name={name}>
			<PinButton className={isMarked && 'marked'} title='Pin for later' type='button' onClick={() => togglePinForLater()} tabIndex='-1'>
				<i className='fas fa-thumbtack'></i>
			</PinButton>
			{children}
		</PinnWrapperComponent>
	);
};
