import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MaterialsListView from 'components/shared/MaterialsList/MaterialsListView';

const Assets = ({ plan, id }) => {
	const { assets, assets_url } = { ...plan };

	return assets ? (
		<Row>
			<Col>
				<MaterialsListView assets_url={assets_url} assets={assets} hideButton={true} />
				<Button as={Link} variant='primary' className='my-3' to={`/edit/${id}`}>
					Edit assets checklist <i className='fas fa-clipboard-list'></i>
				</Button>
			</Col>
		</Row>
	) : (
		<div className='mb-4'>No assets to display.</div>
	);
};

export default Assets;
