import { Panel } from 'components/shared/Panel';
import { Survey } from 'components/shared/Surveys';
import { FormCheck, FormLabel, FormControl } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';

const WarningPanel = styled(Panel)`
	border-left: 0.5rem solid var(--bs-warning);
`;

const HOA = ({ register }) => {
	const checkboxes = ['Document Management', 'Property Maintenance (landscaping, snow removal, pool maintenance, etc.)', 'Dues & Collections', 'Board Meetings & Minutes', 'Bylaw Enforcement & Violations'];

	return (
		<Survey.Container>
			<h4 className='text-2xl font-semibold'>HOA Services</h4>
			<div>
				<div className='mb-3'>
					Which of the following HOA services do you provide? <strong className='text-danger'>*</strong>
				</div>
				{checkboxes.map(checkbox => (
					<FormCheck key={checkbox} id={checkbox}>
						<FormCheck.Input type='checkbox' value={checkbox} {...register('HOA Services.Which of the following HOA services do you provide?', { required: true })} />
						<FormCheck.Label>{checkbox}</FormCheck.Label>
					</FormCheck>
				))}
			</div>
			<div>
				<FormLabel>
					What sets you apart from other HOA services? <strong className='text-danger'>*</strong>
				</FormLabel>
				<FormControl as={TextareaAutosize} minRows={5} {...register('HOA Services.What sets you apart from other HOA services?', { required: true })} />
			</div>
			<WarningPanel>
				<h5 className='text-lg font-semibold text-orange-400'>NOTE:</h5>
				<div>We can only post governing documents on your website, which will be publicly visible. All other documents (private or public) will need to be added via the portal.</div>
			</WarningPanel>
		</Survey.Container>
	);
};

export default HOA;
