import { tidyDateWords } from 'utils/Tidy';
import styled from 'styled-components';
import { CalendarCheck, CalendarClock } from 'lucide-react';

const Date = styled.div`
	font-size: ${({ $large }) => ($large ? '2rem' : '1.5rem')};
	color: ${({ $color }) => `var(--bs-${$color})`};
`;

const DueDate = ({ stage, due_date, status, target_dates, number_of_sites }) => {
	// Revisions dates
	const { revisions_1, revisions_2, live } = { ...target_dates };

	// Status map
	const statusMap = {
		'On Time': 'success',
		Delayed: 'warning',
		Overdue: 'danger'
	};

	return stage === 'Review' ? (
		!number_of_sites || number_of_sites === 'Single Site' ? (
			<div className='space-y-2'>
				<div>
					<div>Round 1 Revisions:</div>
					<Date $color={statusMap[status]} $large={false} className='flex items-center gap-3 font-bold'>
						<CalendarClock className='w-6 h-6' /> {tidyDateWords(revisions_1)}
					</Date>
				</div>
				<div>
					<div>Round 2 Revisions:</div>
					<Date $color={statusMap[status]} $large={false} className='flex items-center gap-3 font-bold'>
						<CalendarClock className='w-6 h-6' /> {tidyDateWords(revisions_2)}
					</Date>
				</div>
				<div>
					<div>Go Live:</div>
					<Date $color={statusMap[status]} $large={true} className='flex items-center gap-3 font-bold'>
						<CalendarCheck className='w-7 h-7' /> {tidyDateWords(live)}
					</Date>
				</div>
			</div>
		) : (
			<div className='space-y-2'>
				<div>
					<div>Template Revisions:</div>
					<Date $color={statusMap[status]} $large={false} className='flex items-center gap-3 font-bold'>
						<CalendarClock className='w-6 h-6' /> {tidyDateWords(revisions_1)}
					</Date>
				</div>
				<div>
					<div>Site Revisions:</div>
					<Date $color={statusMap[status]} $large={false} className='flex items-center gap-3 font-bold'>
						<CalendarClock className='w-6 h-6' /> {tidyDateWords(revisions_2)}
					</Date>
				</div>
				<div>
					<div>Go Live:</div>
					<Date $color={statusMap[status]} $large={true} className='flex items-center gap-3 font-bold'>
						<CalendarCheck className='w-7 h-7' /> {tidyDateWords(live)}
					</Date>
				</div>
			</div>
		)
	) : stage !== 'Live' ? (
		<Date $color={statusMap[status]} $large={true} className='flex items-center gap-3 font-bold'>
			<CalendarClock className='w-7 h-7' /> {tidyDateWords(due_date)}
		</Date>
	) : (
		<div>Nothing, your site is live!</div>
	);
};

export default DueDate;
