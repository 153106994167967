import { Survey } from 'components/shared/Surveys';
import { FormLabel, FormControl } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';

const Investment = ({ register }) => {
	return (
		<Survey.Container>
			<h4 className='font-semibold text-2xl'>Investment</h4>
			<div>
				<FormLabel>
					What are some highlights from your investment portfolio? <strong className='text-danger'>*</strong>
				</FormLabel>
				<FormControl as={TextareaAutosize} minRows={5} {...register('Investment.What are some highlights from your investment portfolio?', { required: true })} />
			</div>
			<div>
				<FormLabel>
					What does the process look like when someone decides to invest in a project with you? <strong className='text-danger'>*</strong>
				</FormLabel>
				<FormControl as={TextareaAutosize} minRows={5} {...register('Investment.What does the process look like when someone decides to invest in a project with you?', { required: true })} />
			</div>
			<div>
				<FormLabel>
					What type of benefits do you offer your partners/clients and why should they choose you over other investment managers? <strong className='text-danger'>*</strong>
				</FormLabel>
				<FormControl as={TextareaAutosize} minRows={5} {...register('Investment.What type of benefits do you offer your partners/clients and why should they choose you over other investment managers?', { required: true })} />
			</div>
		</Survey.Container>
	);
};

export default Investment;
