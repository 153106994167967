import { useState, useRef } from 'react';
import styled from 'styled-components';
import templateImage from 'img/templates/appfolio-ca-template.jpg';

const ImageContainer = styled.div`
	position: relative;
	width: 100%;
	height: 0;
	padding-top: 266%;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
`;

const Placeholder = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: white;
`;

const Image = styled.img`
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	animation: fadeIn 1s forwards;
`;

const StepNumber = styled.div`
	width: 4rem;
	height: 4rem;
	background: ${({ $active }) => ($active ? 'var(--bs-primary)' : 'var(--bs-secondary)')};
	box-shadow: ${({ $active }) => ($active ? '0 0 0 10px var(--primaryOutline2)' : '0 2px 5px rgba(0, 0, 0, 0.25)')};
	color: white;
	border-radius: 50%;
	display: grid;
	place-items: center;
	font-size: 1.5rem;
	font-weight: bold;
	position: absolute;
	transition: 0.5s;
`;

const TemplateImage = ({ currentStep }) => {
	// Component state
	const [isLoaded, setIsLoaded] = useState(false);

	// Image ref for load event
	const imageRef = useRef();

	// Step number positions
	const steps = [
		{ number: 1, position: { top: '0.25%', left: '15%' } },
		{ number: 2, position: { top: '7%', left: '5%' } },
		{ number: 3, position: { top: '20%', left: '15%' } },
		{ number: 4, position: { top: '29%', left: '13%' } },
		{ number: 5, position: { top: '39%', left: '9%' } },
		{ number: 6, position: { top: '58%', left: '5%' } },
		{ number: 7, position: { top: '93.5%', left: '35%' } }
	];

	return (
		<ImageContainer>
			<Image src={templateImage} alt='AppFolio CA Template Image' ref={imageRef} onLoad={() => setIsLoaded(true)} />
			{!isLoaded && <Placeholder />}
			{steps.map(step => (
				<StepNumber key={step.number} style={{ top: step.position.top, left: step.position.left }} $active={currentStep === step.number}>
					{step.number}
				</StepNumber>
			))}
		</ImageContainer>
	);
};

export default TemplateImage;
