import LoadingOverlay from 'components/shared/LoadingOverlay';
import { Views } from 'components/shared/Views';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { tidyDateTime, tidyFormResponse } from 'utils/Tidy';

const Initial = () => {
	// Hooks
	const { id } = useParams();
	const { data: jwt } = useSWR('/auth/jwt');
	const { data, isLoading } = useSWR(jwt ? `/api/v1/site-genie/initial_survey/${id}` : null);
	const { company_name, submitted, ...allFields } = { ...data };

	// Format responses
	const fieldsToRender = tidyFormResponse(allFields);

	return isLoading ? (
		<LoadingOverlay />
	) : (
		<Views.Wrapper>
			<Views.Header heading={`Initial Survey - ${company_name}`} subheading={`Submitted ${tidyDateTime(submitted)}`} />
			<Views.Panels>
				{fieldsToRender.map(({ question, response }) => (
					<Views.Panel key={question}>
						<Views.Question>{question}</Views.Question>
						<Views.Response>{response || '-'}</Views.Response>
					</Views.Panel>
				))}
			</Views.Panels>
		</Views.Wrapper>
	);
};

export default Initial;
