import { useEffect, useRef } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { ButtonGroup } from '../Components';
import StepNumber from '../StepNumber';
import styled from 'styled-components';

const Wrapper = styled.div`
	position: relative;
	top: 100%;
	transform: translateY(-100%);

	@media (max-width: 991px) {
		position: static;
		transform: translateY(0);
	}
`;

const ContactInfo = ({ prevStep, nextStep, handleChange, formResponse }) => {
	// Scroll to element
	const scrollRef = useRef();
	const scrollToElement = () => scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

	useEffect(() => {
		// Scroll to element if on desktop
		if (window.matchMedia('(min-width: 992px)').matches) {
			scrollToElement();
		}
	}, []);

	return (
		<Wrapper ref={scrollRef}>
			<Row className='align-items-center' noGutters>
				<Col xs='auto'>
					<StepNumber number={7} />
				</Col>
				<Col>
					<h5 className='text-xl font-semibold'>Contact Information</h5>
				</Col>
			</Row>
			<Form.Group className='mb-3'>
				<Form.Label>Address</Form.Label>
				<Form.Control type='input' rows={5} name='address' defaultValue={formResponse?.address} onChange={e => handleChange(e)} />
			</Form.Group>
			<Form.Group className='mb-3'>
				<Form.Label>Phone Number</Form.Label>
				<Form.Control type='tel' rows={5} name='phone_number' defaultValue={formResponse?.phone_number} onChange={e => handleChange(e)} />
			</Form.Group>
			<Form.Group className='mb-3'>
				<Form.Label>Fax Number</Form.Label>
				<Form.Control type='tel' rows={5} name='fax_number' defaultValue={formResponse?.fax_number} onChange={e => handleChange(e)} />
			</Form.Group>
			<ButtonGroup>
				<Button variant='secondary' onClick={() => prevStep()} className='m-1'>
					<i className='fas fa-angle-left'></i> Previous
				</Button>
				<Button variant='primary' onClick={() => nextStep()} className='m-1'>
					Next <i className='fas fa-angle-right'></i>
				</Button>
			</ButtonGroup>
		</Wrapper>
	);
};

export default ContactInfo;
