import Helmet from 'react-helmet';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { CircleAlert } from 'lucide-react';

export default function NotFound() {
	const navigate = useNavigate();

	return (
		<>
			<Helmet>
				<title>404 | Page Not Found | Project Plan Tool</title>
			</Helmet>
			<div className='flex-wrap p-3 p-md-5 d-flex justify-content-center align-items-center text-danger'>
				<div className='text-center'>
					<h1 className='flex items-center justify-center gap-3 text-6xl font-bold'>
						<CircleAlert className='w-16 h-16' /> 404
					</h1>
					<p className='my-4 text-secondary'>Sorry, this page could not be found.</p>
					<Button variant={'secondary'} onClick={() => navigate('/')} className='mx-auto'>
						Go Home <i className='fas fa-home'></i>
					</Button>
				</div>
			</div>
		</>
	);
}
