import { Field, useFormikContext } from 'formik';
import { Form, Row, Col } from 'react-bootstrap';
import { PinWrapper } from '../Pinned';
import BriefFieldsEnum from 'config//BriefFieldsEnum.json';

const Features = ({ section }) => {
	// Hooks
	const { values } = useFormikContext();

	// Enum
	const { features_enum } = BriefFieldsEnum;

	return (
		<Row>
			<Col lg={4}>
				{features_enum.map(option => (
					<Field as={Form.Switch} key={option} name='features' checked={values.features.includes(option)} value={option} label={option} id={`feature-${option}`} />
				))}
			</Col>
			<Col lg={8}>
				{values.features.includes('Blog') && (
					<PinWrapper section={section} name='blog_user_email_address'>
						<Form.Label className='m-0'>Blog User Email Address</Form.Label>
						<Form.Text>Separate multiple email addresses with a comma</Form.Text>
						<Field as={Form.Control} name='blog_user_email_address' />
					</PinWrapper>
				)}
				{values.features.includes('Google Analytics') && (
					<PinWrapper section={section} name='google_analytics_tracking_id'>
						<Form.Label>Google Analytics Tracking ID</Form.Label>
						<Field as={Form.Control} name='google_analytics_tracking_id' />
					</PinWrapper>
				)}
				{values.features.includes('Backend Access') && (
					<PinWrapper section={section} name='backend_access_email_address'>
						<Form.Label className='m-0'>Backend Access Email Address</Form.Label>
						<Form.Text>Separate multiple email addresses with a comma</Form.Text>
						<Field as={Form.Control} name='backend_access_email_address' />
					</PinWrapper>
				)}
			</Col>
		</Row>
	);
};

export default Features;
