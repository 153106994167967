import styled from 'styled-components';
import logo from 'img/app/appfolio_logo.svg';
import PageHeader from './PageHeader';

const Wrapper = styled.main`
	max-width: 1280px;
	margin: 0 auto;
	padding: 1.5rem;

	@media (max-width: 576px) {
		padding: 1rem;
	}
`;

const HeaderComponent = styled.div`
	display: grid;
	grid-template-columns: 75px 1fr;
	gap: 1.5rem;
	align-items: center;
	margin: 1rem 0 2rem;
`;

const Panels = styled.div`
	display: grid;
	gap: 1rem;
`;

const Panel = styled.div`
	background: white;
	box-shadow: rgba(0, 0, 0, 0.08) 0px 0.125rem 0.25rem;
	padding: 1rem 1.25rem;
	border-radius: 0.5rem;
	display: grid;
	gap: 0.5rem;
`;

const Question = styled.div`
	color: var(--bs-secondary);
`;

const Response = styled.div``;

const Category = styled.h4`
	margin: 1rem 0 0;
`;

const List = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;

	li::before {
		content: '✓';
		margin-right: 0.5rem;
		color: var(--bs-success);
	}
`;

const Header = ({ heading, subheading, ...props }) => {
	return (
		<HeaderComponent {...props}>
			<img src={logo} alt='AppFolio Logo' width={75} />
			<PageHeader heading={heading} subheading={subheading} className='mb-0' />
		</HeaderComponent>
	);
};

export const Views = { Wrapper, Header, Panels, Panel, Question, Response, Category, List };
