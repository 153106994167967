import { tidyDate } from './Tidy';
import dayjs from 'dayjs';

export const calculateStageDates = start => {
	const collect = dayjs(start).add(5, 'days').format('YYYY-MM-DD');
	const build = dayjs(collect).add(10, 'days').format('YYYY-MM-DD');
	const review = dayjs(build).add(10, 'days').format('YYYY-MM-DD');
	const revisions_1 = dayjs(review).add(5, 'days').format('YYYY-MM-DD');
	const revisions_2 = dayjs(revisions_1).add(5, 'days').format('YYYY-MM-DD');
	const live = dayjs(revisions_2).add(5, 'days').format('YYYY-MM-DD');
	return { start, collect, build, review, revisions_1, revisions_2, live };
};

export const calculateDueDate = (stage, target_dates) => {
	const { collect, build, review, live } = { ...target_dates };
	let dueDate = null;
	switch (stage) {
		case 'Welcome':
			dueDate = tidyDate(collect);
			break;
		case 'Collect':
			dueDate = tidyDate(build);
			break;
		case 'Build':
			dueDate = tidyDate(review);
			break;
		case 'Review':
			dueDate = tidyDate(live);
			break;
		default:
			break;
	}
	return dueDate;
};

export const calculateStatus = (on_hold, stage, dueDate) => {
	// If on hold
	if (on_hold) {
		return 'On Hold';
	}

	// If Live
	else if (stage === 'Live') {
		return 'Complete';
	} else {
		// Set today's date
		const currentDate = dayjs().format('MM/DD/YYYY');

		// Define project status
		let status = null;

		// Calculate difference from today
		const diff = dayjs(dueDate).diff(currentDate, 'day');

		// If today or later
		if (Math.sign(diff) === 1 || Math.sign(diff) === 0) {
			status = 'On Time';
		} else {
			const delay = Math.abs(diff);
			status = delay > 7 ? 'Overdue' : 'Delayed';
		}
		return status;
	}
};

export const calculateWaitingOn = (stage, customer, designer) => {
	let waitingOn = null;
	switch (stage) {
		case 'Welcome':
			waitingOn = designer;
			break;
		case 'Collect':
			waitingOn = customer;
			break;
		case 'Build':
			waitingOn = designer;
			break;
		case 'Review':
			waitingOn = customer;
			break;
		default:
			break;
	}
	return waitingOn;
};
