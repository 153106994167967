import { useState, useEffect } from 'react';
import PageHeader from 'components/shared/PageHeader';
import styled from 'styled-components';
import { Form, ProgressBar, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import logo from 'img/app/appfolio_logo.svg';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import { getJwt } from 'utils/Auth';
import { ButtonGroup } from './Common/Components';
import Preview from './Preview';

// Steps
import Welcome from './Steps/1-Welcome';
import ContactDetails from './Steps/2-ContactDetails';
import PropertyDetails from './Steps/3-PropertyDetails';
import FinalDetails from './Steps/4-FinalDetails';
import Review from './Steps/5-Review';
import Complete from './Steps/6-Complete';
import { fetcher } from 'utils/Fetch';

// Components
const Component = styled.div`
	max-width: 1280px;
	margin: 0 auto;

	.row,
	h4,
	p {
		margin-bottom: 1rem;
	}

	.form-label {
		font-weight: bold;
	}

	.custom-control {
		margin-bottom: 0.5rem;
	}
`;

const Logo = styled.img`
	width: 75px;
	margin-right: 2rem;

	@media (max-width: 767px) {
		width: 50px;
		margin-right: 1rem;
	}
`;

const Progress = styled(ProgressBar)`
	height: 2rem;
	margin-bottom: 2rem;
	font-size: 1rem;
`;

const Wrapper = styled.div`
	display: flex;
	gap: 2rem;
	align-items: start;

	> * {
		flex: 1 0 50%;
	}

	@media (max-width: 767px) {
		flex-direction: column;
		gap: 1rem;

		> * {
			flex: 1 0 100%;
			width: 100%;
		}
	}
`;

const Placeholder = styled.div`
	background: var(--bs-light);
	padding: 5rem;
	text-align: center;
	border-radius: 0.5rem;
	color: var(--bs-secondary);

	@media (max-width: 767px) {
		display: none;
	}
`;

const Important = styled.div`
	background: white;
	margin: 1rem 0 1.5rem;
	padding: 1rem;
	font-size: 0.9rem;
	box-shadow: inset 3px 0 0 0 var(--bs-danger)}, 0 0.125rem 0.25rem rgb(0 0 0 / 8%);

	h5 {
		color: var(--bs-danger);
	}

	p:last-child {
		margin-bottom: 0;
	}
`;

const PropertyForm = () => {
	// Default response
	const defaultResponse = {
		property_name: '',
		address: '',
		phone: '',
		email: '',
		bedroom_range: '',
		bathroom_range: '',
		rent_range: '',
		property_description: '',
		property_features: '',
		onsite_services: '',
		apartment_features: '',
		neighborhood_details: '',
		security_deposit: '',
		application_fee: '',
		pet_policy: '',
		other_fees: '',
		property_group: '',
		social_media: ''
	};

	// State
	const [formResponse, setFormResponse] = useState(defaultResponse);
	const [currentStep, setCurrentStep] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const [designer, setDesigner] = useState({});
	const [uploadUrl, setUploadUrl] = useState('');

	// Hooks
	const { id } = useParams();
	const navigate = useNavigate();

	// Init
	const init = async () => {
		await getJwt();
		const { appfolio_contact, assets_url } = await fetcher(`/api/v1/plans/${id}`);
		const designer = await fetcher(`/api/v1/users/email/${appfolio_contact}`);
		setDesigner(designer);
		setUploadUrl(assets_url);
		setIsLoading(false);
	};

	// Effects
	useEffect(() => {
		init();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Handlers
	const handleNextStep = () => {
		setCurrentStep(currentStep + 1);
		window.scrollTo(0, 0);
	};

	const handlePrevStep = () => {
		setCurrentStep(currentStep - 1);
		window.scrollTo(0, 0);
	};

	const handleChange = e => {
		// Destructure target
		const { name, value } = e.target;
		setFormResponse(prev => ({ ...prev, [name]: value }));
	};

	const handleSubmit = async e => {
		// Prevent page refresh
		e.preventDefault();

		setIsLoading(true);

		// POST to API
		try {
			await fetcher(`/api/v1/properties/${id}`, { method: 'PUT', body: JSON.stringify({ ...formResponse, customer_response: true, submitted: new Date() }) });
			setCurrentStep(stepComponents.length - 1);
		} catch (error) {
			navigate('/error', { state: { from_public_page: true } });
		} finally {
			setIsLoading(false);
		}
	};

	// Step components
	const stepComponents = [
		{
			component: Welcome,
			order: 0,
			required: []
		},
		{
			component: ContactDetails,
			order: 1,
			required: ['property_name', 'address', 'phone', 'email']
		},
		{
			component: PropertyDetails,
			order: 2,
			required: ['bedroom_range', 'bathroom_range', 'rent_range', 'property_description']
		},
		{
			component: FinalDetails,
			order: 3,
			required: []
		},
		{
			component: Review,
			order: 4,
			required: []
		},
		{
			component: Complete,
			order: 5,
			required: []
		}
	];

	// Current progress
	const currentProgress = Math.round((currentStep / (stepComponents.length - 1)) * 100);

	// Render functions
	const renderPrevButton = () => {
		if (currentStep !== 0 && currentStep !== stepComponents.length - 1) {
			return (
				<Button onClick={() => handlePrevStep()} variant='secondary'>
					<i className='fas fa-angle-left'></i> Prev
				</Button>
			);
		}
	};

	const renderNextButton = () => {
		if (currentStep !== stepComponents.length - 1) {
			if (currentStep !== stepComponents.length - 2) {
				return (
					<Button onClick={() => handleNextStep()} disabled={!stepComponents[currentStep].required.every(field => formResponse[field])}>
						Next <i className='fas fa-angle-right'></i>
					</Button>
				);
			} else {
				return (
					<Button onClick={e => handleSubmit(e)}>
						Submit <i className='fas fa-paper-plane'></i>
					</Button>
				);
			}
		}
	};

	const renderPreview = () => {
		const hideOnSteps = [0, stepComponents.length - 2, stepComponents.length - 1];
		if (!hideOnSteps.includes(currentStep)) {
			return formResponse.property_name ? (
				<div>
					<Important>
						<h5 className='mb-2 text-lg font-semibold'>This is an example only.</h5>
						<p>The example below is based on a generic website template. We will take your property information and insert it into your chosen template, which may look different than this template.</p>
					</Important>
					<Preview formResponse={formResponse} />
				</div>
			) : (
				<Placeholder>Your preview will render here.</Placeholder>
			);
		}
	};

	return (
		<div className='p-3 p-md-5'>
			{!isLoading ? (
				<Component>
					<Helmet>
						<title>Property Details Survey | AppFolio Sites</title>
					</Helmet>
					<div className='d-flex align-items-start'>
						<Logo src={logo} width='75' alt='AppFolio Logo' className='mb-3 mb-lg-0' />
						<PageHeader heading='Property Details Survey' subheading='AppFolio Sites' />
					</div>
					<Progress now={currentProgress} label={`${currentProgress}% complete`} />
					<Wrapper>
						<Form onSubmit={e => handleSubmit(e)}>{stepComponents.map(step => currentStep === step.order && <step.component key={step.order} handleChange={handleChange} formResponse={formResponse} designer={designer} uploadUrl={uploadUrl} />)}</Form>
						{renderPreview()}
					</Wrapper>
					<ButtonGroup>
						{renderPrevButton()}
						{renderNextButton()}
					</ButtonGroup>
				</Component>
			) : (
				<LoadingOverlay />
			)}
		</div>
	);
};

export default PropertyForm;
