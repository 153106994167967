import { Check, Plus, X } from 'lucide-react';
import { useState } from 'react';
import { tidyUrl } from 'utils/Tidy';

const EditInput = ({ register, getValues, label, name }) => {
	// State
	const [isEditing, setIsEditing] = useState(false);
	const value = getValues(name);

	return (
		<div>
			<div className='mb-1 text-sm text-gray-500'>{label}</div>
			{isEditing ? (
				<div className='grid grid-cols-[1fr_auto_auto] gap-2 items-center'>
					<input type='text' {...register(name)} className='border-b border-gray-200 border-dashed outline-none' />
					<button type='submit'>
						<Check className='w-5 h-5 text-green-500' />
					</button>
					<button type='button' onClick={() => setIsEditing(false)}>
						<X className='w-5 h-5 text-red-500' />
					</button>
				</div>
			) : (
				<button onClick={() => setIsEditing(true)} className='grid text-left text-gray-700 break-all duration-100 hover:text-black'>
					{value ? tidyUrl(value) : <Plus className='w-5 h-5' />}
				</button>
			)}
		</div>
	);
};

export default EditInput;
