import { tidyDateTime } from 'utils/Tidy';
import { useFormikContext } from 'formik';
import styled from 'styled-components';
import { handleMissingImage } from 'utils/Errors';
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const ContentWrapper = styled.div`
	display: flex;
	gap: 6rem;
	align-items: start;

	@media (max-width: 767px) {
		flex-direction: column;
		gap: 4rem;
	}
`;

const DesignerPanel = styled.div`
	flex: 0 0 15rem;
	text-align: center;
	display: grid;
	justify-content: end;

	@media (max-width: 767px) {
		justify-content: center;
	}
`;

const WelcomePanel = styled.div`
	flex: 1 1 auto;
	background: white;
	padding: 2rem;
	border-radius: 0.5rem;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
	position: relative;

	&::after {
		content: '';
		height: 0;
		width: 0;
		border-top: 2rem solid transparent;
		border-bottom: 2rem solid transparent;
		border-right: 3rem solid white;
		z-index: 1;
		position: absolute;
		top: 2.5rem;
		left: -3rem;
	}

	@media (max-width: 767px) {
		&::after {
			content: '';
			height: 0;
			width: 0;
			border-left: 2rem solid transparent;
			border-right: 2rem solid transparent;
			border-bottom: 3rem solid white;
			z-index: 1;
			position: absolute;
			top: -5rem;
			left: calc(50% - 2rem);
		}
	}

	p:last-child {
		margin: 0;
	}
`;

const Profile = styled.img`
	border-radius: 50%;
	height: 8rem;
	width: 8rem;
	margin: 0 0 1rem;
`;

const Name = styled.h5``;

const Title = styled.p`
	margin: 0;
`;

const Location = styled.p`
	margin: 0;
	color: var(--bs-secondary);
`;

const Complete = ({ owner }) => {
	// Hooks
	const { values: formState } = useFormikContext();
	const { submitted } = formState;
	const { koc_survey } = { ...submitted };
	const { id } = useParams();

	// State
	const { imageUrl, name, jobTitle, userLocation } = { ...owner };

	// Render
	return (
		<ContentWrapper>
			<DesignerPanel>
				<Profile src={imageUrl} onError={({ currentTarget }) => handleMissingImage(currentTarget)} alt={name} />
				<Name>{name}</Name>
				<Title>{jobTitle}</Title>
				<Location>{userLocation}</Location>
			</DesignerPanel>
			<WelcomePanel>
				<h4 className='font-semibold text-2xl'>Thank you!</h4>
				<p>
					Your website kickoff survey response was submitted successfully on <strong>{tidyDateTime(koc_survey)}.</strong> I will be in contact with you shortly to schedule a project kickoff call.
				</p>
				<p>As part of your website purchase, AppFolio Sites can generate a marketing overview for the homepage of your website at no additional cost.</p>
				<p>If you would like to take advantage of this service, please complete the Initial Survey by clicking the button below, otherwise you can now close this window.</p>
				<Button href={`/site-genie/${id}/survey/initial?skipWelcome=true`}>
					Begin Initial Survey <i className='fas fa-angle-right'></i>
				</Button>
			</WelcomePanel>
		</ContentWrapper>
	);
};

export default Complete;
