import { useState } from 'react';
import { Formik } from 'formik';
import Helmet from 'react-helmet';
import PageHeader from 'components/shared/PageHeader';
import useSWR from 'swr';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import { useParams, useNavigate } from 'react-router-dom';
import EditForm from './EditForm';
import Confirmation from 'components/shared/Confirmation';
import DropboxPrompt from './DropboxPrompt';
import { fetcher } from 'utils/Fetch';
import { useMessage } from 'utils/MessageContext';

const Edit = () => {
	// State
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [showDropboxPrompt, setShowDropboxPrompt] = useState(false);

	// Hooks
	const { id } = useParams();
	const { data: plan, isLoading, mutate } = useSWR(`/api/v1/plans/${id}`);
	const navigate = useNavigate();
	const { setMessage } = useMessage();

	// Handlers
	const handleSubmit = async values => {
		try {
			await fetcher(`/api/v1/plans/${id}`, { method: 'PUT', body: JSON.stringify(values) });
			navigate(`/plan/${id}`);
		} catch (error) {
			setMessage({ variant: 'danger', text: 'There was an error saving this project plan.' });
		}
	};

	const handleDeletePlan = async () => {
		try {
			await fetcher(`/api/v1/plans/${id}`, { method: 'DELETE' });
			navigate('/');
		} catch (error) {
			setMessage({ variant: 'danger', text: 'There was an error deleting this project plan.' });
		}
	};

	return !isLoading ? (
		<>
			<Helmet>
				<title>Edit Project Plan</title>
			</Helmet>
			<Confirmation show={showConfirmation} setShow={setShowConfirmation} message={`delete this plan.`} confirmFunction={handleDeletePlan} buttonType={'danger'} />
			<DropboxPrompt show={showDropboxPrompt} setShow={setShowDropboxPrompt} />
			<PageHeader heading='Edit Project Plan' subheading='Edit an existing project plan.' />
			<Formik initialValues={plan} enableReinitialize={true} onSubmit={values => handleSubmit(values)}>
				{() => <EditForm setShowConfirmation={setShowConfirmation} setShowDropboxPrompt={setShowDropboxPrompt} mutatePlan={mutate} />}
			</Formik>
		</>
	) : (
		<LoadingOverlay />
	);
};

export default Edit;
