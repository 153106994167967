import PlanStatus from './PlanStatus';
import { tidyDate, tidyTime, tidyUserEmail } from 'utils/Tidy';
import { calculateDueDate, calculateStatus } from 'utils/Stages';
import { Table } from 'components/shared/Table';

const PlanTable = ({ plans, showOwner = false }) => {
	// Props
	const plansToRender = plans.map(plan => ({ ...plan, status: calculateStatus(plan.on_hold, plan.stage, calculateDueDate(plan.stage, plan.target_dates)), target_go_live: plan.target_dates.live }));

	const columns = !showOwner ? 'repeat(2, minmax(15rem, 1.5fr)) repeat(4, minmax(10rem, 1fr))' : 'repeat(3, minmax(15rem, 1.5fr)) repeat(4, minmax(10rem, 1fr))';

	return (
		<Table.Container>
			<Table.Header $columns={columns}>
				{showOwner && <Table.Cell>Owner</Table.Cell>}
				<Table.Cell>Company Name</Table.Cell>
				<Table.Cell>Status</Table.Cell>
				<Table.Cell>Stage</Table.Cell>
				<Table.Cell>Purpose</Table.Cell>
				<Table.Cell>Product Tier</Table.Cell>
				<Table.Cell>
					Created <i className='fas fa-angle-down text-secondary ms-1'></i>
				</Table.Cell>
			</Table.Header>
			<Table.Body>
				{plansToRender?.length > 0 ? (
					plansToRender?.map(plan => (
						<Table.Row key={plan._id} $columns={columns} to={`/plan/${plan._id}`}>
							{showOwner && <Table.Cell>{tidyUserEmail(plan.appfolio_contact)}</Table.Cell>}
							<Table.Cell>
								<div>{plan.company_name}</div>
								<small className='text-secondary'>{plan.company_contact}</small>
							</Table.Cell>
							<Table.Cell>
								<PlanStatus on_hold={plan.on_hold} stage={plan.stage} dueDate={calculateDueDate(plan.stage, plan.target_dates)} showDelay={true} />
							</Table.Cell>
							<Table.Cell>
								<div>{plan.stage}</div>
							</Table.Cell>
							<Table.Cell>
								<div>{plan.purpose}</div>
								{plan.number_of_sites && <small className='text-secondary'>{plan.number_of_sites}</small>}
							</Table.Cell>
							<Table.Cell>
								<div>{plan.product_tier ?? '-'}</div>
							</Table.Cell>
							<Table.Cell>
								<div>{tidyDate(plan.createdAt)}</div>
								<small className='text-secondary'>{tidyTime(plan.createdAt)}</small>
							</Table.Cell>
						</Table.Row>
					))
				) : (
					<Table.NoData>No plans to display.</Table.NoData>
				)}
			</Table.Body>
		</Table.Container>
	);
};

export default PlanTable;
