import { Field } from 'formik';
import { Form, Row, Col } from 'react-bootstrap';
import { PinWrapper } from '../Pinned';
import BriefFieldsEnum from 'config/BriefFieldsEnum.json';
import TextareaAutosize from 'react-textarea-autosize';

const FollowUp = ({ section }) => {
	// Enum
	const { customer_to_do_list_enum } = BriefFieldsEnum;

	return (
		<Row>
			<Col lg={3}>
				<PinWrapper section={section} name='customer_to_do_list'>
					<Form.Label className='m-0'>Customer To-Do List</Form.Label>
					<Form.Text>What does the customer still need to provide?</Form.Text>
					{customer_to_do_list_enum.map(option => (
						<Field as={Form.Check} key={option} type='checkbox' name='customer_to_do_list' value={option} label={option} id={`marketing-${option}`} />
					))}
				</PinWrapper>
			</Col>
			<Col lg={9}>
				<PinWrapper section={section} name='other_notes'>
					<Form.Label>Other Notes</Form.Label>
					<Field as={TextareaAutosize} className='form-control' minRows={3} name='other_notes' />
				</PinWrapper>
			</Col>
		</Row>
	);
};

export default FollowUp;
