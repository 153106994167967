import styled from 'styled-components';
import { handleMissingImage } from 'utils/Errors';

const Component = styled.div`
	display: flex;
	gap: 4rem;
	align-items: start;

	@media (max-width: 767px) {
		flex-direction: column;
	}
`;

const DesignerPanel = styled.div`
	flex: 1 0 auto;
	text-align: center;
`;

const WelcomePanel = styled.div`
	background: white;
	padding: 2rem;
	border-radius: 0.5rem;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
	position: relative;

	&::after {
		content: '';
		height: 0;
		width: 0;
		border-top: 2rem solid transparent;
		border-bottom: 2rem solid transparent;
		border-right: 3rem solid white;
		z-index: 1;
		position: absolute;
		top: 2.5rem;
		left: -3rem;
	}

	@media (max-width: 767px) {
		&::after {
			content: '';
			height: 0;
			width: 0;
			border-left: 2rem solid transparent;
			border-right: 2rem solid transparent;
			border-bottom: 3rem solid white;
			z-index: 1;
			position: absolute;
			top: -5rem;
			left: calc(50% - 2rem);
		}
	}

	p:last-child {
		margin: 0;
	}
`;

const Profile = styled.img`
	border-radius: 50%;
	height: 8rem;
	width: 8rem;
	margin: 0 0 1rem;
`;

const Title = styled.p`
	margin: 0;
`;

const Location = styled.p`
	margin: 0;
	color: var(--bs-secondary);
`;

const Welcome = ({ designer }) => {
	return (
		<Component>
			<DesignerPanel>
				<Profile src={designer.imageUrl} alt={designer.name} onError={({ currentTarget }) => handleMissingImage(currentTarget)} />
				<h5 className='font-semibold'>{designer.name}</h5>
				<Title>{designer.jobTitle}</Title>
				<Location>{designer.userLocation}</Location>
			</DesignerPanel>
			<WelcomePanel>
				<h4 className='text-2xl font-semibold'>We can’t wait to build a great website to market your property!</h4>
				<p>To streamline the process, complete this brief survey to outline important property details like bed/bath counts, amenities, neighborhood information, and terms and conditions.</p>
				<p>Please complete this information to the best of your ability. If you’re building more than one website with us, you can add another property at the end of the survey. Thanks!</p>
			</WelcomePanel>
		</Component>
	);
};

export default Welcome;
