import { Field, useFormikContext } from 'formik';
import { Form, Row, Col } from 'react-bootstrap';
import { PinWrapper } from '../Pinned';
import styled from 'styled-components';

const CheckWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
	margin: 0 0 0.25rem;

	.custom-radio {
		margin-bottom: 0 !important;
	}
`;

const Accessibility = ({ section }) => {
	// Hooks
	const { values } = useFormikContext();

	return (
		<Row>
			<Col>
				<PinWrapper section={section} name='accessibility_statement'>
					<Form.Label className='fw-bold'>Accessibility Statement</Form.Label>
					<CheckWrapper>
						<Field as={Form.Check} type='radio' name='accessibility_statement' label='Customer would like to include this on the website' value='Include' id='accessibility-include' />
						{values.accessibility_statement === 'Include' && <i className='fas fa-check text-success'></i>}
					</CheckWrapper>
					<CheckWrapper>
						<Field as={Form.Check} type='radio' name='accessibility_statement' label='Customer does not want to include this on the website' value='Exclude' id='accessibility-exclude' />
						{values.accessibility_statement === 'Exclude' && <i className='fas fa-times text-danger'></i>}
					</CheckWrapper>
				</PinWrapper>
			</Col>
		</Row>
	);
};

export default Accessibility;
