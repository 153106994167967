import { Survey } from 'components/shared/Surveys';

const Welcome = ({ owner }) => {
	return (
		<Survey.OwnerDialog owner={owner}>
			<h4 className='text-2xl font-semibold'>Let’s market your website!</h4>
			<p>As part of your website purchase, AppFolio Sites can generate a marketing overview for the homepage of your website.</p>
			<p>Please complete the following questions in as much detail as possible.</p>
			<p className='mb-0'>This survey should take 5-10 minutes. Let’s get started!</p>
		</Survey.OwnerDialog>
	);
};

export default Welcome;
