import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import styled from 'styled-components';
import { useLogin } from 'utils/UserContext';
import Main from './Main';
import Sidebar from './Sidebar';

const Component = styled.div`
	display: grid;
	grid-template-columns: 20rem 1fr;
	grid-template-areas: 'sidebar main';
	align-items: stretch;
	height: 100vh;

	@media (max-width: 767px) {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr auto;
		grid-template-areas:
			'main'
			'sidebar';
	}
`;

const ProtectedRoute = () => {
	// Hooks
	const { user } = useLogin();
	const location = useLocation();

	return user ? (
		<Routes>
			<Route
				path='*'
				element={
					<Component>
						<Sidebar />
						<Main />
					</Component>
				}></Route>
		</Routes>
	) : (
		<Navigate to='/login' replace state={{ from: location.pathname + location.search }} />
	);
};

export default ProtectedRoute;
