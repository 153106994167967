import { Panel } from 'components/shared/Panel';
import { Survey } from 'components/shared/Surveys';
import styled from 'styled-components';
import { tidyNestedFormResponse } from 'utils/Tidy';

const InfoPanel = styled(Panel)`
	border-left: 0.5rem solid var(--bs-primary);
`;

const Review = ({ formValues }) => {
	const survey = tidyNestedFormResponse(formValues);

	return (
		<Survey.Container>
			<h4 className='text-2xl font-semibold'>Expanded Survey Review</h4>
			<InfoPanel>
				<h5 className='text-lg font-semibold text-sky-600'>REVIEW:</h5>
				<div>
					Please review your responses below and hit <strong>Submit</strong> if everything looks correct.
				</div>
			</InfoPanel>
			<Survey.NestedReview survey={survey} />
		</Survey.Container>
	);
};

export default Review;
