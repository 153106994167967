import { Button } from 'react-bootstrap';
import styled from 'styled-components';

const LoadingIcon = styled.i`
	animation: spin 1s infinite;
`;

const LoadingButton = () => {
	return (
		<Button variant='primary' disabled={true}>
			<LoadingIcon className='fas fa-spinner'></LoadingIcon>
		</Button>
	);
};

export default LoadingButton;
