import { useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { Field, useFormikContext } from 'formik';
import { PinWrapper } from '../Pinned';
import BriefFieldsEnum from 'config/BriefFieldsEnum.json';
import { useMessage } from 'utils/MessageContext';

const ProjectDetails = ({ section, plan }) => {
	// Hooks
	const {
		setFieldValue,
		values: { company_name, final_approver, existing_website_url },
		dirty
	} = useFormikContext();
	const { setMessage } = useMessage();

	// Effects
	useEffect(() => {
		if (!company_name) {
			setFieldValue('company_name', plan.company_name);
		}
		if (!final_approver) {
			setFieldValue('final_approver', plan.company_contact);
		}
		if (!existing_website_url) {
			setFieldValue('existing_website_url', plan.website_url);
		}
	}, [company_name, final_approver, existing_website_url, setFieldValue, plan]);

	useEffect(() => {
		if (dirty) setMessage({ variant: 'warning', text: 'You have unsaved changes in this creative brief.', persist: true });
	}, [dirty, setMessage]);

	// Enum
	const { site_type_enum } = BriefFieldsEnum;

	return (
		<>
			<Row>
				<Col lg={6}>
					<PinWrapper section={section} name='company_name'>
						<Form.Label>Company Name</Form.Label>
						<Field as={Form.Control} type='text' name='company_name' />
					</PinWrapper>
				</Col>
				<Col lg={6}>
					<PinWrapper section={section} name='existing_website_url'>
						<Form.Label>Existing Website URL</Form.Label>
						<Field as={Form.Control} type='url' name='existing_website_url' />
					</PinWrapper>
				</Col>
			</Row>
			<Row>
				<Col lg={6}>
					<PinWrapper section={section} name='final_approver'>
						<Form.Label>Final Approver</Form.Label>
						<Field as={Form.Control} type='text' name='final_approver' />
					</PinWrapper>
				</Col>
				<Col lg={6}>
					<PinWrapper section={section} name='additional_approvers'>
						<Form.Label>Additional Approvers</Form.Label>
						<Field as={Form.Control} type='text' name='additional_approvers' />
					</PinWrapper>
				</Col>
			</Row>
			<Row>
				<Col lg={6}>
					<PinWrapper section={section} name='agreed_to_billing_date'>
						<Form.Label className='fw-bold'>Billing Date</Form.Label>
						<p>Once the preview site has been delivered, the site is in a billable stage and the customer will be billed on the 1st of the following month.</p>
						<Field as={Form.Check} type='checkbox' label='Yes, agreed to by customer' value='Yes, agreed to by customer' name='agreed_to_billing_date' id='agreed_to_billing_date' />
					</PinWrapper>
				</Col>
				<Col lg={6}>
					<PinWrapper section={section} name='site_type'>
						<Form.Label className='fw-bold'>Site Type</Form.Label>
						{site_type_enum.map(option => (
							<Field as={Form.Check} key={option} type='radio' name='site_type' label={option} value={option} id={`site-type-${option}`} />
						))}
					</PinWrapper>
				</Col>
			</Row>
		</>
	);
};

export default ProjectDetails;
