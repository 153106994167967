import dayjs from 'dayjs';
import Event from './Event';

const Events = ({ events, mutate }) => {
	// Props
	const upcomingEvents = events?.filter(event => dayjs(event.start_time).isAfter(dayjs())).sort((a, b) => b.start_time - a.start_time);
	const pastEvents = events?.filter(event => dayjs(event.start_time).isBefore(dayjs())).sort((a, b) => b.start_time - a.start_time);

	return (
		<div className='@container'>
			<div className='grid @3xl:grid-cols-2 gap-4'>
				<div className='space-y-2'>
					<h4 className='font-semibold text-sky-600'>Upcoming</h4>
					{upcomingEvents?.length > 0 ? (
						<ul className='space-y-4'>
							{upcomingEvents?.map(event => (
								<Event key={event.uri} event={event} mutate={mutate} />
							))}
						</ul>
					) : (
						<div>No upcoming events.</div>
					)}
				</div>
				<div className='space-y-2'>
					<h4 className='font-semibold text-sky-600'>Past</h4>
					{pastEvents?.length > 0 ? (
						<ul>
							{pastEvents?.map(event => (
								<Event key={event.uri} event={event} mutate={mutate} />
							))}
						</ul>
					) : (
						<div>No past events.</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Events;
