import { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { useParams, useNavigate, Link } from 'react-router-dom';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import Timeline from './Timeline/Timeline';
import WhatWeNeed from './WhatWeNeed';
import WhatWeAreDoing from './WhatWeAreDoing';
import DueDate from './DueDate';
import PlanStatus from 'components/shared/PlanStatus';
import { calculateDueDate, calculateStatus, calculateWaitingOn } from 'utils/Stages';
import { tidyUrl, tidyDate } from 'utils/Tidy';
import dayjs from 'dayjs';
import MultiSite from './MultiSite';
import useSWR from 'swr';
import styled from 'styled-components';
import { handleMissingImage } from 'utils/Errors';
import { getPreviewUrl } from 'utils/Sites';
import PageHeader from 'components/shared/PageHeader';
import Events from 'components/shared/Events/Events';
import logo from 'img/app/appfolio_logo.svg';
import MaterialsListView from 'components/shared/MaterialsList/MaterialsListView';
import { config } from 'config/config';

const Component = styled.main`
	max-width: 1280px;
	margin: 0 auto;
	padding: 1.5rem;
`;

const Logo = styled.img`
	width: 75px;

	@media (max-width: 767px) {
		width: 50px;
	}
`;

const Panel = styled.div`
	background: white;
	padding: 1.5rem;
	border-radius: 0.5rem;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
	display: grid;
	gap: 1rem;
	align-items: start;
`;

const HeaderDetails = styled.div`
	color: var(--bs-secondary);
	display: grid;
	gap: 0.25rem;
`;

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	margin: 2rem 0 0;
	align-items: start;

	@media (max-width: 767px) {
		grid-template-columns: 100%;
	}
`;

const Column = styled.div`
	display: grid;
	gap: 1rem;
`;

const Label = styled.div`
	font-weight: bold;
`;

const Value = styled.div`
	color: var(--bs-secondary);
`;

const Profile = styled.img`
	border-radius: 50%;
	height: 8rem;
	width: 8rem;
	margin: 0 auto 1rem;
`;

const DesignerPanel = styled(Panel)`
	display: grid;
	gap: 0.25rem;
	text-align: center;
`;

const View = () => {
	// State
	const [isLoaded, setIsLoaded] = useState(false);

	// Hooks
	const { id } = useParams();
	const navigate = useNavigate();
	const { data: jwt, error: jwtError } = useSWR('/auth/jwt', null, { revalidateOnFocus: true });
	const { data: plan, error: planError, mutate } = useSWR(jwt ? `/api/v1/plans/${id}` : null, null, { revalidateOnFocus: true });
	const { data: brief } = useSWR(jwt ? `/api/v1/briefs/${id}` : null, null, { revalidateOnFocus: true });
	const { data: designer, error: designerError } = useSWR(plan ? `/api/v1/users/email/${plan.appfolio_contact}` : null, null, { revalidateOnFocus: true });
	const { data: linkedPlan, error: linkedPlanError } = useSWR(plan?.linked_plan_id ? `/api/v1/plans/${plan.linked_plan_id}` : null, null, { revalidateOnFocus: true });

	useEffect(() => {
		if (jwt && plan && designer) {
			setIsLoaded(true);
		}
	}, [jwt, plan, designer]);

	useEffect(() => {
		if (jwtError || planError || designerError || linkedPlanError) {
			navigate('/error', { state: { from_public_page: true } });
		}
	}, [navigate, jwtError, planError, designerError, linkedPlanError]);

	// Destructure data
	const { company_name, company_contact, on_hold, stage, target_dates, actual_dates, site_id, website_url, appfolio_contact, number_of_sites, properties, linked_plan_id, product_tier, purpose, calendly, assets, assets_url } = { ...plan };
	const { events, is_enabled } = { ...calendly };
	const eventsWithPlanId = events?.map(event => ({ ...event, plan_id: plan._id }));
	const { start, live } = { ...target_dates };
	const { imageUrl, email, name, jobTitle, userLocation } = { ...designer };
	const { company_name: linked_company_name } = { ...linkedPlan };

	// Calculate things
	const dueDate = calculateDueDate(stage, target_dates);
	const status = calculateStatus(on_hold, stage, dueDate);
	const waitingOn = calculateWaitingOn(stage, company_contact, appfolio_contact);
	const currentBuildLength = dayjs(live).diff(dayjs(start), 'day');

	return (
		<>
			<Helmet>
				<title>{company_name || 'Company name'} | Project Plan</title>
			</Helmet>
			{isLoaded ? (
				<Component>
					<div className='flex items-center gap-4 mb-2'>
						<Logo src={logo} width='75' alt='AppFolio Logo' className='mb-3 mb-lg-0' />
						<PageHeader heading={company_name} subheading='AppFolio Sites' className='m-0' />
					</div>
					<HeaderDetails>
						{linked_plan_id && (
							<div>
								<i className='fas fa-link me-1'></i> View linked Project Plan for&nbsp;
								<Link to={`/view/${linked_plan_id}`} target='_blank' rel='noopener noreferrer' className='text-sky-600'>
									{linked_company_name}
								</Link>
							</div>
						)}
						{stage !== 'Welcome' && stage !== 'Collect' && (
							<>
								{site_id && (
									<div>
										<i className='fas fa-eye me-1'></i> Preview Site URL:&nbsp;
										<a href={getPreviewUrl(site_id)} target='_blank' rel='noopener noreferrer' className='text-sky-600'>
											{tidyUrl(getPreviewUrl(site_id))}
										</a>
									</div>
								)}
								{website_url && (
									<div>
										<i className='fas fa-desktop me-1'></i> Live Website URL:&nbsp;
										<a href={website_url} target='_blank' rel='noopener noreferrer' className='text-sky-600'>
											{tidyUrl(website_url)}
										</a>
									</div>
								)}
							</>
						)}
					</HeaderDetails>
					{number_of_sites === 'Multi-Site Pack' && <MultiSite properties={properties} />}
					<Wrapper>
						<Column style={{ alignSelf: 'start' }}>
							<Panel>
								<h4 className='text-2xl font-semibold'>Project Details</h4>
								<div>
									<Label>Project Status:</Label>
									<Value>
										{!on_hold ? (
											<PlanStatus stage={stage} dueDate={dueDate} showDelay={true} />
										) : (
											<>
												<i className='fas fa-exclamation-circle text-danger me-1'></i> On Hold
											</>
										)}
									</Value>
								</div>
								<div>
									<Label>Target Go-Live Date:</Label>
									<Value>{tidyDate(live)}</Value>
								</div>
								<div>
									<Label>Current Build Length:</Label>
									<Value>{currentBuildLength} days</Value>
								</div>
								{brief?.website_template && (
									<div>
										<Label>Template Name:</Label>
										<Value>{brief.website_template}</Value>
									</div>
								)}
								<div>
									<Label>Product Tier:</Label>
									<Value>{product_tier}</Value>
								</div>
								<div>
									<Label>Website Purpose:</Label>
									<Value>{purpose}</Value>
								</div>
								<div>
									<Label>Current Stage:</Label>
									<Value>{stage}</Value>
								</div>
								{stage !== 'Live' && (
									<div>
										<Label>Waiting On:</Label>
										<Value>{waitingOn}</Value>
									</div>
								)}
							</Panel>
							<DesignerPanel>
								<Profile src={imageUrl} alt={name} onError={({ currentTarget }) => handleMissingImage(currentTarget)} />
								<h5 className='text-xl font-semibold'>{name}</h5>
								<div>{jobTitle}</div>
								<Value>{userLocation}</Value>
								<a href={`mailto:${email}`} className='text-sky-600' target='_blank' rel='noopener noreferrer'>
									{email}
								</a>
							</DesignerPanel>
						</Column>
						<div>
							<Timeline target_dates={target_dates} actual_dates={actual_dates} stage={stage} dueDate={dueDate} status={status} />
						</div>
						<div className='bg-white rounded-lg shadow'>
							{!on_hold ? (
								<div className='divide-y divide-gray-100'>
									{is_enabled && (
										<div className='p-4 space-y-2'>
											<h4 className='text-2xl font-semibold'>Events:</h4>
											<Events events={eventsWithPlanId} mutate={mutate} />
										</div>
									)}
									<div className='p-4 space-y-2'>
										<h4 className='text-2xl font-semibold'>What we are doing:</h4>
										<WhatWeAreDoing stage={stage} />
									</div>
									<div className='p-4 space-y-2'>
										<h4 className='text-2xl font-semibold'>What we need:</h4>
										<WhatWeNeed plan={plan} designer={designer} showCalendly={true} brief={brief} mutate={mutate} />
									</div>
									{stage !== 'Welcome' && (
										<div className='p-4 space-y-2'>
											<h4 className='text-2xl font-semibold'>Materials list:</h4>
											<MaterialsListView assets_url={assets_url} assets={assets} />
										</div>
									)}
									<div className='p-4 space-y-2'>
										<h4 className='text-2xl font-semibold'>Due date:</h4>
										<DueDate stage={stage} due_date={dueDate} status={status} target_dates={target_dates} number_of_sites={number_of_sites} />
									</div>
								</div>
							) : (
								<div>
									<h5 className='mb-2 text-lg font-semibold'>
										<i className='fas fa-exclamation-circle text-danger me-1'></i> This project has been put on hold.
									</h5>
									<div>
										Please contact{' '}
										<a href={`mailto:${config.ONBOARDING_MANAGER_EMAIL}`} className='text-sky-600'>
											{config.ONBOARDING_MANAGER_EMAIL}
										</a>{' '}
										to continue the development of your website.
									</div>
								</div>
							)}
						</div>
					</Wrapper>
				</Component>
			) : (
				<LoadingOverlay />
			)}
		</>
	);
};

export default View;
