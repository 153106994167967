import PageHeader from 'components/shared/PageHeader';
import styled from 'styled-components';
import useSWR from 'swr';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import { Helmet } from 'react-helmet';
import { tidyUnderscores, tidyParagraphs, tidyDateTime } from 'utils/Tidy';
import logo from 'img/app/appfolio_logo.svg';
import { useEffect } from 'react';

const Body = styled.main`
	padding: 3rem;

	@media (max-width: 767px) {
		padding: 1rem;
	}
`;

const Component = styled.div`
	max-width: 1280px;
	margin: 0 auto;
`;

const Header = styled.div`
	display: flex;
	align-items: center;
	gap: 2rem;
	margin: 0 0 4rem;

	@media (max-width: 767px) {
		gap: 1rem;
		margin: 0 0 2rem;
	}
`;

const Logo = styled.img`
	width: 75px;

	@media (max-width: 767px) {
		width: 50px;
	}
`;

const PropertyList = styled.div`
	display: grid;
	gap: 1.5rem;
`;

const Property = styled.div`
	background: white;
	border-radius: 0.5rem;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
`;

const NoResults = styled(Property)`
	padding: 1rem;
	text-align: center;
`;

const Content = styled.div`
	display: grid;
	grid-template-columns: 12rem 1fr;
	gap: 1rem;
	border-top: 1px solid #eee;
	padding: 0.75rem 1rem;

	&:first-of-type {
		border: 0;
	}

	@media (max-width: 576px) {
		grid-template-columns: 100%;
		gap: 0;
	}
`;

const Name = styled.div`
	font-weight: bold;
	text-align: right;

	@media (max-width: 576px) {
		text-align: left;
	}
`;

const Value = styled.div``;

const Properties = () => {
	// Hooks
	const { id } = useParams();
	const navigate = useNavigate();
	const { data: jwt, error: jwtError } = useSWR('/auth/jwt');
	const { data: plan, error: planError } = useSWR(jwt ? `/api/v1/plans/${id}` : null);
	const { data: properties, error: propertiesError } = useSWR(jwt ? `/api/v1/properties/${id}` : null);
	const { company_name, updatedAt } = { ...plan };

	// Effects
	useEffect(() => {
		if (jwtError || planError || propertiesError) {
			navigate('/error', { state: { from_public_page: true } });
		}
	}, [jwtError, planError, propertiesError, navigate]);

	return (
		<Body>
			<Component>
				<Helmet>
					<title>Property Details | Project Plan Tool</title>
				</Helmet>
				<Header>
					<Logo src={logo} width='75' alt='AppFolio Logo' />
					<PageHeader heading={`Property Details - ${company_name}`} subheading={`Last updated ${tidyDateTime(updatedAt)}`} className='m-0' />
				</Header>
				{properties ? (
					properties.length > 0 ? (
						<PropertyList>
							{properties.map((property, i) => {
								const fields = Object.entries(property).filter(field => field[1]);
								return (
									<Property key={property._id}>
										{fields
											.filter(([name]) => name !== '_id')
											.map(([name, value]) => (
												<Content key={name}>
													<Name>{tidyUnderscores(name)}:</Name>
													<Value dangerouslySetInnerHTML={{ __html: tidyParagraphs(value) }} />
												</Content>
											))}
									</Property>
								);
							})}
						</PropertyList>
					) : (
						<NoResults>No property details to display.</NoResults>
					)
				) : (
					<LoadingOverlay />
				)}
			</Component>
		</Body>
	);
};

export default Properties;
