import { Row, Col } from 'react-bootstrap';
import TemplateImage from '../TemplateImage';

// Steps
import LogoColors from './LogoColors';
import HeroImage from './HeroImage';
import TextContent from './TextContent';
import PhotoGallery from './PhotoGallery';
import TeamPhotos from './TeamPhotos';
import FAQs from './FAQs';
import ContactInfo from './ContactInfo';

// Component map
const importedComponents = [LogoColors, HeroImage, TextContent, PhotoGallery, TeamPhotos, FAQs, ContactInfo];

const TemplateDesign = ({ handleChange, formResponse, currentStep, nextStep, prevStep }) => {
	// Adjust current step
	const adjustedStep = currentStep - 2;

	return (
		<>
			<h4 className='mb-3 text-2xl font-semibold'>Template & Design Choices</h4>
			<Row className='mt-4'>
				<Col lg={6} className='order-lg-1'>
					{importedComponents.map((Component, i) => adjustedStep === i + 1 && <Component key={i} nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} formResponse={formResponse} />)}
				</Col>
				<Col lg={6}>
					<TemplateImage currentStep={adjustedStep} />
				</Col>
			</Row>
		</>
	);
};

export default TemplateDesign;
