import { Survey } from 'components/shared/Surveys';
import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

// Screens
import Welcome from './Screens/1-Welcome';
import Content from './Screens/2-Content';
import TenantServices from './Screens/3-TenantServices';
import ManagementServices from './Screens/4-ManagementServices';
import RealEstate from './Screens/5-RealEstate';
import HOA from './Screens/6-HOA';
import StudentHousing from './Screens/7-StudentHousing';
import Commercial from './Screens/8-Commercial';
import Investment from './Screens/9-Investment';
import Review from './Screens/10-Review';
import Complete from './Screens/11-Complete';
import { fetcher } from 'utils/Fetch';
import { useMessage } from 'utils/MessageContext';

// Screens
const allScreens = [
	{
		component: Welcome,
		name: 'Welcome'
	},
	{
		component: Content,
		name: 'Content'
	},
	{
		component: TenantServices,
		name: 'Tenant Services',
		optional: true
	},
	{
		component: ManagementServices,
		name: 'Management Services',
		optional: true
	},
	{
		component: RealEstate,
		name: 'Real Estate',
		optional: true
	},
	{
		component: HOA,
		name: 'HOA',
		optional: true
	},
	{
		component: StudentHousing,
		name: 'Student Housing',
		optional: true
	},
	{
		component: Commercial,
		name: 'Commercial',
		optional: true
	},
	{
		component: Investment,
		name: 'Investment',
		optional: true
	},
	{
		component: Review,
		name: 'Review'
	},
	{
		component: Complete,
		name: 'Complete'
	}
];

const ContentSurvey = () => {
	// Component state
	const [screensToRender, setScreensToRender] = useState(allScreens);
	const [currentScreen, setCurrentScreen] = useState(1);
	const [allowToProceed, setAllowToProceed] = useState(false);
	const [submittedDate, setSubmittedDate] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	// Hooks
	const { setMessage } = useMessage();
	const navigate = useNavigate();
	const { id } = useParams();
	const [searchParams] = useSearchParams();

	const { data: jwt } = useSWR('/auth/jwt');
	const { data: plan } = useSWR(jwt ? `/api/v1/plans/${id}` : null);
	const { data: siteGenieDoc } = useSWR(jwt ? `/api/v1/site-genie/${id}` : null);

	const {
		formState: { errors, isValid },
		handleSubmit,
		register,
		watch,
		getValues
	} = useForm();

	// Search params
	const skipWelcome = searchParams.get('skipWelcome');
	if (currentScreen === 1 && skipWelcome === 'true') setCurrentScreen(2);

	// Destructure
	const { appfolio_contact } = { ...plan };
	const { data: owner } = useSWR(jwt && appfolio_contact ? `/api/v1/users/email/${appfolio_contact}` : null);

	// Handlers
	const handleFormSubmit = async values => {
		setIsLoading(true);
		try {
			const { expanded_survey } = await fetcher(`/api/v1/site-genie/expanded_survey/${id}`, { method: 'POST', body: JSON.stringify({ ...values, submitted: new Date() }) });
			setSubmittedDate(expanded_survey?.submitted);
			setCurrentScreen(screensToRender.length);
		} catch (error) {
			setMessage({ variant: 'danger', text: 'There was an error submitting your survey. Please try again.' });
		}
		setIsLoading(false);
	};

	// Form state
	const contentTypes = watch('Website Content.Which of the following types of content do you want to have on your website?');
	const formValues = getValues();

	// Effects
	useEffect(() => {
		if (siteGenieDoc?.expanded_survey?.submitted) {
			navigate('/expired', { state: { error: { message: 'This survey has already been submitted! A website onboarding specialist will be in touch with next steps.' } } });
		}
	}, [navigate, siteGenieDoc?.expanded_survey?.submitted]);

	useEffect(() => {
		if (contentTypes?.length > 0) {
			const screens = allScreens.filter(screen => !screen.optional || contentTypes?.includes(screen.name));
			setScreensToRender(screens);
		}
	}, [contentTypes]);

	useEffect(() => {
		isValid || currentScreen === 1 ? setAllowToProceed(true) : setAllowToProceed(false);
	}, [currentScreen, isValid]);

	return (
		<>
			<Helmet>
				<title>Expanded Survey | AppFolio Sites</title>
			</Helmet>
			<Survey.Form onSubmit={handleSubmit(handleFormSubmit)} currentScreen={currentScreen} setCurrentScreen={setCurrentScreen}>
				<Survey.Header heading='Expanded Survey' subheading='AppFolio Sites' />
				<Survey.Progress screens={screensToRender} currentScreen={currentScreen} />
				<Survey.Screens screens={screensToRender} currentScreen={currentScreen} owner={owner} register={register} formValues={formValues} submittedDate={submittedDate} />
				<Survey.Buttons screens={screensToRender} currentScreen={currentScreen} setCurrentScreen={setCurrentScreen} allowToProceed={allowToProceed} errors={errors} isLoading={isLoading} />
			</Survey.Form>
		</>
	);
};

export default ContentSurvey;
