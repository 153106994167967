import { useState, useEffect } from 'react';
import PageHeader from 'components/shared/PageHeader';
import { useParams, useNavigate } from 'react-router-dom';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import logo from 'img/app/appfolio_logo.svg';
import { tidyDateTime, tidyUnderscores, tidyCapitalize } from 'utils/Tidy';
import useSWR from 'swr';
import { renderValue } from 'utils/Render';
import BriefSections from 'config//BriefSections.json';
import BriefInitialValues from 'config//BriefInitialValues.json';

const Wrapper = styled.div`
	max-width: 1280px;
	margin: 0 auto;
`;

const Logo = styled.img`
	width: 75px;
	margin-right: 2rem;

	@media (max-width: 767px) {
		width: 50px;
		margin-right: 1rem;
	}
`;

const Section = styled.div`
	margin-block: 2rem;
`;

const Fields = styled.div``;

const Field = styled.div`
	background: white;
	box-shadow: rgb(0 0 0 / 8%) 0px 0.125rem 0.25rem;
	padding: 1rem 1.25rem;
	border-radius: 0.5rem;
	margin: 1rem 0px;
	display: grid;
	gap: 0.5rem;
`;

const Label = styled.div`
	font-weight: bold;
`;

const Value = styled.div`
	display: grid;
	gap: 0.5rem;
`;

const View = () => {
	// State
	const [isLoading, setIsLoading] = useState(true);
	const [sectionData, setSectionData] = useState([]);

	// Hooks
	const { id } = useParams();
	const { data: brief, error } = useSWR(`/api/v1/briefs/content/${id}`);
	const navigate = useNavigate();

	// Destructure data
	const { company_name, updatedAt } = { ...brief };

	// Effects
	useEffect(() => {
		if (brief) {
			// Map fields to sections
			const mappedSections = Object.entries(brief)
				.filter(([key, value]) => (Array.isArray(value) ? value.length > 0 : value ? true : false))
				.map(([key, value]) => ({ label: tidyCapitalize(tidyUnderscores(key)), key, value }))
				.filter(item => item.value !== BriefInitialValues[item.key])
				.reduce((total, current) => {
					const sectionObject = BriefSections.find(section => section.fields.includes(current.key));
					if (sectionObject) {
						const exists = total.find(section => section.label === sectionObject.label);
						if (exists) {
							exists.fields.push(current);
						} else {
							const sectionWithFields = { label: sectionObject.label, fields: [current] };
							total = [...total, sectionWithFields];
						}
					}
					return total;
				}, []);
			setSectionData(mappedSections);
			setIsLoading(false);
		}
	}, [brief]);

	useEffect(() => {
		if (error) {
			navigate('/error');
		}
	}, [error, navigate]);

	return !isLoading ? (
		brief && (
			<div className='p-3 p-md-5'>
				<Wrapper>
					<Helmet>
						<title>Creative Brief | {company_name}</title>
					</Helmet>
					<div className='d-flex align-items-start'>
						<Logo src={logo} width='75' alt='AppFolio Logo' className='mb-3 mb-lg-0' />
						<PageHeader heading={`Creative Brief - ${company_name}`} subheading={`Last updated ${tidyDateTime(updatedAt)}`} />
					</div>
					<div>
						{sectionData.map(section => (
							<Section key={section.label}>
								<h4 className='text-lg font-semibold'>{section.label}</h4>
								<Fields>
									{section.fields.map(field => (
										<Field key={field.key}>
											<Label>{field.label}</Label>
											<Value>{renderValue(field.value)}</Value>
										</Field>
									))}
								</Fields>
							</Section>
						))}
					</div>
				</Wrapper>
			</div>
		)
	) : (
		<LoadingOverlay />
	);
};

export default View;
