import { ChevronDown, CircleCheck, CircleX } from 'lucide-react';
import { useState } from 'react';
import { cn } from 'utils/Render';
import Markdown from 'react-markdown';

const MaterialsListViewRow = ({ asset }) => {
	// State
	const [isExpanded, setIsExpanded] = useState(false);

	return (
		<div className={cn('grid grid-cols-[auto_1fr] items-start gap-3', asset.complete ? 'line-through' : '')}>
			<div className='mt-1'>{asset.complete ? <CircleCheck size={16} className='text-success' /> : <CircleX size={16} className='text-danger' />}</div>
			<div>
				{asset.description ? (
					<button className='flex items-center' type='button' onClick={() => setIsExpanded(!isExpanded)}>
						<div className='break-all'>{asset.name}</div>
						<ChevronDown size={16} className='ml-2 text-slate-500' />
					</button>
				) : (
					<div className='break-all'>{asset.name}</div>
				)}
				{asset.description && isExpanded && (
					<Markdown
						className='mb-2 text-sm'
						components={{
							a: props => (
								<a {...props} className='text-sky-600' target='_blank' rel='noopener noreferrer'>
									{props.children}
								</a>
							)
						}}>
						{asset.description}
					</Markdown>
				)}
			</div>
		</div>
	);
};

export default MaterialsListViewRow;
