import styled from 'styled-components';

export const Section = styled.div`
	margin: 2rem 0;

	h4 {
		margin-left: 0.5rem;
	}
`;

export const Field = styled.div`
	background: white;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
	padding: 1rem 1.25rem;
	border-radius: 0.5rem;
	margin: 1rem 0;

	p {
		margin: 0.75rem 0;
	}
`;

export const Name = styled.div`
	font-weight: bold;
	margin-bottom: 0.5rem;
`;

export const Value = styled.div`
	p {
		margin: 0.5rem 0;
	}

	ul {
		margin: 0;

		li {
			margin: 0.25rem 0 0;
		}
	}
`;
