import Helmet from 'react-helmet';
import { Button } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { CircleAlert } from 'lucide-react';

export default function Error() {
	// Hooks
	const navigate = useNavigate();
	const location = useLocation();

	const { state } = { ...location };
	const { from_public_page } = { ...state };

	return (
		<>
			<Helmet>
				<title>Error | Project Plan Tool</title>
			</Helmet>
			<div className='flex-wrap p-3 p-md-5 d-flex justify-content-center align-items-center text-danger'>
				<div className='text-center'>
					<h1 className='flex items-center justify-center gap-3 text-6xl font-bold'>
						<CircleAlert className='w-16 h-16' /> Error
					</h1>
					<p className='my-4 text-secondary'>Sorry, looks like something went wrong. {from_public_page && `We have logged this error and are working on a fix.`}</p>
					{!from_public_page && (
						<Button variant={'secondary'} onClick={() => navigate('/')} className='mx-auto'>
							Go Home <i className='fas fa-home'></i>
						</Button>
					)}
				</div>
			</div>
		</>
	);
}
