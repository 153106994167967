import { Button } from 'react-bootstrap';
import { tidyDateTime } from 'utils/Tidy';

const Complete = ({ formResponse }) => {
	// Destructure props
	const { submitted } = formResponse;

	return (
		<>
			<h4 className='mb-3 text-2xl font-semibold'>Thank you!</h4>
			<p>
				Your community association survey response was submitted successfully on <strong>{tidyDateTime(submitted)}.</strong>
			</p>
			<p>Your AppFolio Website Designer will be in contact with you shortly to schedule a project kickoff call.</p>
			<Button variant='primary' className='mt-3' onClick={() => window.close()}>
				Close window <i className='fas fa-times-circle'></i>
			</Button>
		</>
	);
};

export default Complete;
