import Events from 'components/shared/Events/Events';
import { Field } from 'formik';
import { TriangleAlert } from 'lucide-react';
import { Form } from 'react-bootstrap';

const Scheduling = ({ plan, mutate }) => {
	// Props
	const { calendly } = { ...plan };
	const { events, is_enabled, booking_url } = { ...calendly };
	const eventsWithPlanId = events?.map(event => ({ ...event, plan_id: plan._id }));

	return (
		<div className='grid items-start gap-4 sm:grid-cols-2'>
			<div className='space-y-4'>
				<div className='p-3 bg-gray-200 rounded-lg'>
					<Field as={Form.Switch} id='calendly-enabled-switch' label='Enable Calendly scheduling for this project' className='m-0' checked={is_enabled} name='calendly.is_enabled' />
				</div>
				{is_enabled && (
					<div className='space-y-2'>
						<div>
							<Form.Label>
								Calendly Event Booking URL <span className='text-red-600'>*</span>
							</Form.Label>
							<Field as={Form.Control} name='calendly.booking_url' type='url' required />
						</div>
						{!booking_url && (
							<div className='flex items-center gap-2 text-sm text-red-600'>
								<TriangleAlert className='w-4 h-4' />
								<div>A Calendly Event Booking URL is required to enable Calendly scheduling.</div>
							</div>
						)}
					</div>
				)}
			</div>
			{is_enabled && <Events events={eventsWithPlanId} mutate={mutate} />}
		</div>
	);
};

export default Scheduling;
