import { Survey } from 'components/shared/Surveys';
import { tidyDateTime } from 'utils/Tidy';

const Complete = ({ owner, submittedDate }) => {
	return (
		<Survey.OwnerDialog owner={owner}>
			<h4 className='font-semibold text-2xl'>Thank you!</h4>
			<p>
				Your Expanded Survey was submitted successfully on <strong>{tidyDateTime(submittedDate)}.</strong>
			</p>
			<p className='mb-0'>I will be in contact with you shortly to schedule a project kickoff call. You can now close this window.</p>
		</Survey.OwnerDialog>
	);
};

export default Complete;
