export const fetcher = async (...args) => {
	const [url, options] = args;
	try {
		const response = await fetch(url, {
			...options,
			headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...options?.headers }
		});
		const data = await response.json();
		if (!response.ok) {
			throw data;
		}
		return data;
	} catch (error) {
		const { statusText, message } = error;
		const errorText = statusText ?? message;
		if (errorText === 'Unauthorized') {
			window.location = `/login?error=${errorText}`;
		}
		throw error;
	}
};
