import { Calendar, CircleCheck, CircleX, ExternalLink } from 'lucide-react';
import { PopupModal, useCalendlyEventListener } from 'react-calendly';
import { useState } from 'react';
import { fetcher } from 'utils/Fetch';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { cn } from 'utils/Render';

const scheduleEventButtonStatus = (calendly, stage, showCalendly, designer) => {
	const { booking_url, is_enabled, events } = { ...calendly };
	if (!booking_url || !is_enabled) return false;

	if (!showCalendly) return false;
	if (stage !== 'Welcome' && stage !== 'Review') return false;

	if (!designer?.calendly?.access_token) return false;

	const kickOffEvent = events?.find(event => event.event_name === 'Kick Off Call' && event.status === 'active');
	const reviewEvent = events?.find(event => event.event_name === 'Review Call' && event.status === 'active');

	if ((stage === 'Welcome' && !kickOffEvent) || (stage === 'Review' && !reviewEvent)) return true;
	return false;
};

const WhatWeNeed = ({ plan, designer, showCalendly, brief, mutate }) => {
	// Props
	const { stage, calendly, _id } = { ...plan };
	const { booking_url } = { ...calendly };

	// State
	const [calendlyModalIsOpen, setCalendlyModalIsOpen] = useState(false);
	const showScheduleEventButton = scheduleEventButtonStatus(calendly, stage, showCalendly, designer);

	// Welcome stages
	const briefExists = brief && Object.keys(brief).length > 0;
	const briefComplete = briefExists && brief.website_template !== '';
	const stage1 = briefExists && !briefComplete;
	const stage2 = briefComplete && showScheduleEventButton;
	const stage3 = calendly.events.length > 0;

	let welcomeSteps = [
		{ text: 'Establish a project timeline', completed: true },
		{ text: 'Complete kickoff survey', completed: briefComplete },
		{ text: 'Schedule a project kickoff call', completed: calendly.events.length > 0 }
	];

	if (!briefExists) welcomeSteps = welcomeSteps.filter(step => step.text !== 'Complete kickoff survey');

	// Hooks
	useCalendlyEventListener({ onEventScheduled: e => handleEventSchedule(e) });

	// Handlers
	const handleEventSchedule = async e => {
		// Determine event
		let event_name = 'Kick Off Call';
		if (stage === 'Review') event_name = 'Review Call';

		try {
			// Submit
			const body = { access_token: designer.calendly.access_token, event_uri: e.data.payload.event.uri, event_name };
			await fetcher(`/api/v1/plans/${plan._id}/events`, { method: 'POST', body: JSON.stringify(body) });
			await mutate();
		} catch (error) {
			setCalendlyModalIsOpen(false);
		}
	};

	switch (stage) {
		case 'Welcome':
			return (
				<div className='space-y-4'>
					<div className='space-y-1'>
						{welcomeSteps.map(step => (
							<div key={step.text} className={cn('flex items-center gap-2', step.completed ? 'line-through' : '')}>
								{step.completed ? <CircleCheck size={16} className='text-success' /> : <CircleX size={16} className='text-danger' />}
								<span>{step.text}</span>
							</div>
						))}
					</div>
					{stage1 && (
						<Button as={Link} to={`/brief/${_id}/survey`} className='inline-flex items-center justify-center gap-2' target='_blank' rel='noopener noreferrer'>
							<div>Begin Kickoff Survey</div>
							<ExternalLink size={16} />
						</Button>
					)}
					{stage2 && (
						<>
							{calendlyModalIsOpen && <PopupModal url={booking_url} onModalClose={() => setCalendlyModalIsOpen(false)} open={calendlyModalIsOpen} rootElement={document.getElementById('root')} />}
							<Button onClick={() => setCalendlyModalIsOpen(true)} className='inline-flex items-center justify-center gap-2'>
								<div>Schedule your project kickoff call</div>
								<Calendar size={16} />
							</Button>
						</>
					)}
					{stage3 && <div>Please refer to the materials list and due date below.</div>}
				</div>
			);

		case 'Collect':
			return <div>Please refer to the materials list and due date below.</div>;

		case 'Build':
			return <div>Please refer to the materials list and due date below.</div>;

		case 'Review':
			return (
				<>
					<PopupModal url={booking_url} onModalClose={() => setCalendlyModalIsOpen(false)} open={calendlyModalIsOpen} rootElement={document.getElementById('root')} />
					<div className='space-y-4'>
						{showScheduleEventButton && (
							<button onClick={() => setCalendlyModalIsOpen(true)} className='flex items-center gap-2 px-3 py-2 text-white duration-100 rounded bg-sky-600 hover:bg-sky-700'>
								<div>Schedule your project review call</div>
								<Calendar className='w-5 h-5 text-sky-200' />
							</button>
						)}
						<div>Please refer to the materials list and due date below.</div>
					</div>
				</>
			);

		case 'Live':
			return <p>Nothing, your site is live!</p>;

		default:
			return null;
	}
};

export default WhatWeNeed;
