import { Button, Form, Row, Col } from 'react-bootstrap';
import { ButtonGroup } from '../Components';
import { useFormikContext, Field } from 'formik';
import BriefInitialValues from 'config//BriefInitialValues.json';
import TextareaAutosize from 'react-textarea-autosize';

const WebsiteInfo = ({ handleNextStep, handlePrevStep }) => {
	// Hooks
	const { values: formState } = useFormikContext();

	// Proceed
	const { contact_details } = BriefInitialValues;
	const allowToProceed = formState.contact_details !== contact_details;

	return (
		<>
			<h4 className='font-semibold text-2xl'>Website Info</h4>
			<Row>
				<Col lg={6}>
					<Form.Group className='mb-3'>
						<Form.Label>
							Contact Details <span className='text-danger fw-bold'>*</span>
						</Form.Label>
						<Field as={TextareaAutosize} className='form-control' minRows={3} name='contact_details' />
						<Form.Text>Please only list the details that you would like to be public on the website.</Form.Text>
					</Form.Group>
				</Col>
				<Col lg={6}>
					<Form.Group className='mb-3'>
						<Form.Label>Social Media Accounts</Form.Label>
						<Field as={TextareaAutosize} className='form-control' minRows={3} name='social_media_accounts' />
						<Form.Text>Optional links to your social media accounts (Facebook, Instagram, etc.)</Form.Text>
					</Form.Group>
				</Col>
			</Row>
			<Row>
				<Col lg={6}>
					<Form.Group className='mb-3'>
						<Form.Label>Contact Form Notification Emails</Form.Label>
						<Field as={TextareaAutosize} className='form-control' minRows={3} name='contact_form_notification_emails' />
						<Form.Text>Email address(es) to be the recipients of general inquiries via the website.</Form.Text>
					</Form.Group>
				</Col>
				<Col lg={6}>
					<Form.Group className='mb-3'>
						<Form.Label>Associations</Form.Label>
						<Field as={TextareaAutosize} className='form-control' minRows={3} name='associations' />
						<Form.Text>Optional local or national associations you or your company belongs to.</Form.Text>
					</Form.Group>
				</Col>
			</Row>
			<ButtonGroup>
				<Button variant='secondary' type='button' onClick={() => handlePrevStep()}>
					<i className='fas fa-angle-left'></i> Previous
				</Button>
				<Button variant='primary' type='button' onClick={() => handleNextStep()} disabled={allowToProceed ? false : true}>
					Next <i className='fas fa-angle-right'></i>
				</Button>
			</ButtonGroup>
		</>
	);
};

export default WebsiteInfo;
