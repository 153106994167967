import styled from 'styled-components';

import { tidyParagraphs } from 'utils/Tidy';

const Component = styled.div`
	background: white;
	border-radius: 0.5em;
	overflow: hidden;
	box-shadow: 0 0.125em 0.25em rgb(0 0 0 / 8%);
	font-size: ${({ $size }) => ($size === 'large' ? '16px' : '12px')};
	pointer-events: none;

	@media (max-width: 767px) {
		display: none;
	}
`;

const Toolbar = styled.div`
	background: var(--darkest);
	height: 2em;
	display: flex;
	align-items: center;
	padding: 0 0.5em;
	gap: 0.5em;
`;

const Icon = styled.div`
	height: 1em;
	width: 1em;
	border-radius: 50%;
	background: ${({ $color }) => `var(--bs-${$color})`};
`;

const Window = styled.div`
	position: relative;
`;

const Menu = styled.div`
	display: flex;
	align-items: center;
	padding: 1em;
	justify-content: space-between;
	box-shadow: 0 2px 5px #0003;
	position: relative;
	z-index: 1;
`;

const Name = styled.div`
	font-weight: bold;
	font-size: 1.5em;

	i {
		color: var(--bs-secondary);
		margin-right: 0.5em;
	}
`;

const Title = styled.div`
	font-size: 1.25em;
	font-weight: bold;
`;

const Box = styled.div`
	border: 1px solid;
	padding: 0.25em 1.5em;
	display: inline-block;
`;

const Hero = styled.section`
	padding: 8em 2em;
	background: linear-gradient(#fffa, #fffa), url('https://lirp.cdn-website.com/07d24258/dms3rep/multi/opt/Living-room-0d7bf9ef-720w.jpg') center/cover;

	${Name} {
		font-size: 2em;
		margin-bottom: 0.5em;
	}
`;

const Features = styled.section`
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	background: #fafafa;
`;

const Feature = styled.div`
	padding: 3em 1em;
	display: grid;
	place-items: center;

	i {
		font-size: 1.5em;
		margin-bottom: 0.75em;
	}
`;

const Section = styled.section`
	text-align: center;
	padding: 5em;
`;

const Description = styled.div`
	margin-top: 1em;
`;

const Amenities = styled.section`
	display: flex;
	background: var(--darkest);
	color: white;

	> * {
		flex: 1 1 auto;
	}
`;

const AmenityText = styled.div`
	padding: 2em;

	${Title} {
		margin: 0.75em 0 0.25rem;

		&:first-of-type {
			margin: 0;
		}
	}
`;

const AmenityImage = styled.div`
	background: url('https://lirp.cdn-website.com/md/unsplash/dms3rep/multi/opt/photo-1484154218962-a197022b5858-720w.jpg') center/cover;
	width: 20em;
`;

const Terms = styled.section`
	display: grid;
	grid-template-columns: repeat(2, auto);
	align-items: center;
	justify-content: center;
	padding: 3em;
	gap: 2em;
	line-height: 1.75;
`;

const Contact = styled.section`
	padding: 3em 1em;
	background: #fafafa;
	display: flex;
	justify-content: space-around;
`;

const ContactTile = styled.div`
	text-align: center;

	i {
		color: var(--bs-secondary);
		font-size: 1em;
		margin-bottom: 0.5em;
	}
`;

const Example = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotate(-30deg);
	font-size: 10em;
	font-weight: bold;
	color: var(--bs-primary);
	opacity: 0.25;
	z-index: 1;
	text-align: center;
	line-height: 1;
`;

const Preview = ({ formResponse, size = 'small' }) => {
	const { property_name, address, phone, email, bedroom_range, bathroom_range, rent_range, property_description, property_features, onsite_services, apartment_features, neighborhood_details, security_deposit, application_fee, pet_policy, other_fees, social_media } = { ...formResponse };

	return (
		<Component $size={size}>
			<Toolbar>
				<Icon $color='danger'></Icon>
				<Icon $color='warning'></Icon>
				<Icon $color='success'></Icon>
			</Toolbar>
			<Window>
				{size === 'large' && (
					<Example>
						EXAMPLE
						<br />
						ONLY
					</Example>
				)}
				<Menu>
					<i className='fas fa-bars'></i>
					{property_name && (
						<Name>
							<i className='fas fa-city'></i> {property_name}
						</Name>
					)}
					<Box>Pay Rent</Box>
				</Menu>
				<Hero>
					{property_name && (
						<>
							<Name>{property_name}</Name>
							<Box>Availability</Box>
						</>
					)}
				</Hero>
				{(bedroom_range || bathroom_range || rent_range) && (
					<Features>
						{bedroom_range && (
							<Feature>
								<i className='fas fa-bed'></i>
								<div>{bedroom_range} beds</div>
							</Feature>
						)}
						{bathroom_range && (
							<Feature>
								<i className='fas fa-bath'></i>
								<div>{bathroom_range} baths</div>
							</Feature>
						)}
						{rent_range && (
							<Feature>
								<i className='fas fa-house-user'></i>
								<div>{rent_range}/month</div>
							</Feature>
						)}
					</Features>
				)}
				{(property_name || property_description) && (
					<Section>
						{property_name && <Name>Welcome to {property_name}</Name>}
						{property_description && <Description dangerouslySetInnerHTML={{ __html: tidyParagraphs(property_description) }} />}
					</Section>
				)}
				{(property_features || onsite_services || apartment_features || neighborhood_details) && (
					<Amenities>
						<AmenityText>
							{property_features && (
								<>
									<Title>Property Features</Title>
									<div dangerouslySetInnerHTML={{ __html: tidyParagraphs(property_features) }} />
								</>
							)}
							{onsite_services && (
								<>
									<Title>On-Site Services</Title>
									<div dangerouslySetInnerHTML={{ __html: tidyParagraphs(onsite_services) }} />
								</>
							)}
							{apartment_features && (
								<>
									<Title>Apartment Features</Title>
									<div dangerouslySetInnerHTML={{ __html: tidyParagraphs(apartment_features) }} />
								</>
							)}
						</AmenityText>
						{neighborhood_details && (
							<AmenityText>
								<Title>Neighborhood</Title>
								<div dangerouslySetInnerHTML={{ __html: tidyParagraphs(neighborhood_details) }} />
							</AmenityText>
						)}
						<AmenityImage />
					</Amenities>
				)}
				{(application_fee || security_deposit || pet_policy || other_fees) && (
					<Terms>
						<Title>Terms</Title>
						<div>
							{application_fee && (
								<div>
									<strong>APPLICATION FEE:</strong> {application_fee}
								</div>
							)}
							{security_deposit && (
								<div>
									<strong>SECURITY DEPOSIT:</strong> {security_deposit}
								</div>
							)}
							{pet_policy && (
								<div>
									<strong>PET POLICY:</strong> {pet_policy}
								</div>
							)}
							{other_fees && (
								<div>
									<strong>OTHER FEES:</strong> {other_fees}
								</div>
							)}
						</div>
					</Terms>
				)}
				{(address || phone || email || social_media) && (
					<Contact>
						{address && (
							<ContactTile>
								<i className='fas fa-map-marker-alt'></i>
								<div dangerouslySetInnerHTML={{ __html: tidyParagraphs(address) }} />
							</ContactTile>
						)}
						{phone && (
							<ContactTile>
								<i className='fas fa-mobile-alt'></i>
								<div>{phone}</div>
							</ContactTile>
						)}
						{email && (
							<ContactTile>
								<i className='fas fa-envelope'></i>
								<div>{email}</div>
							</ContactTile>
						)}
						{social_media && (
							<ContactTile>
								<i className='fas fa-at'></i>
								<div dangerouslySetInnerHTML={{ __html: tidyParagraphs(social_media) }} />
							</ContactTile>
						)}
					</Contact>
				)}
			</Window>
		</Component>
	);
};

export default Preview;
