import { useState } from 'react';
import { Form } from 'react-bootstrap';

const PropertyDetails = ({ handleChange, formResponse }) => {
	// State
	const [showAmenities, setShowAmenities] = useState(true);
	const [showNeighborhood, setShowNeighborhood] = useState(true);

	return (
		<>
			<h4 className='font-semibold text-2xl'>Floorplan Details</h4>
			<Form.Group className='mb-3'>
				<Form.Label>
					Bedroom Range <span className='text-danger font-bold'>*</span>
				</Form.Label>
				<Form.Control onChange={e => handleChange(e)} name='bedroom_range' value={formResponse.bedroom_range} required />
			</Form.Group>
			<Form.Group className='mb-3'>
				<Form.Label>
					Bathroom Range <span className='text-danger font-bold'>*</span>
				</Form.Label>
				<Form.Control onChange={e => handleChange(e)} name='bathroom_range' value={formResponse.bathroom_range} required />
			</Form.Group>
			<Form.Group className='mb-3'>
				<Form.Label>
					Rent/Month Range <span className='text-danger font-bold'>*</span>
				</Form.Label>
				<Form.Control onChange={e => handleChange(e)} name='rent_range' value={formResponse.rent_range} required />
			</Form.Group>
			<Form.Group className='mt-4'>
				<Form.Label className='m-0'>
					Property Description <span className='text-danger font-bold'>*</span>
				</Form.Label>
				<Form.Text>Why should prospective tenants want to live at this property?</Form.Text>
				<Form.Control as='textarea' rows={5} className='allow-resize' onChange={e => handleChange(e)} name='property_description' value={formResponse.property_description} />
			</Form.Group>
			<Form.Group className='mt-4'>
				<Form.Label>Are there any property amenities you would like to highlight?</Form.Label>
				<Form.Check type='radio' id='show-amenities' label='Yes, display the following amenities on my website.' checked={showAmenities} onChange={() => setShowAmenities(true)} />
				<Form.Check type='radio' id='hide-amenities' label='None for now, maybe later.' checked={!showAmenities} onChange={() => setShowAmenities(false)} />
				{showAmenities && (
					<>
						<Form.Group className='mt-3'>
							<Form.Label>Property Features</Form.Label>
							<Form.Control as='textarea' rows={5} className='allow-resize' onChange={e => handleChange(e)} name='property_features' value={formResponse.property_features} />
						</Form.Group>
						<Form.Group className='mt-3'>
							<Form.Label>On-Site Services</Form.Label>
							<Form.Control as='textarea' rows={5} className='allow-resize' onChange={e => handleChange(e)} name='onsite_services' value={formResponse.onsite_services} />
						</Form.Group>
						<Form.Group className='mt-3'>
							<Form.Label>Apartment Features</Form.Label>
							<Form.Control as='textarea' rows={5} className='allow-resize' onChange={e => handleChange(e)} name='apartment_features' value={formResponse.apartment_features} />
						</Form.Group>
					</>
				)}
			</Form.Group>
			<Form.Group className='mt-4'>
				<Form.Label>Are there any neighborhood/area details you would like to highlight?</Form.Label>
				<Form.Check type='radio' id='show-neighborhood' label='Yes, show site site visitors why our neighborhood is the perfect place to call home.' checked={showNeighborhood} onChange={() => setShowNeighborhood(true)} />
				<Form.Check type='radio' id='hide-neighborhood' label='None for now, maybe later.' checked={!showNeighborhood} onChange={() => setShowNeighborhood(false)} />
				{showNeighborhood && (
					<Form.Group className='mt-3'>
						<Form.Label>Neighborhood Details</Form.Label>
						<Form.Control as='textarea' rows={5} className='allow-resize' onChange={e => handleChange(e)} name='neighborhood_details' value={formResponse.neighborhood_details} />
					</Form.Group>
				)}
			</Form.Group>
		</>
	);
};

export default PropertyDetails;
