import { Survey } from 'components/shared/Surveys';
import { FormCheck, FormLabel, FormControl } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';

const ManagementServices = ({ register }) => {
	// Form state
	const checkboxes = ['Leasing', 'Screening', 'Marketing', 'Property Maintenance', 'Property Inspections', 'Rent Collection', 'Tenant Relations', 'Accounting/Financials', 'Owner Reports', 'Real Estate', 'Development', 'Investment', 'Property Turnover'];

	return (
		<Survey.Container>
			<h4 className='font-semibold text-2xl'>Management Services</h4>
			<div>
				<div className='mb-3'>
					Which of the following management services do you provide? <strong className='text-danger'>*</strong>
				</div>
				{checkboxes.map(checkbox => (
					<FormCheck key={checkbox} id={checkbox}>
						<FormCheck.Input type='checkbox' value={checkbox} {...register('Management Services.Which of the following management services do you provide?', { required: true })} />
						<FormCheck.Label>{checkbox}</FormCheck.Label>
					</FormCheck>
				))}
			</div>
			<div>
				<FormLabel>
					What sets you apart from other property managers? <strong className='text-danger'>*</strong>
				</FormLabel>
				<FormControl as={TextareaAutosize} minRows={5} {...register('Management Services.What sets you apart from other property managers?', { required: true })} />
			</div>
			<div>
				<FormLabel>
					What types of properties do you manage? <strong className='text-danger'>*</strong>
				</FormLabel>
				<FormControl as={TextareaAutosize} minRows={5} {...register('Management Services.What types of properties do you manage?', { required: true })} />
			</div>
		</Survey.Container>
	);
};

export default ManagementServices;
