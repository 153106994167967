import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { ButtonGroup } from '../Components';

const Component = styled.div`
	background: white;
	box-shadow: rgb(0 0 0 / 8%) 0px 0.125rem 0.25rem;
	padding: 1.5rem;
	border-radius: 0.5rem;

	> *:last-child {
		margin-bottom: 0;
	}
`;

const Welcome = ({ nextStep }) => {
	return (
		<>
			<Component>
				<h4 className='text-2xl font-semibold'>Welcome!</h4>
				<p>We're excited to build a new website for your community association. To get started, please read through the notes below and complete this survey. This should take 10 - 15 minutes. If you have questions, please note them on the last page and we can discuss these during our kick-off call.</p>
				<p>
					We'll be starting with a layout that looks like{' '}
					<a href='https://dashboard.appfoliowebsites.com/preview/f6b4b30d/' target='_blank' rel='noopener noreferrer' className='text-sky-600'>
						this template.
					</a>
				</p>
				<p>This website layout is specifically designed for community associations and has space for all the content you'll need. Our project will be to customize the content, colors, and photos to match your needs. This survey will help us get on the same page right from the start. </p>
				<p>On the next page, you'll see the entire home page layout, with numbers highlighting important sections. The survey steps will guide you through customizing these sections.</p>
				<p>After you complete this survey, we'll reach out to schedule a project kickoff call, review your responses and discuss any additional questions you have. Then we can begin building the website!</p>
			</Component>
			<ButtonGroup>
				<Button variant='primary' onClick={() => nextStep()} className='m-1'>
					Get started <i className='fas fa-angle-right'></i>
				</Button>
			</ButtonGroup>
		</>
	);
};

export default Welcome;
