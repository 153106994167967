import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Form, Row, Col } from 'react-bootstrap';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import PageHeader from 'components/shared/PageHeader';
import { useLogin } from 'utils/UserContext';
import { Typeahead, Highlighter } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import DataTable from 'components/shared/DataTable';

const MyBriefsSurveys = () => {
	// Component state
	const [typeahead, setTypeahead] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	// Hooks
	const { user } = useLogin();
	const navigate = useNavigate();
	const { data: briefs } = useSWR(`/api/v1/briefs/user/${user.email}`);
	const { data: surveys } = useSWR(`/api/v1/associations/survey/user/${user.email}`);

	// Effects
	useEffect(() => {
		if (briefs && surveys) {
			const allBriefs = briefs.map(({ _id, company_name, submitted }) => ({ _id, company_name, submitted: submitted?.koc_survey, type: 'brief' }));
			const allSurveys = surveys.map(({ _id, company_name, submitted }) => ({ _id, company_name, submitted, type: 'survey' }));
			setTypeahead([...allBriefs, ...allSurveys]);
			setIsLoading(false);
		}
	}, [briefs, surveys]);

	// Go to brief
	const goToBrief = e => {
		const selected = e[0];
		const selectedItem = typeahead.find(brief => brief._id === selected._id);
		navigate({ pathname: `/${selectedItem.type}/${selectedItem._id}` });
	};

	return (
		<>
			<Helmet>
				<title>My Briefs & Surveys | Project Plan Tool</title>
			</Helmet>
			<PageHeader heading={'My Briefs & Surveys'} subheading={'View all of my creative briefs & survey responses.'} />
			<Row className='mb-4'>
				<Col xl={6}>
					<Form>
						<Form.Group className='mb-3'>
							<Typeahead
								className='border-0 shadow-sm'
								id='company-search'
								labelKey='company_name'
								filterBy={['company_name']}
								onChange={e => goToBrief(e)}
								options={typeahead}
								renderMenuItemChildren={(option, props) => (
									<>
										<Highlighter search={props.text}>{option.company_name}</Highlighter>
										<div>
											<small className='text-secondary'>{option.type === 'brief' ? 'Creative Brief' : 'Association Survey'}</small>
										</div>
									</>
								)}
								placeholder='Search for a company...'
								minLength={2}
							/>
						</Form.Group>
					</Form>
				</Col>
			</Row>
			{!isLoading ? (
				<>
					<div className='my-5'>
						<h4 className='mb-2 text-2xl font-semibold'>Creative Briefs</h4>
						<DataTable data={briefs} type='brief' />
					</div>
					<div className='my-5'>
						<h4 className='mb-2 text-2xl font-semibold'>Association Surveys</h4>
						<DataTable data={surveys} type='association' />
					</div>
				</>
			) : (
				<LoadingOverlay />
			)}
		</>
	);
};

export default MyBriefsSurveys;
