import Preview from '../Preview';
import styled from 'styled-components';

const Important = styled.div`
	background: white;
	margin: 1rem 0 1.5rem;
	padding: 1rem;
	font-size: 0.9rem;
	box-shadow: inset 3px 0 0 0 var(--bs-danger)}, 0 0.125rem 0.25rem rgb(0 0 0 / 8%);

	h5 {
		color: var(--bs-danger);
	}

	p:last-child {
		margin-bottom: 0;
	}
`;

const Review = ({ formResponse }) => {
	return (
		<>
			<h4 className='text-2xl font-semibold'>Review</h4>
			<p className='text-secondary'>Please take your time to review the details in this example and confirm that everything about your property looks correct.</p>
			<Important>
				<h5 className='mb-2 text-lg font-semibold'>This is an example only.</h5>
				<p>The example below is based on a generic website template. We will take your property information and insert it into your chosen template, which may look different than this template.</p>
			</Important>
			<Preview formResponse={formResponse} size='large' />
		</>
	);
};

export default Review;
