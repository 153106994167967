import { useEffect, useState } from 'react';
import { fetcher } from 'utils/Fetch';
import { Calendar, CalendarCheck, CalendarX, RefreshCcw, X } from 'lucide-react';
import { cn } from 'utils/Render';
import { tidyDateTime, tidyUrl } from 'utils/Tidy';
import dayjs from 'dayjs';

const EventIcon = ({ isActive, start_time }) => {
	// If event is upcoming
	if (dayjs(start_time).isAfter(dayjs())) {
		if (isActive) return <Calendar className='w-5 h-5 mt-1 text-gray-500' />;
		return <CalendarX className='w-5 h-5 mt-1 text-gray-500' />;
	}
	// If event is past
	return <CalendarCheck className='w-5 h-5 mt-1 text-gray-500' />;
};

const Event = ({ event, mutate }) => {
	// Props
	const { uri, event_name, start_time, location, status, plan_id, access_token } = event;

	// State
	const [isRefreshing, setIsRefreshing] = useState(false);
	const isActive = status === 'active';

	// Handlers
	const handleRefreshEventData = async () => {
		setIsRefreshing(true);
		try {
			const body = { plan_id, uri, access_token };
			await fetcher(`/api/v1/events/refresh`, { method: 'POST', body: JSON.stringify(body) });
			await mutate();
		} catch (error) {}
		setIsRefreshing(false);
	};

	const handleRemoveEvent = async () => {
		try {
			const body = { plan_id, uri };
			await fetcher(`/api/v1/events`, { method: 'DELETE', body: JSON.stringify(body) });
			await mutate();
		} catch (error) {}
	};

	// Effects
	useEffect(() => {
		handleRefreshEventData();
		// eslint-disable-next-line
	}, []);

	return (
		<li key={uri} className='leading-5'>
			<div className='flex items-start gap-2'>
				<EventIcon isActive={isActive} start_time={start_time} />
				<div>
					<div className='flex items-center gap-2'>
						<div className={status !== 'active' ? 'line-through' : ''}>
							{event_name} - <strong>{tidyDateTime(start_time)}</strong>{' '}
						</div>
						{isActive ? (
							<div className={cn('text-gray-500', isRefreshing ? 'animate-spin' : '')}>
								<RefreshCcw className='w-4 h-4 ' />
							</div>
						) : (
							<button className='text-gray-600 duration-100 hover:text-red-600' title='Remove event' onClick={() => handleRemoveEvent()}>
								<X className='w-4 h-4' />
							</button>
						)}
					</div>
					{location.join_url && isActive ? (
						<a className='text-sm text-sky-600' href={location.join_url} target='_blank' rel='noopener noreferrer'>
							{tidyUrl(location.join_url)}
						</a>
					) : (
						<div className='text-gray-500'>-</div>
					)}
				</div>
			</div>
		</li>
	);
};

export default Event;
