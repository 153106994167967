import styled from 'styled-components';
import { Collapse, Section, Toggle } from './Components';
import { tidyDateTime, tidyFormResponse, tidyNestedFormResponse } from 'utils/Tidy';
import { Accordion } from 'react-bootstrap';
import { Panel } from 'components/shared/Panel';
import EditableField from './EditableField';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import Confirmation from 'components/shared/Confirmation';
import { fetcher } from 'utils/Fetch';
import { useParams } from 'react-router-dom';
import { useMessage } from 'utils/MessageContext';

const Row = styled.div`
	border-top: 1px solid var(--lightest);
	padding: 1rem 0;
	display: grid;
	gap: 0.5rem;

	&:last-of-type {
		padding-bottom: 0;
	}
`;

const Component = styled.div`
	display: grid;
	gap: 1.5rem;
`;

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr auto;
	gap: 0.5rem;
	align-items: start;
`;

const Buttons = styled.div`
	display: flex;
	padding-top: 1rem;
`;

const DeleteButton = styled.button`
	all: unset;
	color: var(--bs-danger);
	transition: 0.2s;
	display: grid;
	place-items: center;
	border-radius: 50%;
	width: 2rem;
	height: 2rem;

	&:hover,
	&:focus-visible {
		background: var(--bs-light);
	}
`;

const LinkButton = styled.a`
	all: unset;
	color: var(--bs-secondary);
	transition: 0.2s;
	display: grid;
	place-items: center;
	border-radius: 50%;
	width: 2rem;
	height: 2rem;
	cursor: pointer;

	&:hover,
	&:focus-visible {
		background: var(--bs-light);
	}
`;

const ResponseList = styled.ul`
	margin: 0.5rem 0;
	list-style: none;
	padding: 0;

	li::before {
		content: '✓';
		margin-right: 0.5rem;
		color: var(--bs-success);
	}
`;

const Surveys = ({ siteGenieData, mutate }) => {
	// Props
	const { initial_survey, expanded_survey } = { ...siteGenieData };
	const { submitted: initial_submitted, ...initial_survey_responses } = { ...initial_survey };
	const { submitted: expanded_submitted, ...expanded_survey_responses } = { ...expanded_survey };

	// Format responses
	const initial_survey_data = tidyFormResponse(initial_survey_responses);
	const expanded_survey_data = tidyNestedFormResponse(expanded_survey_responses);

	// State
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [surveyType, setSurveyType] = useState('');

	// Hooks
	const { id } = useParams();
	const form = useForm({ defaultValues: { initial_survey, expanded_survey } });
	const { setMessage } = useMessage();

	// Handlers
	const handleDeleteSurvey = async () => {
		setShowConfirmation(false);
		try {
			await fetcher(`/api/v1/site-genie/${surveyType}_survey/${id}`, { method: 'DELETE' });
			await mutate();
		} catch (error) {
			setMessage({ type: 'danger', message: `Error deleting ${surveyType} survey: ${error.message}` });
		}
	};

	return (
		<>
			<Confirmation show={showConfirmation} setShow={setShowConfirmation} message='delete this survey? The customer will need to complete & submit the survey again.' confirmFunction={handleDeleteSurvey} buttonType='danger' />
			<Section>
				<h4 className='font-semibold text-2xl'>Surveys</h4>
				<Component>
					{initial_submitted ? (
						<Wrapper>
							<Accordion>
								<Panel>
									<Toggle eventKey={1}>
										<div>
											<i className='fas fa-check-circle text-success me-2'></i>Initial survey submitted successfully on <strong>{tidyDateTime(initial_submitted)}</strong>
										</div>
										<div>
											View <i className='fas fa-angle-down text-muted'></i>
										</div>
									</Toggle>
									<Collapse eventKey={1}>
										<div>
											{initial_survey_data.map(({ question, response }) => (
												<Row key={question}>
													<div>
														<div className='text-secondary'>{question}</div>
														<EditableField form={form} name={`initial_survey.${question}`} value={response} />
													</div>
												</Row>
											))}
										</div>
									</Collapse>
								</Panel>
							</Accordion>
							<Buttons>
								<LinkButton href={`/site-genie/${id}/view/initial`} target='_blank' rel='noopener noreferrer'>
									<i className='fas fa-poll-h'></i>
								</LinkButton>
								<DeleteButton
									onClick={() => {
										setSurveyType('initial');
										setShowConfirmation(true);
									}}>
									<i className='fas fa-trash-alt'></i>
								</DeleteButton>
							</Buttons>
						</Wrapper>
					) : (
						<Panel>
							<div>
								<i className='fas fa-exclamation-circle text-warning me-2'></i>No initial survey responses to display. Send the customer a <strong>Initial Survey</strong> using the link above.
							</div>
						</Panel>
					)}
					{expanded_submitted ? (
						<Wrapper>
							<Accordion>
								<Panel>
									<Toggle eventKey={1}>
										<div>
											<i className='fas fa-check-circle text-success me-2'></i>Expanded survey submitted successfully on <strong>{tidyDateTime(expanded_submitted)}</strong>
										</div>
										<div>
											View <i className='fas fa-angle-down text-muted'></i>
										</div>
									</Toggle>
									<Collapse eventKey={1}>
										<div>
											{expanded_survey_data.map(section => (
												<Row key={section.section}>
													<div className='fw-bold'>{section.section}</div>
													{section.data.map(field => (
														<div key={field.question}>
															<div className='text-secondary'>{field.question}</div>
															<div>
																{Array.isArray(field.response) ? (
																	<ResponseList>
																		{field.response.map(r => (
																			<li key={r}>{r}</li>
																		))}
																	</ResponseList>
																) : (
																	<EditableField form={form} name={`expanded_survey.${section.section}.${field.question}`} value={field.response} />
																)}
															</div>
														</div>
													))}
												</Row>
											))}
										</div>
									</Collapse>
								</Panel>
							</Accordion>
							<Buttons>
								<LinkButton href={`/site-genie/${id}/view/expanded`} target='_blank' rel='noopener noreferrer'>
									<i className='fas fa-poll-h'></i>
								</LinkButton>
								<DeleteButton
									onClick={() => {
										setSurveyType('expanded');
										setShowConfirmation(true);
									}}>
									<i className='fas fa-trash-alt'></i>
								</DeleteButton>
							</Buttons>
						</Wrapper>
					) : (
						<Panel>
							<div>
								<i className='fas fa-exclamation-circle text-warning me-2'></i>No expanded survey responses to display. Send the customer a <strong>Expanded Survey</strong> using the link above.
							</div>
						</Panel>
					)}
				</Component>
			</Section>
		</>
	);
};

export default Surveys;
