import { useEffect, useRef } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { ButtonGroup } from '../Components';
import StepNumber from '../StepNumber';
import styled from 'styled-components';

const Wrapper = styled.div`
	position: relative;
	top: 0.5%;

	@media (max-width: 991px) {
		position: static;
	}
`;

const LogoColors = ({ prevStep, nextStep, handleChange, formResponse }) => {
	// Scroll to element
	const scrollRef = useRef();
	const scrollToElement = () => scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

	useEffect(() => {
		// Scroll to element if on desktop
		if (window.matchMedia('(min-width: 992px)').matches) {
			scrollToElement();
		}
	}, []);

	return (
		<Wrapper ref={scrollRef}>
			<Row className='align-items-center' noGutters>
				<Col xs='auto'>
					<StepNumber number={1} />
				</Col>
				<Col>
					<h5 className='text-xl font-semibold'>Logo & Colors</h5>
				</Col>
			</Row>
			<Form.Group className='mb-3'>
				<Form.Label>Does your association have a logo we can use on the website?</Form.Label>
				<Form.Check type='radio' name='does_your_association_have_a_logo_we_can_use_on_the_website' value='Yes' label='Yes' defaultChecked={formResponse?.does_your_association_have_a_logo_we_can_use_on_the_website === 'Yes'} id='does_your_association_have_a_logo_we_can_use_on_the_website_yes' onChange={e => handleChange(e)} />
				<Form.Check type='radio' name='does_your_association_have_a_logo_we_can_use_on_the_website' value='No' label='No' defaultChecked={formResponse?.does_your_association_have_a_logo_we_can_use_on_the_website === 'No'} id='does_your_association_have_a_logo_we_can_use_on_the_website_no' onChange={e => handleChange(e)} />
			</Form.Group>
			<Form.Group className='mb-3'>
				<Form.Label>Does your association have existing marketing materials (brochure, website etc.) that we can use as a design reference?</Form.Label>
				<Form.Check type='radio' name='does_your_association_have_existing_marketing_materials_that_we_can_use_as_a_design_reference' value='Yes' label='Yes' defaultChecked={formResponse?.does_your_association_have_existing_marketing_materials_that_we_can_use_as_a_design_reference === 'Yes'} id='does_your_association_have_existing_marketing_materials_that_we_can_use_as_a_design_reference_yes' onChange={e => handleChange(e)} />
				<Form.Check type='radio' name='does_your_association_have_existing_marketing_materials_that_we_can_use_as_a_design_reference' value='No' label='No' defaultChecked={formResponse?.does_your_association_have_existing_marketing_materials_that_we_can_use_as_a_design_reference === 'No'} id='does_your_association_have_existing_marketing_materials_that_we_can_use_as_a_design_reference_no' onChange={e => handleChange(e)} />
			</Form.Group>
			<ButtonGroup>
				<Button variant='secondary' onClick={() => prevStep()} className='m-1'>
					<i className='fas fa-angle-left'></i> Previous
				</Button>
				<Button variant='primary' onClick={() => nextStep()} className='m-1'>
					Next <i className='fas fa-angle-right'></i>
				</Button>
			</ButtonGroup>
		</Wrapper>
	);
};

export default LogoColors;
