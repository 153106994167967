import { Button, Form } from 'react-bootstrap';
import { ButtonGroup } from '../Components';
import { Field } from 'formik';
import TextareaAutosize from 'react-textarea-autosize';

const AdditionalInfo = ({ handlePrevStep }) => {
	return (
		<>
			<h4 className='font-semibold text-2xl'>Additional Information</h4>
			<Form.Group className='mb-3'>
				<Form.Label>Additional approvers or reviewers for this site?</Form.Label>
				<Field as={Form.Control} name='additional_approvers' />
				<Form.Text>If there are other individuals who need to review the site before going live, please add their email addresses, separated by a comma.</Form.Text>
			</Form.Group>
			<Form.Group className='mb-3'>
				<Form.Label>Anything you would like to add?</Form.Label>
				<Field as={TextareaAutosize} className='form-control' name='other_notes' minRows={3} />
				<Form.Text>Any questions you have, as well as thoughts or comments about the design and content of the website.</Form.Text>
			</Form.Group>
			<ButtonGroup>
				<Button variant='secondary' type='button' onClick={() => handlePrevStep()}>
					<i className='fas fa-angle-left'></i> Previous
				</Button>
				<Button variant='primary' type='submit'>
					Submit <i className='fas fa-paper-plane'></i>
				</Button>
			</ButtonGroup>
		</>
	);
};

export default AdditionalInfo;
