import { useRef, useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { ButtonGroup } from '../Components';
import styled from 'styled-components';
import ReactGA from 'react-ga4';
import { Field, useFormikContext } from 'formik';
import TextareaAutosize from 'react-textarea-autosize';
import { useTemplates } from 'utils/Templates';

const Panel = styled.div`
	background: white;
	padding: 1rem;
	margin: 2rem auto;
	border-radius: 0.5rem;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);

	p:last-of-type {
		margin: 0;
	}
`;

const TemplateWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	gap: 2rem;
	margin: 1rem auto;
`;

const Overlay = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background: linear-gradient(#0000, #000);
	padding: 2rem 1rem 1rem;
	gap: 0.5rem;
	display: grid;
	opacity: 0;
	visibility: hidden;
	transform: translateY(100%);
	transition: 0.2s;

	@media (max-width: 991px) {
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
	}
`;

const Template = styled.div`
	position: relative;
	border-radius: 0.5rem;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
	overflow: hidden;

	&:hover ${Overlay}, &:focus ${Overlay}, &:focus-within ${Overlay} {
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
	}
`;

const Thumb = styled.img`
	width: 100%;
	aspect-ratio: 3/4;
	object-fit: cover;
	object-position: center top;
`;

const OverlayButton = styled(Button)`
	padding: 0.75rem;
	color: white;
`;

const Selected = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #0009;
	display: grid;
	place-items: center;
`;

const SelectedInfo = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
	text-shadow: 0 1px 2px #0003;
`;

const Icon = styled.div`
	font-size: 3rem;
	color: var(--bs-primary);
`;

const Text = styled.div`
	color: white;
	font-size: 1rem;
	line-height: 1.25;
`;

const Name = styled.div`
	font-weight: bold;
	font-size: 2rem;
`;

const Info = styled.div`
	text-align: center;
	padding: 0.75rem;
`;

const InfoName = styled.h5`
	margin: 0 0 0.25rem;
`;

const InfoTags = styled.div`
	color: var(--bs-secondary);
`;

const DesignOptions = ({ plan, handleNextStep, handlePrevStep }) => {
	// State
	const [showModal, setShowModal] = useState(false);
	const { product_tier, purpose } = { ...plan };
	const [showMore, setShowMore] = useState(false);

	// Hooks
	const { values: formState, setFieldValue } = useFormikContext();

	// Templates
	const { getAllowedTemplates, getAdditionalTemplates } = useTemplates();
	const requiresAdditionalTemplates = product_tier === 'S2' && purpose === 'Single Property';
	const allowedTemplates = getAllowedTemplates(product_tier, purpose);
	const additionalTemplates = requiresAdditionalTemplates ? getAdditionalTemplates(product_tier, purpose) : [];

	// Scroll to element
	const scrollRef = useRef();

	// Image select handler
	const handleImageSelect = template_name => {
		setFieldValue('website_template', template_name);
		ReactGA.event({ category: 'Kickoff Survey', action: 'Template Selected', label: template_name });
		setTimeout(() => scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }), 500);
	};

	// Image unselect handler
	const handleImageUnselect = template_name => {
		setFieldValue('website_template', '');
		ReactGA.event({ category: 'Kickoff Survey', action: 'Template Unselected', label: template_name });
	};

	// Modal close handler
	const handleCloseModal = () => {
		ReactGA.event({ category: 'Kickoff Survey', action: 'Template Selected', label: 'Undecided' });
		setShowModal(false);
		handleNextStep();
	};

	// GA template preview event
	const handleTemplatePreview = template_name => {
		ReactGA.event({ category: 'Kickoff Survey', action: 'Template Previewed', label: template_name });
	};

	// Proceed
	const allowToProceed = formState.website_template && formState.why_did_the_customer_choose_this_design ? true : false;

	return (
		<>
			<Modal show={showModal} onHide={() => handleCloseModal()} backdrop='static'>
				<Modal.Header>
					<Modal.Title>
						<i className='fas fa-exclamation-circle text-warning me-2'></i>Can't decide on a website template?
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>No problem! During our kick-off call we can review your difference template options in more detail and find the right one for your website.</p>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='primary' onClick={() => handleCloseModal()}>
						OK, great! <i className='fas fa-check'></i>
					</Button>
				</Modal.Footer>
			</Modal>
			<h4 className='font-semibold text-2xl'>Design Options</h4>
			<Panel>
				<h5>
					<i className='fas fa-exclamation-circle text-warning me-1'></i> Quick note:
				</h5>
				<p>When selecting a option below, note that the template simply refers to the basic layout of the website. We are able to change the colors, logos and photos to suit your company branding.</p>
				<p>To make a decision, please consider what your primary goals are for this website and what you would like the home page/navigation style to look like. We will discuss this in more detail during the kick off call but please come prepared with any special requests, as some of our templates do have limitations.</p>
			</Panel>
			<strong>
				Please select a template below: <span className='fw-bold text-danger'>*</span>
			</strong>
			<TemplateWrapper>
				{allowedTemplates?.map(template => (
					<div key={template.site_id}>
						<Template tabIndex='0'>
							{formState.website_template === template.name && (
								<Selected>
									<SelectedInfo>
										<Icon>
											<i className='fas fa-check'></i>
										</Icon>
										<Text>
											Selected template:
											<Name>{template.name}</Name>
										</Text>
									</SelectedInfo>
								</Selected>
							)}
							<Overlay>
								{formState.website_template !== template.name ? (
									<OverlayButton
										variant='success'
										onClick={e => {
											e.currentTarget.blur();
											handleImageSelect(template.name);
										}}>
										Select <strong>{template.name}</strong> template <i className='fas fa-check-circle'></i>
									</OverlayButton>
								) : (
									<OverlayButton
										variant='danger'
										onClick={e => {
											e.currentTarget.blur();
											handleImageUnselect(template.name);
										}}>
										Unselect <strong>{template.name}</strong> template <i className='fas fa-times-circle'></i>
									</OverlayButton>
								)}
								<OverlayButton variant='secondary' href={`https://dashboard.appfoliowebsites.com/preview/${template.site_id}`} target='_blank' rel='noopener noreferrer' onClick={() => handleTemplatePreview(template.name)}>
									<i className='fas fa-external-link-alt'></i> Open template preview
								</OverlayButton>
							</Overlay>
							<Thumb src={`https://irp.cdn-website.com/${template.site_id}/screenshots/Screenshot.png`} alt={template.name} />
						</Template>
						<Info>
							<InfoName>{template.name}</InfoName>
							<InfoTags>{template.tags}</InfoTags>
						</Info>
					</div>
				))}
				{showMore &&
					additionalTemplates?.map(template => (
						<div key={template.site_id}>
							<Template tabIndex='0'>
								{formState.website_template === template.name && (
									<Selected>
										<SelectedInfo>
											<Icon>
												<i className='fas fa-check'></i>
											</Icon>
											<Text>
												Selected template:
												<Name>{template.name}</Name>
											</Text>
										</SelectedInfo>
									</Selected>
								)}
								<Overlay>
									{formState.website_template !== template.name ? (
										<OverlayButton
											variant='success'
											onClick={e => {
												e.currentTarget.blur();
												handleImageSelect(template.name);
											}}>
											Select <strong>{template.name}</strong> template <i className='fas fa-check-circle'></i>
										</OverlayButton>
									) : (
										<OverlayButton
											variant='danger'
											onClick={e => {
												e.currentTarget.blur();
												handleImageUnselect(template.name);
											}}>
											Unselect <strong>{template.name}</strong> template <i className='fas fa-times-circle'></i>
										</OverlayButton>
									)}
									<OverlayButton variant='secondary' href={`https://dashboard.appfoliowebsites.com/preview/${template.site_id}`} target='_blank' rel='noopener noreferrer' onClick={() => handleTemplatePreview(template.name)}>
										Open template preview <i className='fas fa-external-link-alt'></i>
									</OverlayButton>
								</Overlay>
								<Thumb src={`https://irp.cdn-website.com/${template.site_id}/screenshots/Screenshot.png`} alt={template.name} />
							</Template>
							<Info>
								<InfoName>{template.name}</InfoName>
								<InfoTags>{template.tags}</InfoTags>
							</Info>
						</div>
					))}
			</TemplateWrapper>
			{formState.website_template && (
				<Form.Group className='mt-4' ref={scrollRef}>
					<Form.Label>
						What do you like about the {formState.website_template} template? <span className='text-danger fw-bold'>*</span>
					</Form.Label>
					<Field as={TextareaAutosize} className='form-control' minRows={3} name='why_did_the_customer_choose_this_design' required />
				</Form.Group>
			)}
			<ButtonGroup>
				<Button variant='secondary' onClick={() => handlePrevStep()}>
					<i className='fas fa-angle-left'></i> Previous
				</Button>
				{requiresAdditionalTemplates && !showMore && (
					<Button variant='outline-dark' onClick={() => setShowMore(true)}>
						<i className='fas fa-plus'></i> Show more
					</Button>
				)}
				{!formState.website_template ? (
					<Button variant='dark' type='button' onClick={() => setShowModal(true)}>
						I can't decide <i className='fas fa-question-circle'></i>
					</Button>
				) : (
					<Button variant='primary' type='button' onClick={() => handleNextStep()} disabled={allowToProceed ? false : true}>
						Next <i className='fas fa-angle-right'></i>
					</Button>
				)}
			</ButtonGroup>
		</>
	);
};

export default DesignOptions;
