import { CircleAlert } from 'lucide-react';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom';

export default function Expired() {
	const { state } = useLocation();
	const {
		error: { message }
	} = { ...state };

	return (
		<>
			<Helmet>
				<title>Expired</title>
			</Helmet>
			<div className='flex-wrap p-3 p-md-5 d-flex justify-content-center align-items-center text-danger'>
				<div className='text-center'>
					<h1 className='flex items-center justify-center gap-3 text-6xl font-bold'>
						<CircleAlert className='w-16 h-16' /> Expired
					</h1>
					<p className='my-4 text-secondary'>{message}</p>
				</div>
			</div>
		</>
	);
}
