import { Survey } from 'components/shared/Surveys';
import { FormLabel, FormControl } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';

const RealEstate = ({ register }) => {
	return (
		<Survey.Container>
			<h4 className='font-semibold text-2xl'>Real Estate</h4>
			<div>
				<FormLabel>
					What areas or locations do you focus on? <strong className='text-danger'>*</strong>
				</FormLabel>
				<FormControl as={TextareaAutosize} minRows={5} {...register('Real Estate.What areas or locations do you focus on?', { required: true })} />
			</div>
			<div>
				<FormLabel>
					What type of property portfolio do you specialize in? <strong className='text-danger'>*</strong>
				</FormLabel>
				<FormControl as={TextareaAutosize} minRows={5} {...register('Real Estate.What type of property portfolio do you specialize in?', { required: true })} />
			</div>
			<div>
				<FormLabel>
					What sets you apart from other Real Estate agents in your area? <strong className='text-danger'>*</strong>
				</FormLabel>
				<FormControl as={TextareaAutosize} minRows={5} {...register('Real Estate.What sets you apart from other Real Estate agents in your area?', { required: true })} />
			</div>
		</Survey.Container>
	);
};

export default RealEstate;
