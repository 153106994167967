import { Helmet } from 'react-helmet';
import { Form, Button } from 'react-bootstrap';
import PlanTable from 'components/shared/PlanTable';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import PageHeader from 'components/shared/PageHeader';
import { useLogin } from 'utils/UserContext';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useNavigate, Link } from 'react-router-dom';
import useSWR from 'swr';

const MyProjectPlans = () => {
	// Hooks
	const { user } = useLogin();
	const navigate = useNavigate();

	// Load plans on render
	const { data: plans } = useSWR(`/api/v1/plans/user/${user.email}`);

	// Go to plan
	const goToPlan = e => {
		const selected = e[0];
		const selectedSite = plans.find(plan => plan._id === selected._id);
		navigate({ pathname: `/plan/${selectedSite._id}` });
	};

	return (
		<>
			<Helmet>
				<title>My Project Plans | Project Plan Tool</title>
			</Helmet>
			<PageHeader heading={'My Project Plans'} subheading={'View all of my project plans.'} />
			<div className='space-y-6'>
				{plans ? (
					plans.length > 0 ? (
						<>
							<Form>
								<Form.Group className='mb-3'>
									<Typeahead className='border-0 shadow-sm' id='basic-typeahead-single' labelKey='name' onChange={e => goToPlan(e)} options={plans.map(plan => ({ _id: plan._id, name: plan.company_name }))} placeholder='Search for a company...' minLength={2} />
								</Form.Group>
							</Form>
							<PlanTable plans={plans} />
						</>
					) : (
						<div className='grid gap-3 p-4 bg-white rounded-lg shadow place-items-center'>
							<div>You don't have any project plans.</div>
							<Button as={Link} to={'/plan/new'}>
								Create a New Project Plan <i className='fas fa-folder-plus'></i>
							</Button>
						</div>
					)
				) : (
					<LoadingOverlay />
				)}
			</div>
		</>
	);
};

export default MyProjectPlans;
