import PageHeader from 'components/shared/PageHeader';
import styled from 'styled-components';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';
import { Accordion, Button } from 'react-bootstrap';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import { Helmet } from 'react-helmet';
import { tidyUnderscores, tidyParagraphs } from 'utils/Tidy';
import { ButtonBar, BarButton } from 'components/shared/ButtonBar';
import { fetcher } from 'utils/Fetch';
import { useMessage } from 'utils/MessageContext';
import { AccordionToggle } from 'components/shared/Accordion';

const Component = styled.div``;

const Property = styled.div`
	background: white;
	margin-bottom: 1rem;
	border-radius: 0.5rem;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
	overflow: auto hidden;
`;

const NoResults = styled(Property)`
	padding: 1rem;
	text-align: center;
`;

const Toggle = styled(AccordionToggle)`
	font-size: 1.25rem;
	font-weight: bold;
	cursor: pointer;
	padding: 1rem;
	display: flex;
	align-items: center;
	gap: 0.5rem;

	i {
		color: var(--bs-secondary);
	}
`;

const Content = styled.div`
	display: grid;
	grid-template-columns: 12rem 1fr;
	gap: 1rem;
	border-top: 1px solid #eee;
	padding: 0.75rem 1rem;

	@media (max-width: 576px) {
		grid-template-columns: 100%;
		gap: 0;
	}
`;

const Name = styled.div`
	font-weight: bold;
	text-align: right;

	@media (max-width: 576px) {
		text-align: left;
	}
`;

const Value = styled.div``;

const ButtonWrapper = styled.div`
	display: flex;
	gap: 1rem;
`;

const Delete = styled.button``;

const DeleteWrapper = styled.div`
	display: flex;
	gap: 1rem;
	justify-content: end;
	padding: 0 1rem 1rem;
`;

const Properties = () => {
	// Hooks
	const { id } = useParams();
	const { data: properties, isValidating, mutate } = useSWR(`/api/v1/properties/${id}`);
	const { setMessage } = useMessage();

	// Handlers
	const handleDelete = async property => {
		const { _id, property_name } = property;
		try {
			await fetcher(`/api/v1/properties/${id}/${_id}`, { method: 'DELETE' });
			mutate();
		} catch (error) {
			setMessage({ variant: 'danger', text: `There was an error deleting property ${property_name}.` });
		}
	};

	return (
		<Component>
			<Helmet>
				<title>Property Details | Project Plan Tool</title>
			</Helmet>
			<PageHeader heading='Properties' subheading='Details for each property submitted via the Property Details survey.' />
			<ButtonWrapper>
				<ButtonBar className='mb-5'>
					<BarButton to={`/plan/${id}`}>
						<i className='fas fa-angle-left'></i> Back to Project Plan
					</BarButton>
				</ButtonBar>
				<ButtonBar className='mb-5'>
					<BarButton to={`/properties/${id}/survey`} target='_blank' rel='noopener noreferrer'>
						Property Details Survey <i className='fas fa-external-link-alt'></i>
					</BarButton>
					{properties?.length > 0 && (
						<BarButton to={`/properties/${id}/view`} target='_blank' rel='noopener noreferrer'>
							Writing Content Link <i className='fas fa-external-link-alt'></i>
						</BarButton>
					)}
				</ButtonBar>
			</ButtonWrapper>
			{properties && !isValidating ? (
				properties.length > 0 ? (
					<Accordion>
						{properties.map((property, i) => {
							const fields = Object.entries(property).filter(field => field[1]);
							return (
								<Property key={property.property_name}>
									<Toggle eventKey={i + 1}>
										{property.property_name} <i className='fas fa-angle-down'></i>
									</Toggle>
									<Accordion.Collapse eventKey={i + 1}>
										<div>
											{fields
												.filter(([name]) => name !== '_id')
												.map(([name, value]) => (
													<Content key={name}>
														<Name>{tidyUnderscores(name)}:</Name>
														<Value dangerouslySetInnerHTML={{ __html: tidyParagraphs(value) }} />
													</Content>
												))}
											<DeleteWrapper>
												<Button variant='danger' as={Delete} onClick={() => handleDelete(property)}>
													<i className='fas fa-trash-alt'></i> Delete
												</Button>
											</DeleteWrapper>
										</div>
									</Accordion.Collapse>
								</Property>
							);
						})}
					</Accordion>
				) : (
					<NoResults>No property details to display.</NoResults>
				)
			) : (
				<LoadingOverlay />
			)}
		</Component>
	);
};

export default Properties;
