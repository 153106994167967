import { useEffect, useRef } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { ButtonGroup } from '../Components';
import StepNumber from '../StepNumber';
import styled from 'styled-components';

const Wrapper = styled.div`
	position: relative;
	top: 39%;

	@media (max-width: 991px) {
		position: static;
	}
`;

const TeamPhotos = ({ prevStep, nextStep, handleChange, formResponse }) => {
	// Scroll to element
	const scrollRef = useRef();
	const scrollToElement = () => scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

	useEffect(() => {
		// Scroll to element if on desktop
		if (window.matchMedia('(min-width: 992px)').matches) {
			scrollToElement();
		}
	}, []);

	return (
		<Wrapper ref={scrollRef}>
			<Row className='align-items-center' noGutters>
				<Col xs='auto'>
					<StepNumber number={5} />
				</Col>
				<Col>
					<h5 className='text-xl font-semibold'>Team/Board Photos</h5>
				</Col>
			</Row>
			<p>We can include short bios, headshots, and titles for your Association Board.</p>
			<Form.Group className='mb-3'>
				<Form.Label>Would you like to include the team/board photos section?</Form.Label>
				<Form.Check type='radio' name='would_you_like_to_include_the_team_photos_section' label='Yes' value='Yes' defaultChecked={formResponse?.would_you_like_to_include_the_team_photos_section === 'Yes'} id='would_you_like_to_include_the_team_photos_section_yes' onChange={e => handleChange(e)} />
				<Form.Check type='radio' name='would_you_like_to_include_the_team_photos_section' label='No' value='No' defaultChecked={formResponse?.would_you_like_to_include_the_team_photos_section === 'No'} id='would_you_like_to_include_the_team_photos_section_no' onChange={e => handleChange(e)} />
			</Form.Group>
			{formResponse?.would_you_like_to_include_the_team_photos_section === 'Yes' && (
				<>
					<Form.Group className='mb-3'>
						<Form.Label>Do you have headshots for each board member?</Form.Label>
						<Form.Check type='radio' name='do_you_have_headshots_for_each_team_member' label='Yes' value='Yes' defaultChecked={formResponse?.do_you_have_headshots_for_each_team_member === 'Yes'} id='do_you_have_headshots_for_each_team_member_yes' onChange={e => handleChange(e)} />
						<Form.Check type='radio' name='do_you_have_headshots_for_each_team_member' label='No' value='No' defaultChecked={formResponse?.do_you_have_headshots_for_each_team_member === 'No'} id='do_you_have_headshots_for_each_team_member_no' onChange={e => handleChange(e)} />
					</Form.Group>
					<Form.Group className='mb-3'>
						<Form.Label>How many board members would you like to include?</Form.Label>
						<Form.Control type='number' name='how_many_team_members_would_you_like_to_include' defaultValue={formResponse?.how_many_members_would_you_like_to_include} onChange={e => handleChange(e)} />
					</Form.Group>
					<Form.Group className='mb-3'>
						<Form.Label>What information do you want to include about each member?</Form.Label>
						<Form.Control type='input' name='information_to_include_about_each_team_member' defaultValue={formResponse?.information_to_include_about_each_team_member} onChange={e => handleChange(e)} placeholder='eg. name, title' />
					</Form.Group>
				</>
			)}
			<ButtonGroup>
				<Button variant='secondary' onClick={() => prevStep()} className='m-1'>
					<i className='fas fa-angle-left'></i> Previous
				</Button>
				<Button variant='primary' onClick={() => nextStep()} className='m-1'>
					Next <i className='fas fa-angle-right'></i>
				</Button>
			</ButtonGroup>
		</Wrapper>
	);
};

export default TeamPhotos;
